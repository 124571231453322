import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';
import CreateEntityDrawer from '../../common/create-entity-drawer/create-entity-drawer';
import CreateEmployeeForm from './create-employee-form/create-employee-form';
import EmployeesListing from './employees-listing/employees-listing';

function Employees() {
  const { user } = useContext(AuthContext);

  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Employees
        </Typography>

        <Grid container spacing={2}>
          <Grid xs={4}></Grid>
          <Grid xs={4}></Grid>
          <Grid xs={4} sx={{ textAlign: 'right' }}>
            <CreateEntityDrawer>
              <CreateEmployeeForm />
            </CreateEntityDrawer>
          </Grid>
          <Grid xs={12}>
            <EmployeesListing />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Employees;
