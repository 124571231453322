
export const ensureZeroAfterCountryCode = (phoneNumber: string): string => {
    if (phoneNumber.startsWith("+961")) {
        const base = phoneNumber.slice(4)
        if (base.length === 7)
            return `+9610${base}`
        return phoneNumber
    } else if (phoneNumber.startsWith("961")) {
        const base = phoneNumber.slice(3)
        if (base.length == 7)
            return `+9610${base}`
        return phoneNumber
    }
    return phoneNumber
}