import {
  Html5Qrcode,
  Html5QrcodeResult,
  Html5QrcodeScanner
} from 'html5-qrcode';
import { useEffect, useRef } from 'react';
import { createConfig, getStyle } from './util';

const qrcodeRegionId = 'barcode-scanner-full-region';

export interface BarcodeScannerProps {
  onSuccessfullScan: (
    newDecodedText: string,
    newDecodedResult: Html5QrcodeResult
  ) => void;
}

const BarcodeScanner = (props: BarcodeScannerProps) => {
  const scannerRef = useRef<Html5QrcodeScanner | null>(null);

  const config = createConfig();

  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'hidden') {
      if (scannerRef.current) {
        await scannerRef.current.clear();
      }
    } else {
      if (document.getElementById(qrcodeRegionId)) {
        if (scannerRef.current) {
          scannerRef.current.render(props.onSuccessfullScan, undefined)
        } else {
          setTimeout(() => {
            if (scannerRef.current) {
              scannerRef.current.render(props.onSuccessfullScan, undefined)
            }
          }, 300);
        }
      }
    }
  };

  useEffect(() => {
    scannerRef.current = new Html5QrcodeScanner(qrcodeRegionId, { ...config, showTorchButtonIfSupported: true, showZoomSliderIfSupported: true }, false);
    // Start scanning once component is mounted
    if (scannerRef.current) {
      scannerRef.current.render(props.onSuccessfullScan, undefined)
    }

    //Add event listener to listen to visibility change events
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      const clearCamera = async () => {
        if (scannerRef.current) {
          await scannerRef.current.clear();
        }
        //Remove event listener on component unmount
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      }
      clearCamera()
    };
  }, []);

  return <div id={qrcodeRegionId} />;
};

export default BarcodeScanner;
