import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
import { primaryPaletteColor } from '../../../configurations/global.config';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { IFieldConfig } from './report';

const borderColor = primaryPaletteColor;
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 45,
    fontStyle: 'bold',
    borderColor: borderColor,
  },
});

interface ReportTableRowsProps {
  orders: OrderType[];
  fields_config: IFieldConfig[];
  max_rows_on_first_page: number;
}

export const ReportTableRows = ({
  orders,
  fields_config,
  max_rows_on_first_page,
}: ReportTableRowsProps) => {
  const rows = orders.map((item, rowIndex) => {
    return (
      <View
        break={
          rowIndex + 1 === max_rows_on_first_page ||
          (rowIndex + 1 > 11 &&
            (rowIndex + 1 - max_rows_on_first_page) % 17 === 0)
        }
        style={styles.row}
        key={item.id}
      >
        {fields_config.map((config, index) => (
          <Text
            key={index}
            style={{
              width: config.width_percentage,
              borderColor: borderColor,
              paddingLeft: 3,
              borderRightWidth: index < fields_config.length - 1 ? 1 : 0,
              height: '100%',
              fontWeight: 'bold',
            }}
          >
            {config.get_values(item).map((value) => (
              <>
                {value} {'\n'}
              </>
            ))}
          </Text>
        ))}
      </View>
    );
  });

  return <Fragment>{rows}</Fragment>;
};
