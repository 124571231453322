import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useContext } from 'react';
import { DrawerHeader, DrawerWithIcons, SideNavProps } from './side-nav.utils';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { drawerWidth, get_menu_definition } from './side-nav.config';

import { useTheme } from '@mui/material/styles';
import { NavLink, useLocation } from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { isMobile } from 'react-device-detect';
import {
  secondaryPaletteColor
} from '../../../configurations/global.config';
import { AuthContext } from '../../../contexts/user-context/user-context';
import './side-nav.css';
import UserProfile from './side-nav.user-profile';

function SideNav({ open, setOpen, handleDrawerClose }: SideNavProps) {
  const theme = useTheme();
  const location = useLocation();

  const { user } = useContext(AuthContext);

  let userType = '';
  if (user) userType = user.type;

  const menu_definition = get_menu_definition(userType);

  const navBarContent = (
    <>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>

      <List>
        <ListItem key={'profile'} disablePadding sx={{ display: 'block' }}>
          {!open && (
            <ListItemButton
              onClick={() => setOpen(true)}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <AccountCircleIcon color="primary" />
              </ListItemIcon>
            </ListItemButton>
          )}

          <ListItemText
            primary={open ? <UserProfile /> : ''}
            sx={{ opacity: open ? 1 : 0 }}
          />
        </ListItem>

        {menu_definition.map((item, index) => (
          <NavLink to={item.route} key={index}>
            <ListItem
              key={item.name}
              disablePadding
              sx={{
                display: 'block',
                backgroundColor: location.pathname.includes(
                  item.route_substring.substring(
                    0,
                    item.route_substring.length - 1
                  )
                )
                  ? secondaryPaletteColor
                  : '',
              }}
            >
              <ListItemButton
                onClick={handleDrawerClose}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>

                <ListItemText
                  primary={item.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
      </List>
    </>
  );

  return isMobile ? (
    <Drawer
      anchor={'left'}
      open={open}
      style={{ width: drawerWidth, zIndex: 9999999999 }}
    >
      <Box
        sx={{
          width: drawerWidth,
        }}
      >
        {navBarContent}
      </Box>
    </Drawer>
  ) : (
    <DrawerWithIcons anchor="left" open={open} variant="permanent">
      {navBarContent}
    </DrawerWithIcons>
  );
}

export default SideNav;
