import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import BusinessIcon from '@mui/icons-material/Business';
import MopedIcon from '@mui/icons-material/Moped';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import InventoryIcon from '@mui/icons-material/Inventory';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SearchIcon from '@mui/icons-material/Search';
import { UserType } from '../../../enums/users';

export const drawerWidth = '275px';

interface MenuDefinitionItem {
  name: string;
  icon: JSX.Element;
  route_substring: string;
  route: string;
}

const admin_menu_definition: MenuDefinitionItem[] = [
  { name: 'Home', icon: <HomeIcon />, route_substring: 'home', route: 'home' },
  {
    name: 'Orders',
    icon: <InventoryIcon />,
    route_substring: 'order',
    route: 'orders',
  },
  {
    name: 'Customers',
    icon: <EmojiPeopleIcon />,
    route_substring: 'customer',
    route: 'customers',
  },
  {
    name: 'Merchants',
    icon: <BusinessIcon />,
    route_substring: 'merchant',
    route: 'merchants',
  },
  {
    name: 'Drivers',
    icon: <MopedIcon />,
    route_substring: 'driver',
    route: 'drivers',
  },
  {
    name: 'Employees',
    icon: <SupportAgentIcon />,
    route_substring: 'employee',
    route: 'employees',
  },
  {
    name: 'Branches',
    icon: <StoreIcon />,
    route_substring: 'branch',
    route: 'branches',
  },
  {
    name: 'Accounting',
    icon: <AttachMoneyIcon />,
    route_substring: 'accounting',
    route: 'accounting',
  },
];

const employee_menu_definition: MenuDefinitionItem[] = [
  {
    name: 'Orders',
    icon: <InventoryIcon />,
    route_substring: 'order',
    route: 'orders',
  },
  {
    name: 'Customers',
    icon: <EmojiPeopleIcon />,
    route_substring: 'customer',
    route: 'customers',
  },
  {
    name: 'Merchants',
    icon: <BusinessIcon />,
    route_substring: 'merchant',
    route: 'merchants',
  },
  {
    name: 'Drivers',
    icon: <MopedIcon />,
    route_substring: 'driver',
    route: 'drivers',
  },
  {
    name: 'Accounting',
    icon: <AttachMoneyIcon />,
    route_substring: 'accounting',
    route: 'accounting',
  },
];

const driver_menu_definition: MenuDefinitionItem[] = [
  {
    name: 'Order Lookup',
    icon: <SearchIcon />,
    route_substring: 'driver-order-lookup',
    route: 'driver-order-lookup',
  },
  {
    name: 'Invoices',
    icon: <AttachMoneyIcon />,
    route_substring: 'invoice',
    route: 'invoices',
  },
];

const merchant_menu_definition: MenuDefinitionItem[] = [
  {
    name: 'My Orders',
    icon: <InventoryIcon />,
    route_substring: 'order',
    route: 'merchant-orders',
  },
  {
    name: 'Invoices',
    icon: <AttachMoneyIcon />,
    route_substring: 'invoice',
    route: 'invoices',
  },
];

export const get_menu_definition = (usertype: string): MenuDefinitionItem[] => {
  switch (usertype) {
    case UserType.ORGANISATION: {
      return admin_menu_definition;
    }
    case UserType.EMPLOYEE: {
      return employee_menu_definition;
    }
    case UserType.DRIVER: {
      return driver_menu_definition;
    }
    case UserType.MERCHANT: {
      return merchant_menu_definition;
    }
    default: {
      return [];
    }
  }
};
