import React from 'react';
import { CustomerType } from '../../../interfaces/common/customer';
import { Box, Typography, Divider, Paper } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { primaryPaletteColor } from '../../../configurations/global.config';

interface CustomerPopoverDisplayProps {
    customer: CustomerType
}

function CustomerPopoverDisplay({ customer }: CustomerPopoverDisplayProps) {
    return (
        <Paper elevation={3} sx={{ padding: 2, maxWidth: 300 }}>
            <Typography variant="h6" component="div" gutterBottom>
                {customer.name}
            </Typography>
            <Divider />
            <Box sx={{ mt: 1 }}>
                <Box display="flex" alignItems="center" mb={1}>
                    <PhoneIcon fontSize="small" sx={{ mr: 1, color: primaryPaletteColor }} />
                    <Typography variant="body1">{customer.phone_number}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                    <HomeIcon fontSize="small" sx={{ mr: 1, color: primaryPaletteColor }} />
                    <Typography variant="body1">{customer.address}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <LocationOnIcon fontSize="small" sx={{ mr: 1, color: primaryPaletteColor }} />
                    <Typography variant="body1">
                        {customer.governorate} / {customer.district}
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
}

export default CustomerPopoverDisplay;
