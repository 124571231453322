import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Alert, Card, CircularProgress } from '@mui/material';
import {
  pendingApprovalColor,
  approvedColor,
  inTransitColor,
  deliveredColor,
  cancelledColor,
  totalColor,
} from '../../../../configurations/global.config';
import {
  cardStyle,
  cardHeaderStyle,
} from '../../../common/styles/grid-card.style';
import { generateMetricComponent } from '../utils';
import { WidgetProps } from '../home';

function DeliveryStatesWidget({ stats, isLoading, errorMessage }: WidgetProps) {
  console.log('stats = ', stats?.delivery.APPROVED);
  return (
    <Grid style={{ textAlign: 'center' }} xs={12} md={4}>
      <Card style={cardStyle}>
        <div style={cardHeaderStyle}>
          <BarChartIcon />
          <div>
            <b>Delivery States</b>
          </div>
        </div>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {errorMessage !== '' && (
              <Alert severity="error">{errorMessage}</Alert>
            )}

            {stats !== undefined && (
              <Grid container>
                <Grid style={{ textAlign: 'center' }} xs={6} md={4}>
                  {generateMetricComponent(
                    'Pending',
                    stats.delivery.PENDING_APPROVAL ?? 0,
                    pendingApprovalColor
                  )}
                </Grid>

                <Grid style={{ textAlign: 'center' }} xs={6} md={4}>
                  {generateMetricComponent(
                    'Approved',
                    stats.delivery.APPROVED ?? 0,
                    approvedColor
                  )}
                </Grid>

                <Grid style={{ textAlign: 'center' }} xs={6} md={4}>
                  {generateMetricComponent(
                    'In Transit',
                    stats.delivery.IN_TRANSIT ?? 0,
                    inTransitColor
                  )}
                </Grid>

                <Grid style={{ textAlign: 'center' }} xs={6} md={4}>
                  {generateMetricComponent(
                    'Delivered',
                    stats.delivery.DELIVERED ?? 0,
                    deliveredColor
                  )}
                </Grid>

                <Grid style={{ textAlign: 'center' }} xs={6} md={4}>
                  {generateMetricComponent(
                    'Cancelled',
                    stats.delivery.CANCELLED ?? 0,
                    cancelledColor
                  )}
                </Grid>

                <Grid style={{ textAlign: 'center' }} xs={6} md={4}>
                  {generateMetricComponent(
                    'Total',
                    Object.values(stats.delivery).reduce(
                      (item, acc) => acc + item ?? 0,
                      0
                    ),
                    totalColor
                  )}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Card>
    </Grid>
  );
}

export default DeliveryStatesWidget;
