import React, { useContext, useEffect, useState } from 'react';
import InvoicesListingTable from '../../../../common/invoices-listing-table/invoices-listing-table';
import { Box, TablePagination } from '@mui/material';
import DoubleDatePicker from '../../../../common/double-date-picker/double-date-picker';
import { DoubleDate } from '../../../../common/double-date-picker/double-date-picker.interfaces';
import { InvoiceMetaType } from '../../../../../interfaces/common/invoice';
import { AuthContext } from '../../../../../contexts/user-context/user-context';
import { API_ENDPOINT } from '../../../../../configurations/global.config';
import axios, { AxiosResponse } from 'axios';
import { UserType } from '../../../../../enums/users';

interface MerchantInvoicesListingProps {
  merchantId: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setResponse: React.Dispatch<React.SetStateAction<AxiosResponse<any, any> | undefined>>;
}

function MerchantInvoicesListing({ merchantId, setMessage, setResponse }: MerchantInvoicesListingProps) {
  const [invoicesList, setInvoicesList] = useState<InvoiceMetaType[]>([]);
  const [invoicesCount, setInvoicesCount] = useState<number>(0);
  const [dateFilter, setDateFilter] = useState<DoubleDate>({
    startDate: null,
    endDate: null,
  });

  const [paginationController, setPaginationController] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const [isLoading, setIsLoading] = useState(true);

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const canChangeInvoice = user?.type === UserType.ORGANISATION || user?.permissions?.includes("change_invoice")


  useEffect(() => {
    let response: any;
    const getData = async () => {
      let endpoint = `${API_ENDPOINT}/api/org/invoice-metas/?page=${paginationController.page + 1}&page_size=${paginationController.rowsPerPage}&merchant_id=${encodeURIComponent(merchantId)}`;

      const { startDate, endDate } = dateFilter;

      if (startDate !== null) {
        endpoint += `&date_from=${encodeURIComponent(startDate.format('YYYY-MM-DD'))}`;
      }

      if (endDate !== null) {
        endpoint += `&date_to=${encodeURIComponent(endDate.format('YYYY-MM-DD'))}`;
      }

      console.log(endpoint);
      try {
        response = await axios.get<{
          count: number;
          results: InvoiceMetaType[];
        }>(endpoint, config);
        console.log(response.data);

        setResponse(response)
        setInvoicesList(response.data.results);
        setInvoicesCount(response.data.count);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.log('error: ', e);
          setResponse(e.response);
          setMessage('Failed to fetch invoices: ' + e.response?.statusText);
        } else {
          setMessage('Failed to fetch invoices');
        }
        console.log(e);
      }

      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
  }, [dateFilter, paginationController, merchantId]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPaginationController({
      ...paginationController,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPaginationController({
      ...paginationController,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const resetPaginationController = () => {
    setPaginationController({
      page: 0,
      rowsPerPage: 10,
    });
  };

  const dateFilterAction = (dateFilterValue: DoubleDate) => {
    resetPaginationController();
    setDateFilter(dateFilterValue);
  };

  return (
    <Box>
      <DoubleDatePicker dateFilterAction={dateFilterAction} />

      <br />
      <br />

      <InvoicesListingTable
        allowEditNote={canChangeInvoice}
        rows={invoicesList}
        setRows={setInvoicesList}
        isLoading={isLoading}
        baseEndpoint={`${API_ENDPOINT}/api/org/merchant/${merchantId}`}
        setResponse={setResponse}
        setMessage={setMessage}
      />

      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={paginationController.page}
        count={invoicesCount}
        rowsPerPage={paginationController.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
        labelRowsPerPage=""
        style={{ overflow: 'visible' }}
      />
    </Box>
  );
}

export default MerchantInvoicesListing;
