import {
  Container,
  Box,
  Typography,
  LinearProgress,
  Alert,
  TablePagination,
} from '@mui/material';
import { useAttachedOrgs } from '../../../hooks/use-attached-orgs';
import { useContext, useEffect, useState } from 'react';
import OrgSetter from '../../common/org-setter/org-setter';
import { DoubleDate } from '../../common/double-date-picker/double-date-picker.interfaces';
import InvoicesListingTable from '../../common/invoices-listing-table/invoices-listing-table';
import DoubleDatePicker from '../../common/double-date-picker/double-date-picker';
import { InvoiceMetaType } from '../../../interfaces/common/invoice';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { API_ENDPOINT } from '../../../configurations/global.config';
import axios, { AxiosResponse } from 'axios';

function DriverInvoices() {
  const attachedOrgs = useAttachedOrgs();
  const [orgId, setOrgId] = useState<string>();
  const [invoices, setInvoices] = useState<InvoiceMetaType[]>([]);
  const [invoicesCount, setInvoicesCount] = useState<number>(0);
  const [response, setResponse] = useState<AxiosResponse>()
  const [message, setMessage] = useState<string>("")

  const [dateFilter, setDateFilter] = useState<DoubleDate>({
    startDate: null,
    endDate: null,
  });
  const [paginationController, setPaginationController] = useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  useEffect(() => {
    let response: any;
    if (orgId === undefined) return;
    const getData = async () => {
      let endpoint = `${API_ENDPOINT}/api/driver/invoice-metas/?page=${paginationController.page + 1}&page_size=${paginationController.rowsPerPage}&org=${encodeURIComponent(orgId)}`;

      const { startDate, endDate } = dateFilter;

      if (startDate !== null) {
        endpoint += `&date_from=${encodeURIComponent(startDate.format('YYYY-MM-DD'))}`;
      }

      if (endDate !== null) {
        endpoint += `&date_to=${encodeURIComponent(endDate.format('YYYY-MM-DD'))}`;
      }

      console.log(endpoint);
      try {
        response = await axios.get<{
          count: number;
          results: InvoiceMetaType[];
        }>(endpoint, config);
        console.log(response.data);

        if (response.statusText === 'OK') {
          setInvoices(response.data.results);
          setInvoicesCount(response.data.count);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
    console.log(response);
  }, [dateFilter, paginationController, orgId]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPaginationController({
      ...paginationController,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPaginationController({
      ...paginationController,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const resetPaginationController = () => {
    setPaginationController({
      page: 0,
      rowsPerPage: 10,
    });
  };

  const dateFilterAction = (dateFilterValue: DoubleDate) => {
    resetPaginationController();
    setDateFilter(dateFilterValue);
  };

  if (attachedOrgs && attachedOrgs.length > 0)
    orgId === undefined && setOrgId(attachedOrgs[0]);

  return (
    <div>
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Invoices
        </Typography>

        <br />

        {attachedOrgs === undefined ? (
          <LinearProgress />
        ) : (
          <>
            {attachedOrgs.length === 0 ? (
              <Alert severity="error">
                {' '}
                You are not attached to any delivery organisation yet.
              </Alert>
            ) : (
              <>
                <OrgSetter
                  currentOrg={orgId}
                  setCurrentOrg={setOrgId}
                  attachedOrgs={attachedOrgs}
                />

                <br />
                <br />

                <DoubleDatePicker dateFilterAction={dateFilterAction} />
                <br />

                {message !== '' && (

                  <>
                    <Alert
                      severity={
                        response && response.status >= 200 && response.status < 300
                          ? 'success'
                          : 'error'
                      }
                    >
                      <>
                        {message}

                        {response &&
                          response.status >= 300 &&
                          Object.keys(response.data).map((key) => (
                            <>
                              <ul>
                                {
                                  typeof response.data[key] === "string" ?
                                    <li>{response.data[key]}</li> :
                                    response.data[key].map((validationError: string) => (
                                      <li key={key}>
                                        {key}: {validationError}
                                      </li>
                                    ))}
                              </ul>
                            </>
                          ))}
                      </>
                    </Alert>

                    <br />

                  </>

                )}

                <InvoicesListingTable
                  rows={invoices}
                  setRows={setInvoices}
                  isLoading={isLoading}
                  selectedOrg={orgId}
                  baseEndpoint={`${API_ENDPOINT}/api/driver`}
                  setResponse={setResponse}
                  setMessage={setMessage}
                />

                <TablePagination
                  component="div"
                  onPageChange={handlePageChange}
                  page={paginationController.page}
                  count={invoicesCount}
                  rowsPerPage={paginationController.rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
                  labelRowsPerPage=""
                  style={{ overflow: 'visible' }}
                />
              </>
            )}
          </>
        )}
      </Box>
    </div>
  );
}

export default DriverInvoices;
