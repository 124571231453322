import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface CopyLinkButtonProps {
  icon: JSX.Element;
  link: string;
}

function CopyLinkButton({ icon, link }: CopyLinkButtonProps) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <CopyToClipboard text={link} onCopy={handleCopy}>
      <Tooltip title={isCopied ? 'Link Copied!' : 'Copy Link'} arrow>
        <IconButton>{icon}</IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
}

export default CopyLinkButton;
