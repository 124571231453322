import {
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DriverType } from '../../../../interfaces/delivery-company/drivers';
import { useContext } from 'react';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { UserType } from '../../../../enums/users';

interface DriversListingTableProps {
  drivers: DriverType[];
  isLoading: boolean;
}

function DriversListingTable({ drivers, isLoading }: DriversListingTableProps) {

  const { user } = useContext(AuthContext)

  const canAssignOrders = user?.type === UserType.ORGANISATION || user?.permissions?.includes("change_order")

  return (
    <TableContainer style={{ maxHeight: '80%' }}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Phone</b>
              </TableCell>

              {
                canAssignOrders && <TableCell align="center">
                  <b>Assign Orders</b>{' '}
                </TableCell>
              }

              <TableCell align="center">
                <b>Has Account</b>{' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drivers.map((driver) => (
              <TableRow key={driver.id}>
                <TableCell align="center" style={{ cursor: 'pointer' }}>
                  <a
                    href={`/${driver.isGuest ? 'guest-' : ''}driver/${driver.id}`}
                  >
                    <b>{driver.name}</b>
                  </a>
                </TableCell>
                <TableCell align="center">{driver.phone_number}</TableCell>

                {

                  canAssignOrders && <TableCell align="center">
                    <a
                      href={`/${driver.isGuest ? 'guest-' : ''}driver-order-assigner/${driver.id}`}
                    >
                      <Chip
                        style={{ cursor: 'pointer' }}
                        icon={<AssignmentReturnIcon />}
                        color="primary"
                        label={`Assign Orders`}
                        variant={'outlined'}
                      />
                    </a>
                  </TableCell>
                }
                <TableCell align="center">
                  {driver.isGuest ? (
                    <CancelIcon sx={{ color: 'red' }} />
                  ) : (
                    <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                  )}{' '}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

export default DriversListingTable;
