import { useContext, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { useImmer } from 'use-immer';
import FilterBar from '../../../../common/filter-bar/filter-bar';
import { Filter } from '../../../../common/filter-bar/filter-bar.interface';
import SearchBar from '../../../../common/search-bar/search-bar';
import { DoubleDate } from '../../../../common/double-date-picker/double-date-picker.interfaces';
import DoubleDatePicker from '../../../../common/double-date-picker/double-date-picker';
import { OrderType } from '../../../../../interfaces/delivery-company/orders';
import OrdersListingTable from '../../../../common/orders-listing-table/orders-listing-table';
import { DeliveryState, PaymentState } from '../../../../../enums/orders';
import { AuthContext } from '../../../../../contexts/user-context/user-context';
import axios, { AxiosResponse } from 'axios';
import {
  API_ENDPOINT,
  primaryPaletteColor,
} from '../../../../../configurations/global.config';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { buildQueryParams } from '../../../../common/filter-bar/filter-bar.utils';
import { ensureZeroAfterCountryCode } from '../../../../../utils/phone_numbers';

interface DriverOrdersListingProps {
  driverId: string;
  deliveryState: DeliveryState.DELIVERED | DeliveryState.CANCELLED;
  switchToInvoices: () => void;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setResponse: React.Dispatch<React.SetStateAction<AxiosResponse<any, any> | undefined>>;
}

function DriverOrdersListing({
  driverId,
  deliveryState,
  switchToInvoices,
  setResponse,
  setMessage
}: DriverOrdersListingProps) {
  const [ordersList, setOrdersList] = useState<OrderType[]>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [dateFilter, setDateFilter] = useState<DoubleDate>({
    startDate: null,
    endDate: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedOrders, updateSelectedOrders] = useImmer<
    Map<string, OrderType>
  >(new Map<string, OrderType>());

  const [filters, setFilters] = useState<Filter[]>([]);

  const resetSelectionState = () => {
    updateSelectedOrders((draft) => draft.clear());
  };

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleGenerateInvoice = async () => {
    setIsLoading(true);
    setResponse(undefined);
    setMessage("");

    const requestData = {
      orders_keys: Array.from(selectedOrders.keys()),
      driver_id: driverId,
      delivery_state: deliveryState,
    };
    const endpoint = `${API_ENDPOINT}/api/org/invoice-metas/`;
    try {
      const response = await axios.post(endpoint, requestData, config);
      setResponse(response)
      setMessage("Invoice created successfully")
      switchToInvoices()
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.log('error: ', e);
        setResponse(e.response);
        setMessage('Failed to create invoice: ' + e.response?.statusText);
      } else {
        setMessage('Failed to create invoice');
      }
      console.log(e);
    }

    setIsLoading(false);
  };

  const totalSelected = selectedOrders.size;

  const searchOptions = [
    'Order_Id',
    'Ref_Number',
    'Customer_Name',
    'Customer_Phone',
  ];

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    searchColumn: 'Order_Id',
    searchValue: '',
    searchOperation: 'equals',
  });

  useEffect(() => {
    if (driverId === null) return;
    let response: any;
    const getData = async () => {
      let endpoint = `${API_ENDPOINT}/api/org/orders/?page=${controller.page + 1}&page_size=${controller.rowsPerPage}`;

      endpoint += `&driver_id=${encodeURIComponent(driverId)}&delivery_state=${encodeURIComponent(deliveryState)}&payment_state=${PaymentState.UNINVOICED}`;

      if (controller.searchColumn !== '' && ensureZeroAfterCountryCode(controller.searchValue) !== '') {
        endpoint += `&${controller.searchColumn.toLowerCase()}__${controller.searchOperation}=${encodeURIComponent(ensureZeroAfterCountryCode(controller.searchValue))}`;
      }

      const filterQueryParams = buildQueryParams(filters)
      endpoint += `${filterQueryParams}`

      const { startDate, endDate } = dateFilter;

      if (startDate !== null) {
        endpoint += `&date_from=${encodeURIComponent(startDate.format('YYYY-MM-DD'))}`;
      }

      if (endDate !== null) {
        endpoint += `&date_to=${encodeURIComponent(endDate.format('YYYY-MM-DD'))}`;
      }

      console.log(endpoint);
      try {
        response = await axios.get<{ count: number; results: OrderType[] }>(
          endpoint,
          config
        );

        setResponse(response)
        setOrdersList(response.data.results);
        setOrdersCount(response.data.count);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.log('error: ', e);
          setResponse(e.response);
          setMessage('Failed to fetch driver orders: ' + e.response?.statusText);
        } else {
          setMessage('Failed to fetch driver orders');
        }
        console.log(e);
      }

      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
    console.log(response);
  }, [controller, dateFilter, driverId, filters]);

  const buildOrdersFilters = async (query: string) => {
    if (query === '') {
      return [];
    }

    const endpoint = `${API_ENDPOINT}/api/org/orders/filters/?query=${encodeURIComponent(query)}`;
    let filters: Filter[] = [];
    try {
      const response = await axios.get<Filter[]>(endpoint, config);
      console.log(response);

      if (response.statusText === 'OK') {
        console.log('response data = ', response.data);
        filters = response.data;
        console.log(filters);
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.log(error);
    }
    return filters;
  };

  const filterQueryAction = (filters: Filter[]) => {
    resetPaginationControls();
    setFilters(filters);
    resetSelectionState();
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const searchQueryAction = (
    column: string,
    value: string,
    operation: string
  ) => {
    setController({
      ...controller,
      searchColumn: column,
      searchValue: value,
      searchOperation: operation,
    });
  };

  const resetPaginationControls = () => {
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
    });
  };

  const dateFilterAction = (dateFilterValue: DoubleDate) => {
    resetPaginationControls();
    setDateFilter(dateFilterValue);
  };

  return (
    <Box>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            variant="h5"
            component="h5"
            sx={{ textAlign: 'center', mt: 1, mb: 1 }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
              }}
            >
              <ManageSearchIcon fontSize="large" color="primary" />
              <span>
                <b>Search and Filter Controls</b>
              </span>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SearchBar
            searchOptions={searchOptions}
            searchQueryAction={searchQueryAction}
          />
          <br />

          <DoubleDatePicker dateFilterAction={dateFilterAction} />

          <br />

          <FilterBar
            currentlySelectedFilters={filters}
            filterQueryAction={filterQueryAction}
            handleFetchFilters={buildOrdersFilters}
            helpWidget={
              <div>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  color={primaryPaletteColor}
                >
                  <b>Available Filters</b>
                </Typography>

                <TableContainer component={Paper} elevation={0}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Filter By</b>
                        </TableCell>
                        <TableCell>
                          <b>Examples</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Merchant</TableCell>
                        <TableCell style={{ color: primaryPaletteColor }}>
                          <b>
                            merchant:jack
                            <br />
                            merchant:70123123
                          </b>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Region</TableCell>
                        <TableCell style={{ color: primaryPaletteColor }}>
                          <b>
                            district:akkar
                            <br />
                            governorate:north
                          </b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          />
        </AccordionDetails>
      </Accordion>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10,
          marginLeft: 10,
        }}
      >
        <Chip
          icon={<ChecklistIcon />}
          color="primary"
          label={`Selected ${totalSelected}/${ordersCount}`}
          variant={
            totalSelected === ordersCount && totalSelected > 0
              ? 'filled'
              : 'outlined'
          }
        />

        <Button
          disabled={totalSelected === 0}
          variant="contained"
          onClick={handleGenerateInvoice}
        >
          Create Invoice
        </Button>
      </div>

      <OrdersListingTable
        actionable={true}
        orders={ordersList}
        selectedOrders={selectedOrders}
        updateSelectedOrders={updateSelectedOrders}
        isLoading={isLoading}
      />

      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={ordersCount}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
        labelRowsPerPage=""
        style={{ overflow: 'visible' }}
      />
    </Box>
  );
}

export default DriverOrdersListing;
