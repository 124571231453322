import { OrderType } from '../../../interfaces/delivery-company/orders';

export const defaultOrgReportFieldsConfig = [
  {
    display_name: 'Order Id',
    width_percentage: '20%',
    get_values: (order: OrderType) => [order.order_id],
  },
  {
    display_name: 'Customer',
    width_percentage: '23%',
    get_values: (order: OrderType) => [
      order.customer?.name || 'unknown',
      order.customer?.phone_number || 'unknown',
    ],
  },
  {
    display_name: 'Address',
    width_percentage: '30%',
    get_values: (order: OrderType) => [order.address || ''],
  },
  {
    display_name: 'Price',
    width_percentage: '15%',
    get_values: (order: OrderType) => [
      Number.parseFloat(order.price_usd.toString()).toFixed(2) + '$',
      Number.parseFloat(order.price_lbp.toString()) + 'L.L',
    ],
  },
  {
    display_name: 'Del. Fee',
    width_percentage: '15%',
    get_values: (order: OrderType) => [
      Number.parseFloat(order.delivery_fee_usd.toString()).toFixed(2) + '$',
      Number.parseFloat(order.delivery_fee_lbp.toString()) + 'L.L',
    ],
  },
  {
    display_name: 'Commission',
    width_percentage: '15%',
    get_values: (order: OrderType) => [
      Number.parseFloat(order.driver_commission_usd.toString()).toFixed(2) +
      '$',
      Number.parseFloat(order.driver_commission_lbp.toString()) + 'L.L',
    ],
  },
];

export const defaultDriverReportFieldsConfig = [
  {
    display_name: 'Order Id',
    width_percentage: '19%',
    get_values: (order: OrderType) => [order.order_id],
  },
  {
    display_name: 'Customer',
    width_percentage: '16%',
    get_values: (order: OrderType) => [
      order.customer?.name || 'unknown',
      order.customer?.phone_number || 'unknown',
    ],
  },
  {
    display_name: 'Address',
    width_percentage: '17%',
    get_values: (order: OrderType) => [order.address || ''],
  },
  {
    display_name: 'Commission',
    width_percentage: '15%',
    get_values: (order: OrderType) => [
      Number.parseFloat(order.driver_commission_usd.toString()).toFixed(2) +
      '$',
      Number.parseFloat(order.driver_commission_lbp.toString()) + 'L.L',
    ],
  },
  {
    display_name: 'Price to collect',
    width_percentage: '15%',
    get_values: (order: OrderType) => {
      console.log('debugger: ', order);
      return [
        (
          Number.parseFloat(order.price_usd.toString()) +
          Number.parseFloat(order.delivery_fee_usd.toString())
        ).toFixed(2) + '$',
        Number.parseFloat(order.price_lbp.toString()) +
        Number.parseFloat(order.delivery_fee_lbp.toString()) +
        'L.L',
      ];
    },
  },
  {
    display_name: 'Notes',
    width_percentage: '18%',
    get_values: (order: OrderType) => [order.public_note],
  },
];

export const defaultMerchantReportFieldsConfig = [
  {
    display_name: 'Order Id',
    width_percentage: '16%',
    get_values: (order: OrderType) => [order.order_id],
  },
  {
    display_name: 'Ref.',
    width_percentage: '12%',
    get_values: (order: OrderType) => [order.reference_id],
  },
  {
    display_name: 'Customer',
    width_percentage: '18%',
    get_values: (order: OrderType) => [
      order.customer?.name || 'unknown',
      order.customer?.phone_number || 'unknown',
    ],
  },
  {
    display_name: 'Address',
    width_percentage: '18%',
    get_values: (order: OrderType) => [order.address || ''],
  },
  {
    display_name: 'Price',
    width_percentage: '12%',
    get_values: (order: OrderType) => [
      Number.parseFloat(order.price_usd.toString()).toFixed(2) + '$',
      Number.parseFloat(order.price_lbp.toString()) + 'L.L',
    ],
  },
  {
    display_name: 'Del. Fee',
    width_percentage: '12%',
    get_values: (order: OrderType) => [
      Number.parseFloat(order.delivery_fee_usd.toString()).toFixed(2) + '$',
      Number.parseFloat(order.delivery_fee_lbp.toString()) + 'L.L',
    ],
  },
  {
    display_name: 'Notes',
    width_percentage: '12%',
    get_values: (order: OrderType) => [order.public_note],
  },
];
