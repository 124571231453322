import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { showDeliveryState } from '../../common/styles/order-states.style';

interface ScannedOrdersViewProps {
  orders: OrderType[];
  removeOrder: any;
}

function ScannedOrdersView({ orders, removeOrder }: ScannedOrdersViewProps) {
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>
                <b>Order Id</b>
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                <b>Status</b>
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                <b>Remove</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow
                key={order.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  style={{ textAlign: 'center' }}
                  component="th"
                  scope="row"
                >
                  {order.order_id}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {showDeliveryState(order.delivery_state)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <IconButton
                    color="error"
                    aria-label="open drawer"
                    onClick={() => removeOrder(order.order_id)}
                    edge="start"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ScannedOrdersView;
