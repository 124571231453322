import React, { useContext, useEffect, useState } from 'react';
import { DriverType } from '../../../interfaces/delivery-company/drivers';
import {
  API_ENDPOINT,
  primaryPaletteColor,
} from '../../../configurations/global.config';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { DoubleDate } from '../../common/double-date-picker/double-date-picker.interfaces';
import { useImmer } from 'use-immer';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { Filter } from '../../common/filter-bar/filter-bar.interface';
import axios from 'axios';
import {
  Box,
  Card,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  TablePagination,
} from '@mui/material';
import BulkAction from '../../common/bulk-action/bulk-action';
import DoubleDatePicker from '../../common/double-date-picker/double-date-picker';
import FilterBar from '../../common/filter-bar/filter-bar';
import OrdersListingTable from '../../common/orders-listing-table/orders-listing-table';
import SearchBar from '../../common/search-bar/search-bar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { defaultDriverReportFieldsConfig } from '../../common/report-builder/fields-config';
import {
  compute_total_commission_lbp,
  compute_total_commission_usd,
  compute_total_delivery_fee_lbp,
  compute_total_delivery_fee_usd,
  compute_total_price_lbp,
  compute_total_price_usd,
} from '../../common/report-builder/utils';
import { buildQueryParams } from '../../common/filter-bar/filter-bar.utils';
import { ensureZeroAfterCountryCode } from '../../../utils/phone_numbers';

export interface DriverOrdersProps {
  driver: DriverType;
}

function DriverOrders({ driver }: DriverOrdersProps) {
  const [ordersList, setOrdersList] = useState<OrderType[]>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [dateFilter, setDateFilter] = useState<DoubleDate>({
    startDate: null,
    endDate: null,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedOrders, updateSelectedOrders] = useImmer<
    Map<string, OrderType>
  >(new Map());

  const resetSelectionState = () => {
    updateSelectedOrders((selectedOrders) => {
      selectedOrders.clear();
    });
  };

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const totalSelected = selectedOrders.size;

  const searchOptions = [
    'Order_Id',
    'Ref_Number',
    'Customer_Name',
    'Customer_Phone',
  ];

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    searchColumn: 'Order_Id',
    searchValue: '',
    searchOperation: 'equals',
  });

  const [filters, setFilters] = useState<Filter[]>([
    { key: 'driver_name', value: driver.name },
    { key: 'driver_phone', value: driver.phone_number },
  ]);

  const buildOrdersFilters = async (query: string) => {
    if (query === '') {
      return [];
    }

    const endpoint = `${API_ENDPOINT}/api/org/orders/filters/?query=${encodeURIComponent(query)}`;
    let filters: Filter[] = [];
    try {
      const response = await axios.get<Filter[]>(endpoint, config);
      console.log(response);

      if (response.statusText === 'OK') {
        console.log('response data = ', response.data);
        filters = response.data;
        console.log(filters);
      } else {
        throw new Error('Request failed');
      }
    } catch (error) {
      console.log(error);
    }
    return filters;
  };

  useEffect(() => {
    let response: any;
    const getData = async () => {
      const relevant_fields = [
        'id',
        'order_id',
        'reference_number',
        'creation_date',
        'merchant',
        'customer',
        'driver',
        'address',
        'district',
        'governorate',
        'price_usd',
        'price_lbp',
        'delivery_fee_lbp',
        'delivery_fee_usd',
        'number_of_packages',
        'delivery_state',
        'payment_state',
        'branch',
        'tracking_hash',
        'driver_commission_lbp',
        'driver_commission_usd',
        'public_note',
      ];

      let endpoint = `${API_ENDPOINT}/api/org/orders/?page=${controller.page + 1}&page_size=${controller.rowsPerPage}&fields=${relevant_fields.join(',')}`;

      if (controller.searchColumn !== '' && ensureZeroAfterCountryCode(controller.searchValue) !== '') {
        endpoint += `&${controller.searchColumn.toLowerCase()}__${controller.searchOperation}=${encodeURIComponent(ensureZeroAfterCountryCode(controller.searchValue))}`;
      }

      const filterQueryParams = buildQueryParams(filters)
      endpoint += `${filterQueryParams}`


      const { startDate, endDate } = dateFilter;

      if (startDate !== null) {
        endpoint += `&date_from=${encodeURIComponent(startDate.format('YYYY-MM-DD'))}`;
      }

      if (endDate !== null) {
        endpoint += `&date_to=${encodeURIComponent(endDate.format('YYYY-MM-DD'))}`;
      }

      console.log(endpoint);
      try {
        response = await axios.get<{ count: number; results: OrderType[] }>(
          endpoint,
          config
        );

        if (response.statusText === 'OK') {
          setOrdersList(response.data.results);
          setOrdersCount(response.data.count);

          console.log(response.data);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
    console.log(response);
  }, [controller, dateFilter, filters]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const searchQueryAction = (
    column: string,
    value: string,
    operation: string
  ) => {
    setController({
      ...controller,
      searchColumn: column,
      searchValue: value,
      searchOperation: operation,
    });
  };

  const resetPaginationControls = () => {
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
    });
  };
  const filterQueryAction = (filters: Filter[]) => {
    resetPaginationControls();
    setFilters(filters);
    resetSelectionState();
  };

  const dateFilterAction = (dateFilterValue: DoubleDate) => {
    resetPaginationControls();
    setDateFilter(dateFilterValue);
  };

  const resetAll = () => {
    resetPaginationControls();
    resetSelectionState();
  };

  return (
    <Box>
      <Card sx={{ padding: 2 }}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{ textAlign: 'center', mt: 1, mb: 1 }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                }}
              >
                <ManageSearchIcon fontSize="large" color="primary" />
                <span>
                  <b>Search and Filter Controls</b>
                </span>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SearchBar
              searchOptions={searchOptions}
              searchQueryAction={searchQueryAction}
            />
            <br />

            <DoubleDatePicker dateFilterAction={dateFilterAction} />

            <br />

            <FilterBar
              currentlySelectedFilters={filters}
              filterQueryAction={filterQueryAction}
              handleFetchFilters={buildOrdersFilters}
              helpWidget={
                <div>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    color={primaryPaletteColor}
                  >
                    <b>Available Filters</b>
                  </Typography>

                  <TableContainer component={Paper} elevation={0}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Filter By</b>
                          </TableCell>
                          <TableCell>
                            <b>Examples</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Driver</TableCell>
                          <TableCell style={{ color: primaryPaletteColor }}>
                            <b>
                              driver:jack
                              <br />
                              driver:70123123
                            </b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Merchant</TableCell>
                          <TableCell style={{ color: primaryPaletteColor }}>
                            <b>
                              merchant:jack
                              <br />
                              merchant:70123123
                            </b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Delivery State</TableCell>
                          <TableCell style={{ color: primaryPaletteColor }}>
                            <b>delivery_state:pending</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Payment State</TableCell>
                          <TableCell style={{ color: primaryPaletteColor }}>
                            <b>payment_state:uninvoiced</b>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Region</TableCell>
                          <TableCell style={{ color: primaryPaletteColor }}>
                            <b>
                              district:akkar
                              <br />
                              governorate:north
                            </b>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              }
            />
          </AccordionDetails>
        </Accordion>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
            marginLeft: 10,
          }}
        >
          <Chip
            icon={<ChecklistIcon />}
            color="primary"
            label={`Selected ${totalSelected}/${ordersCount}`}
            variant={
              totalSelected === ordersCount && totalSelected > 0
                ? 'filled'
                : 'outlined'
            }
          />

          <BulkAction
            selectedOrders={selectedOrders}
            bulkEndpoint={`${API_ENDPOINT}/api/org/orders/bulk-update/`}
            refetchOrders={resetAll}
            extractAsPdf={{
              fileName: `${driver.name}.${new Date().toLocaleString()}.pdf`,
              fields_config: defaultDriverReportFieldsConfig,
              subtitle: `Driver: ${driver.name} / ${driver.phone_number}`,
              date: new Date().toLocaleString(),
              tableTitle: 'Driver Orders',
              table_footer_rows: [
                {
                  label: 'total orders',
                  compute_value: (orders: OrderType[]) =>
                    orders.length.toString(),
                },
                {
                  label: 'price to collect',
                  compute_value: (orders) =>
                    `${compute_total_price_usd(orders) + compute_total_delivery_fee_usd(orders)} $ + ${compute_total_price_lbp(orders) + compute_total_delivery_fee_lbp(orders)} L.L`,
                },
                {
                  label: 'driver commission',
                  compute_value: (orders) =>
                    `${compute_total_commission_usd(orders)} $ + ${compute_total_commission_lbp(orders)} L.L`,
                },
              ],
            }}
          />
        </div>

        <OrdersListingTable
          actionable={true}
          orders={ordersList}
          selectedOrders={selectedOrders}
          updateSelectedOrders={updateSelectedOrders}
          isLoading={isLoading}
        />

        <TablePagination
          component="div"
          onPageChange={handlePageChange}
          page={controller.page}
          count={ordersCount}
          rowsPerPage={controller.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
          labelRowsPerPage=""
          style={{ overflow: 'visible' }}
        />
      </Card>
    </Box>
  );
}

export default DriverOrders;
