import SearchIcon from '@mui/icons-material/Search';
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  LinearProgress,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Howl } from 'howler';
import { Html5QrcodeResult } from 'html5-qrcode';
import React from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { useAttachedOrgs } from '../../../hooks/use-attached-orgs';
import OrgSetter from '../../common/org-setter/org-setter';
import BarcodeScanner from '../../common/scanner/scanner-input';
import OrderLookupDialog from './driver-order-lookup-dialog';

function DriverOrderLookup() {
  const [useCameraChecked, setUseCameraChecked] =
    React.useState<boolean>(false);
  const [orderId, setOrderId] = React.useState<string>('');

  const [orderIdInput, setOrderIdInput] = React.useState<string>('');
  const [orderScannedId, setOrderScannedId] = React.useState<string>('');
  const [orgId, setOrgId] = React.useState<string>();

  const attachedOrgs = useAttachedOrgs();

  if (attachedOrgs && attachedOrgs.length > 0)
    orgId === undefined && setOrgId(attachedOrgs[0]);

  const beepSound = new Howl({
    src: ['/media/Barcode-scanner-beep-sound.mp3'],
    volume: 0.0075,
  });

  const onNewCameraScanResult = (
    newDecodedText: string,
    newDecodedResult: Html5QrcodeResult
  ) => {
    setOrderScannedId(newDecodedText);
  };

  const handleUseCameraChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUseCameraChecked(event.target.checked);
  };

  const onCloseDialog = () => {
    setOrderId('');
    setOrderIdInput('');
    setOrderScannedId('');
  };

  React.useEffect(() => {
    if (orderId === '') {
      if (orderScannedId !== '') beepSound.play();
      setOrderId(orderScannedId);
    }
  }, [orderScannedId]);

  return (
    <div>
      <Container>
        <Box>
          <Typography
            variant="h3"
            component="h3"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
          >
            Order Lookup
          </Typography>

          <br />

          {attachedOrgs === undefined ? (
            <LinearProgress />
          ) : (
            <>
              {attachedOrgs.length === 0 ? (
                <Alert severity="error">
                  {' '}
                  You are not attached to any delivery organisation yet.
                </Alert>
              ) : (
                <>
                  {/* Use Device Camera to scan barcodes */}
                  <div style={{ display: 'flex', alignContent: 'center' }}>
                    <Switch
                      disabled={isBrowser}
                      onChange={handleUseCameraChange}
                      checked={useCameraChecked}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      Use device camera to scan orders
                    </div>
                  </div>

                  {!isMobile && (
                    <div
                      style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Alert severity="warning">
                        Using device camera is only available on mobile devices
                      </Alert>
                    </div>
                  )}

                  <br />

                  <OrgSetter
                    currentOrg={orgId}
                    setCurrentOrg={setOrgId}
                    attachedOrgs={attachedOrgs}
                  />

                  <br />

                  {!useCameraChecked && (
                    <>
                      <br />
                      <form
                        onSubmit={async (e) => {
                          e.preventDefault();
                          setOrderId(orderIdInput);
                        }}
                      >
                        <div style={{ display: 'flex', gap: 3 }}>
                          <br />
                          <FormControl fullWidth>
                            <TextField
                              label="Type order id or scan order barcode"
                              type="text"
                              variant="outlined"
                              required
                              value={orderIdInput}
                              onChange={(e) => setOrderIdInput(e.target.value)}
                            />
                          </FormControl>
                          <Button variant="contained" type={'submit'}>
                            <SearchIcon />
                          </Button>
                        </div>
                      </form>
                    </>
                  )}

                  {
                    /* Open device camera if required */
                    isMobile && (
                      <>
                        <br />
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          {
                            useCameraChecked && <BarcodeScanner
                              onSuccessfullScan={onNewCameraScanResult}
                            />
                          }
                        </div>
                      </>
                    )
                  }

                  {orderId.length > 0 && (
                    <OrderLookupDialog
                      orderId={orderId}
                      onCloseDialog={onCloseDialog}
                      orgId={orgId}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default DriverOrderLookup;
