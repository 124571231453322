import {
  Checkbox,
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import LinkIcon from '@mui/icons-material/Link';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useContext } from 'react';
import { Updater } from 'use-immer';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { DeliveryState } from '../../../enums/orders';
import { UserType } from '../../../enums/users';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import CopyLinkButton from '../copy-link-button/copy-link-button';
import {
  showDeliveryState,
  showPaymentState
} from '../styles/order-states.style';
import GenericPopover from '../generic-popover/generic-popover';
import CustomerPopoverDisplay from '../../merchant-components/utils/customer-popover-display';
import { emptyCustomer } from '../../../data/customers';
import CustomerLinkOrPopover from '../customer-link-or-popover/customer-link-or-popover';

interface OrdersListingTableProps {
  orders: OrderType[];
  selectedOrders: Map<string, OrderType>;
  updateSelectedOrders: Updater<Map<string, OrderType>>;
  isLoading: boolean;
  actionable?: boolean;
  generateOrderRoute?: (order: OrderType) => string;
}

function OrdersListingTable({
  orders,
  selectedOrders,
  updateSelectedOrders,
  isLoading,
  actionable,
  generateOrderRoute,
}: OrdersListingTableProps) {
  const toggleIdSelection = (order: OrderType) => {
    updateSelectedOrders((draft) => {
      const rowId = order.id;

      if (draft.has(rowId)) draft.delete(rowId);
      else draft.set(rowId, order);
    });
  };

  const selectAllRows = (orders: OrderType[]) => {
    updateSelectedOrders((draft) => {
      orders.forEach((order) => draft.set(order.id, order));
    });
  };

  const unSelectAllRows = (orders: OrderType[]) => {
    updateSelectedOrders((draft) => {
      orders.forEach((order) => draft.delete(order.id));
    });
  };

  const currentIds = orders.map((order) => order.id);

  const numSelected = currentIds.reduce(
    (result: number, orderId: string) =>
      selectedOrders.has(orderId) ? result + 1 : result,
    0
  );
  const rowCount = orders.length;
  const allSelected = numSelected === rowCount;

  const { user } = useContext(AuthContext);
  console.log(user)

  if (user === null) return <div>Unauthenticated</div>;

  return (
    <TableContainer style={{ maxHeight: '80%' }}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {actionable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={
                      (allSelected ||
                        (rowCount > 0 && numSelected === rowCount)) &&
                      numSelected > 0
                    }
                    onChange={(e) => {
                      if (allSelected) unSelectAllRows(orders);
                      else selectAllRows(orders);
                    }}
                  />
                </TableCell>
              )}

              <TableCell>
                {' '}
                <b>Order Id</b>
              </TableCell>
              <TableCell>
                <b>Ref #</b>
              </TableCell>
              <TableCell>
                <b>Creation Date</b>
              </TableCell>
              {user.type !== UserType.MERCHANT && (
                <TableCell>
                  <b>Merchant</b>
                </TableCell>
              )}
              <TableCell>
                <b>Customer</b>
              </TableCell>
              {user.type !== UserType.MERCHANT && (
                <TableCell>
                  <b>Driver</b>
                </TableCell>
              )}
              <TableCell>
                <b>Address</b>
              </TableCell>
              <TableCell>
                <b>Region</b>
              </TableCell>
              <TableCell>
                <b>Price</b>
              </TableCell>
              <TableCell>
                <b>Delivery Fee</b>
              </TableCell>
              <TableCell>
                <b>Nbr. Packages</b>
              </TableCell>
              <TableCell>
                <b>Delivery State</b>
              </TableCell>
              <TableCell>
                <b>Payment State</b>
              </TableCell>
              <TableCell>
                <b>Branch</b>
              </TableCell>
              <TableCell>
                <b>Tracking Link</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.order_id}>
                {actionable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedOrders.has(order.id)}
                      onChange={(e) => {
                        toggleIdSelection(order);
                      }}
                    />
                  </TableCell>
                )}

                <TableCell style={{ cursor: 'pointer' }}>
                  <a
                    href={
                      generateOrderRoute === undefined
                        ? `/order/${order.id}`
                        : generateOrderRoute(order)
                    }
                  >
                    <b>
                      {order.delivery_state ===
                        DeliveryState.PENDING_APPROVAL ? (
                        <>
                          <Chip
                            label={<b>{order.order_id || 'NEW'}</b>}
                            variant="filled"
                            style={{
                              margin: 1,
                              backgroundColor: 'rgba(200, 245, 0)',
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {order.order_id || (
                            <>
                              <QuestionMarkIcon />
                              <QuestionMarkIcon />
                            </>
                          )}
                        </>
                      )}
                    </b>
                  </a>
                </TableCell>
                <TableCell>{order.reference_id}</TableCell>
                <TableCell>
                  {order.creation_date !== undefined &&
                    new Date(order.creation_date).toLocaleString()}
                </TableCell>

                {user.type !== UserType.MERCHANT && (
                  <TableCell style={{ cursor: 'pointer' }}>
                    <a href={`/merchant/${order.merchant.id}`}>
                      <b>{order.merchant.name}</b>
                    </a>
                  </TableCell>
                )}

                <TableCell style={{ cursor: 'pointer' }}>
                  <CustomerLinkOrPopover customer={order.customer} />
                </TableCell>
                {user.type !== UserType.MERCHANT && (
                  <TableCell
                    style={order.driver === null ? {} : { cursor: 'pointer' }}
                  >
                    <a
                      href={`/${order.driver?.isGuest ? 'guest-' : ''}driver/${order.driver?.id}`}
                    >
                      <b>{order.driver && order.driver.name}</b>
                    </a>
                  </TableCell>
                )}
                <TableCell>{order.address}</TableCell>
                <TableCell>
                  {order.governorate}/{order.district}
                </TableCell>

                <TableCell>
                  {order.price_usd} $ <br />
                  {order.price_lbp} L.L
                </TableCell>
                <TableCell>
                  {order.delivery_fee_usd} $ <br />
                  {order.delivery_fee_lbp} L.L
                </TableCell>

                <TableCell>{order.number_of_packages}</TableCell>
                <TableCell>{showDeliveryState(order.delivery_state)}</TableCell>
                <TableCell>{showPaymentState(order.payment_state)}</TableCell>
                <TableCell
                  style={{ cursor: order.branch !== null ? 'pointer' : '' }}
                >
                  {user.type === UserType.MERCHANT ? (
                    order.branch?.name
                  ) : (
                    <a href={`/branch/${order.branch?.id}`}>
                      <b>{order.branch?.name}</b>
                    </a>
                  )}
                </TableCell>
                <TableCell>
                  <CopyLinkButton
                    icon={<LinkIcon color="primary" />}
                    link={
                      window.location.origin +
                      '/tracking/' +
                      order.tracking_hash
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

export default OrdersListingTable;
