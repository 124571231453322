import {
  Box,
  FormControl
} from '@mui/material';
import MultipleAutocompleteWithThrottle from '../multiple-autocomplete-with-throttle/multiple-autocomplete-with-throttle';
import { Filter, FilterBarProps } from './filter-bar.interface';
import { displayFilter } from './filter-bar.utils';

function FilterBar({
  handleFetchFilters,
  filterQueryAction,
  currentlySelectedFilters,
  helpWidget,
}: FilterBarProps) {
  return (
    <Box
      component="fieldset"
      border={'none'}
      borderTop={'1px solid rgb(0,0,0,0.1)'}
    >
      <legend>
        <b>Filter Controls</b>
      </legend>
      <FormControl fullWidth>
        <MultipleAutocompleteWithThrottle
          label={'Filters'}
          fetch={handleFetchFilters}
          value={currentlySelectedFilters}
          getOptionLabel={(option: Filter) => displayFilter(option)}
          isOptionEqualToValue={(option: Filter, choice: Filter) => {
            return option.key === choice.key && option.value === choice.value;
          }}
          filterHelp={helpWidget}
          onChange={(e, value) => {
            if (e === null) return;
            if (value == null) {
              filterQueryAction([]);
            } else {
              filterQueryAction(value);
            }
          }}
        />
      </FormControl>
    </Box>
  );
}

export default FilterBar;
