import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ClearIcon } from '@mui/x-date-pickers';
import axios, { AxiosResponse } from 'axios';
import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { emptyCustomer } from '../../../data/customers';
import { emptyMerchant } from '../../../data/merchants';
import { RegionType, emptyRegion, regions } from '../../../data/regions';
import { DeliveryState, PaymentState } from '../../../enums/orders';
import { CustomerType } from '../../../interfaces/common/customer';
import { BranchType } from '../../../interfaces/delivery-company/branches';
import {
  CompactDriverType,
  DriverType,
} from '../../../interfaces/delivery-company/drivers';
import {
  CompactMerchantType,
  MerchantType,
} from '../../../interfaces/delivery-company/merchants';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import AutocompleteWithThrottle from '../../common/autocomplete-with-throttle/autocomplete-with-throttle';
import {
  assignBranchProvider,
  assignCustomerProvider,
  assignMerchantProvider,
} from '../../common/create-callbacks-utils/create-callbacks-utils';
import CreateCustomerForm from '../../common/create-customer-form/create-customer-form';
import TextFieldWithScan from '../../common/textfield-with-scan/textfield-with-scan';
import CreateBranchForm from '../branches/create-branch-form/create-branch-form';
import CreateGuestOrAddDriver from '../drivers/driver-forms/create-guest-or-add-driver';
import CreateMerchantForm from '../merchants/merchant-forms/create-or-edit-merchant';

function EditOrder() {
  const { orderId } = useParams();
  const [orderData, setOrderData] = React.useState<OrderType>();
  const [creatingNewCustomerOpen, setCreatingNewCustomerOpen] =
    useState<boolean>(false);
  const [creatingNewMerchantOpen, setCreatingNewMerchantOpen] =
    useState<boolean>(false);
  const [creatingNewBranchOpen, setCreatingNewBranchOpen] =
    useState<boolean>(false);
  const [creatingNewDriverOpen, setCreatingNewDriverOpen] =
    useState<boolean>(false);

  const initialDeliveryState = useRef<DeliveryState>();

  const [regionSearchInput, setRegionSearchInput] = useState(
    orderData?.district
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');
  const [autoAssignOrderId, setAutoAssignOrderId] = useState<boolean>(false)

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  React.useEffect(() => {
    console.log('use effect');
    const getAndSetOrder = async () => {
      if (orderId !== undefined) {
        setIsLoading(true);
        try {
          const endpoint = `${API_ENDPOINT}/api/org/order/${orderId}/`;
          console.log(endpoint);
          const response = await axios.get<OrderType>(endpoint, config);
          console.log(response);
          if (response.statusText == 'OK') {
            setOrderData(response.data);
            initialDeliveryState.current = response.data.delivery_state;
          }
        } catch (e) {
          setOrderData(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load order data: ' + e.message);
          } else {
            setMessage('Failed to load order data');
          }
          console.log(e);
        }
        setIsLoading(false);
      }
    };
    getAndSetOrder();
  }, [orderId]);

  const handleFetchCustomers = async (
    query: string
  ): Promise<CustomerType[]> => {
    if (orderData) {
      const endpoint = `${API_ENDPOINT}/api/org/customers/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;

      try {
        const response = await axios.get<{
          count: number;
          results: CustomerType[];
        }>(endpoint, config);

        if (response.statusText === 'OK') {
          return response.data.results;
        }

        return [];
      } catch (error) {
        console.log(error);
      }
    }

    return [];
  };

  const handleFetchMerchants = async (
    query: string
  ): Promise<MerchantType[]> => {
    if (orderData) {
      const endpoint = `${API_ENDPOINT}/api/org/merchants/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;

      try {
        const response = await axios.get<{
          count: number;
          results: MerchantType[];
        }>(endpoint, config);

        if (response.statusText === 'OK') {
          return response.data.results;
        }

        return [];
      } catch (error) {
        console.log(error);
      }
    }

    return [];
  };

  const handleFetchBranches = async (query: string) => {
    if (orderData) {
      const endpoint = `${API_ENDPOINT}/api/org/branches/?page_size=10&name=${encodeURIComponent(query)}`;

      try {
        const response = await axios.get<{
          count: number;
          results: BranchType[];
        }>(endpoint, config);

        console.log(response);

        if (response.statusText === 'OK') {
          return response.data.results;
        }

        return [];
      } catch (error) {
        console.log(error);
      }
    }

    return [];
  };

  const handleFetchDrivers = async (query: string): Promise<DriverType[]> => {
    if (orderData) {
      const endpoint = `${API_ENDPOINT}/api/org/drivers/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;

      try {
        const response = await axios.get<{
          count: number;
          results: DriverType[];
        }>(endpoint, config);

        if (response.statusText === 'OK') {
          return response.data.results;
        }

        return [];
      } catch (error) {
        console.log(error);
      }
    }

    return [];
  };

  // Initialise data assigners to be used as success callbacks when creating entities inline
  const assignCustomer = assignCustomerProvider({
    orderData,
    setOrderData,
    setRegionSearchInput,
  });
  const assignMerchant = assignMerchantProvider({ orderData, setOrderData });
  const assignBranch = assignBranchProvider({ orderData, setOrderData });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (orderData) {
      setIsLoading(true);
      setResponse(undefined);
      setMessage('');
      setLoadingMessage('Saving Order...');

      let requestData = orderData;

      if (initialDeliveryState.current !== requestData.delivery_state) {
        requestData = {
          ...requestData,
          payment_state: PaymentState.UNINVOICED,
        };
      }

      if (
        [DeliveryState.IN_TRANSIT, DeliveryState.DELIVERED].includes(
          requestData.delivery_state
        )
      ) {
        if (!orderData.driver) {
          setMessage(
            `You can't set state to ${requestData.delivery_state} without setting a driver.`
          );
          setIsLoading(false);
          return;
        }
      }

      if (
        orderData.driver &&
        [DeliveryState.PENDING_APPROVAL, DeliveryState.APPROVED].includes(
          orderData.delivery_state
        )
      ) {
        setMessage(
          `You can't assign order to a driver while order is in ${requestData.delivery_state} state. Please modify order state as well.`
        );
        setIsLoading(false);
        return;
      }

      try {
        const endpoint = `${API_ENDPOINT}/api/org/order/${orderData.id}/`;

        console.log('Request Data: ');
        console.log(requestData);

        // update order
        const response = await axios.put(endpoint, requestData, {
          headers: { ...config.headers, 'X-Auto-Assign-ID': autoAssignOrderId }
        });

        setResponse(response);
        console.log(response);

        if (response.status >= 200 && response.status < 300) {
          setMessage('Order saved successfully');
        }
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response)
          setMessage('Failed to save order: ' + e.response?.statusText);
        } else {
          setMessage('Failed to save order');
        }
        console.log(e);
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  return (
    <div className="content-container">
      <Container>
        <Box>
          <Typography
            variant="h3"
            component="h3"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
          >
            Edit Order
          </Typography>

          {orderData === undefined ? (
            <LinearProgress />
          ) : (
            <>
              {message != '' && (
                <Alert
                  severity={
                    response && response.status >= 200 && response.status < 300
                      ? 'success'
                      : 'error'
                  }
                >
                  <>
                    {message}

                    {response &&
                      response.status >= 300 &&
                      Object.keys(response.data).map((key) => (
                        <>
                          <ul>
                            {
                              typeof response.data[key] === "string" ?
                                <li>{response.data[key]}</li> :
                                response.data[key].map((validationError: string) => (
                                  <li key={key}>
                                    {key}: {validationError}
                                  </li>
                                ))}
                          </ul>
                        </>
                      ))}
                  </>
                </Alert>
              )}

              {isLoading ? (
                <>
                  {' '}
                  {loadingMessage} <br /> <LinearProgress />{' '}
                </>
              ) : (
                <>
                  {orderData && (
                    <>
                      <br></br>

                      <Switch
                        onChange={() => {
                          setAutoAssignOrderId(!autoAssignOrderId)
                        }}
                        checked={autoAssignOrderId}
                      />
                      Assign Order Id Automatically
                      <br /><br />

                      <form onSubmit={handleSubmit}>
                        <FormControl fullWidth>
                          <Grid container spacing={2}>
                            {!autoAssignOrderId && <Grid xs={12} md={6}>
                              <TextFieldWithScan
                                label="Order Id"
                                type="text"
                                variant="outlined"
                                fullWidth
                                value={orderData.order_id}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }
                                }}
                                act={(value) => {
                                  setOrderData({
                                    ...orderData,
                                    order_id: value,
                                  })
                                }
                                }
                              />
                            </Grid>}

                            <Grid xs={12} md={autoAssignOrderId ? 12 : 6}>
                              <TextFieldWithScan
                                label="Reference Number"
                                type="text"
                                variant="outlined"
                                fullWidth
                                value={orderData.reference_id}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }
                                }}
                                act={(value) => {
                                  console.log("fired from onchange with: ", value)
                                  setOrderData({
                                    ...orderData,
                                    reference_id: value,
                                  })
                                }}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>

                        <br />
                        <br />

                        <FormControl fullWidth>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ flexGrow: 1 }}
                          >
                            <Grid xs={12} md={6}>
                              <AutocompleteWithThrottle
                                label={'Customer'}
                                onClickAdd={() =>
                                  setCreatingNewCustomerOpen(true)
                                }
                                fetch={handleFetchCustomers}
                                value={orderData.customer}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                getOptionLabel={(option: CustomerType) =>
                                  option.name + ' ' + option.phone_number
                                }
                                onChange={(e, value) => {
                                  assignCustomer(value ?? emptyCustomer);
                                }}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <AutocompleteWithThrottle
                                label={'Merchant'}
                                value={orderData.merchant}
                                onClickAdd={() =>
                                  setCreatingNewMerchantOpen(true)
                                }
                                fetch={handleFetchMerchants}
                                getOptionLabel={(option: CompactMerchantType) =>
                                  option.name + ' ' + option.phone_number
                                }
                                onChange={(e, value) => {
                                  setOrderData({
                                    ...orderData,
                                    merchant: value ? value : emptyMerchant,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>

                        <br />
                        <br />

                        <FormControl fullWidth>
                          <Grid container spacing={2}>
                            <Grid xs={12} md={6}>
                              <TextField
                                label="Address"
                                type="string"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.address}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    address: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <Autocomplete
                                disablePortal
                                id="district"
                                isOptionEqualToValue={(option, value) =>
                                  option.district === value.district
                                }
                                value={
                                  orderData.district !== ''
                                    ? {
                                      district: orderData.district,
                                      governorate: orderData.governorate,
                                    }
                                    : null
                                }
                                onChange={(_, newValue: RegionType | null) => {
                                  if (newValue === null) newValue = emptyRegion;

                                  setOrderData({
                                    ...orderData,
                                    district: newValue.district,
                                    governorate: newValue.governorate,
                                  });
                                }}
                                inputValue={regionSearchInput}
                                onInputChange={(event, newInputValue) => {
                                  if (event === null) return;
                                  setRegionSearchInput(newInputValue);
                                }}
                                options={regions.sort((a, b) =>
                                  b.governorate.localeCompare(a.governorate)
                                )}
                                groupBy={(option) => option.governorate}
                                getOptionLabel={(option) => option.district}
                                renderInput={(params) => (
                                  <TextField {...params} label="District" />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>

                        <br />
                        <br />

                        <FormControl fullWidth>
                          <AutocompleteWithThrottle
                            label={'Branch'}
                            onClickAdd={() => setCreatingNewBranchOpen(true)}
                            fetch={handleFetchBranches}
                            value={orderData.branch ? orderData.branch : null}
                            getOptionLabel={(option: BranchType) =>
                              option.name + ' - ' + option.address
                            }
                            required={false}
                            onChange={(e, value) => {
                              const newOrderData = {
                                ...orderData,
                                branch: value ? value : null,
                              };

                              setOrderData(newOrderData);
                            }}
                          />
                        </FormControl>
                        <br />
                        <br />

                        <FormControl fullWidth>
                          <Grid container spacing={2}>
                            <Grid xs={12} md={6}>
                              <InputLabel id="demo-simple-select">
                                Delivery State
                              </InputLabel>
                              <Select
                                required
                                fullWidth
                                id="demo-simple-select"
                                value={Object.values(DeliveryState).indexOf(
                                  orderData.delivery_state
                                )}
                                label="Delivery State"
                                onChange={(e) => {
                                  const index = parseInt(
                                    e.target.value.toString()
                                  );
                                  const state =
                                    Object.values(DeliveryState)[index];

                                  const newOrderData = {
                                    ...orderData,
                                    delivery_state: state,
                                  };

                                  setOrderData(newOrderData);
                                }}
                              >
                                {Object.values(DeliveryState).map(
                                  (state, index) => (
                                    <MenuItem key={index} value={index}>
                                      {state}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </Grid>

                            <Grid xs={12} md={6}>
                              <FormControl fullWidth>
                                <AutocompleteWithThrottle
                                  label={'Driver'}
                                  required={false}
                                  onClickAdd={() =>
                                    setCreatingNewDriverOpen(true)
                                  }
                                  fetch={handleFetchDrivers}
                                  value={
                                    orderData.driver ? orderData.driver : null
                                  }
                                  getOptionLabel={(option: CompactDriverType) =>
                                    option.name + ' - ' + option.phone_number
                                  }
                                  onChange={(e, value) => {
                                    const newOrderData = {
                                      ...orderData,
                                      driver: value ? value : null,
                                    };
                                    setOrderData(newOrderData);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Grid container spacing={2}>
                            <Grid xs={6}>
                              <TextField
                                label="Order Price $"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.price_usd}
                                inputProps={{
                                  maxLength: 13,
                                  step: '0.01',
                                }}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    'wheel',
                                    (e) => e.preventDefault(),
                                    { passive: false }
                                  )
                                }
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    price_usd: parseFloat(e.target.value),
                                  })
                                }
                              />
                            </Grid>

                            <Grid xs={6}>
                              <TextField
                                label="Order Price L.L"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.price_lbp}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    price_lbp: parseInt(e.target.value),
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <br />
                        <br />

                        <FormControl fullWidth>
                          <Grid container spacing={2}>
                            <Grid xs={6}>
                              <TextField
                                label="Delivery Fee $"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.delivery_fee_usd}
                                inputProps={{
                                  maxLength: 13,
                                  step: '0.01',
                                }}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    'wheel',
                                    (e) => e.preventDefault(),
                                    { passive: false }
                                  )
                                }
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    delivery_fee_usd: parseFloat(
                                      e.target.value
                                    ),
                                  })
                                }
                              />
                            </Grid>

                            <Grid xs={6}>
                              <TextField
                                label="Delivery Fee L.L"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.delivery_fee_lbp}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    delivery_fee_lbp: parseInt(e.target.value),
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Grid container spacing={2}>
                            <Grid xs={6}>
                              <TextField
                                label="Driver Commission $"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.driver_commission_usd}
                                inputProps={{
                                  maxLength: 13,
                                  step: '0.01',
                                }}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    'wheel',
                                    (e) => e.preventDefault(),
                                    { passive: false }
                                  )
                                }
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    driver_commission_usd: parseFloat(
                                      e.target.value
                                    ),
                                  })
                                }
                              />
                            </Grid>

                            <Grid xs={6}>
                              <TextField
                                label="Driver Commission L.L"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.driver_commission_lbp}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    driver_commission_lbp: parseInt(
                                      e.target.value
                                    ),
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <TextField
                            label="Number of Packages"
                            type="number"
                            variant="outlined"
                            required
                            value={orderData.number_of_packages}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                'wheel',
                                (e) => e.preventDefault(),
                                { passive: false }
                              )
                            }
                            onChange={(e) =>
                              setOrderData({
                                ...orderData,
                                number_of_packages: parseInt(e.target.value),
                              })
                            }
                          />
                        </FormControl>

                        <br />
                        <br />

                        <FormControl fullWidth>
                          <TextField
                            label="Description"
                            type="text"
                            variant="outlined"
                            multiline
                            value={orderData.description}
                            rows={2}
                            onChange={(e) =>
                              setOrderData({
                                ...orderData,
                                description: e.target.value,
                              })
                            }
                          />
                        </FormControl>

                        <br />
                        <br />
                        <FormControl fullWidth>
                          <TextField
                            label="Public Note"
                            type="text"
                            variant="outlined"
                            multiline
                            value={orderData.public_note}
                            rows={2}
                            onChange={(e) =>
                              setOrderData({
                                ...orderData,
                                public_note: e.target.value,
                              })
                            }
                          />
                        </FormControl>

                        <br />
                        <br />

                        <FormControl fullWidth>
                          <TextField
                            label="Private Note"
                            type="text"
                            variant="outlined"
                            multiline
                            value={orderData.private_note}
                            rows={2}
                            onChange={(e) =>
                              setOrderData({
                                ...orderData,
                                private_note: e.target.value,
                              })
                            }
                          />
                        </FormControl>

                        <br />
                        <br />

                        <Button type="submit" variant="contained">
                          Save Order
                        </Button>
                      </form>
                    </>
                  )}
                </>
              )}

              <Dialog
                fullWidth
                open={creatingNewCustomerOpen}

              >
                <div
                  style={{
                    textAlign: 'right',
                    width: '100%',
                  }}
                >
                  <IconButton onClick={() => setCreatingNewCustomerOpen(false)}>
                    <ClearIcon color="primary" fontSize="large" />
                  </IconButton>
                </div>

                <DialogContent>
                  <CreateCustomerForm
                    baseEndpoint={`${API_ENDPOINT}/api/org`}
                    successCallback={assignCustomer}
                  />
                </DialogContent>
              </Dialog>

              <Dialog
                fullWidth
                open={creatingNewMerchantOpen}

              >
                <div
                  style={{
                    textAlign: 'right',
                    width: '100%',
                  }}
                >
                  <IconButton onClick={() => setCreatingNewMerchantOpen(false)}>
                    <ClearIcon color="primary" fontSize="large" />
                  </IconButton>
                </div>

                <DialogContent>
                  <CreateMerchantForm successCallback={assignMerchant} />
                </DialogContent>
              </Dialog>

              <Dialog fullWidth open={creatingNewBranchOpen} >
                <div
                  style={{
                    textAlign: 'right',
                    width: '100%',
                  }}
                >
                  <IconButton onClick={() => setCreatingNewBranchOpen(false)}>
                    <ClearIcon color="primary" fontSize="large" />
                  </IconButton>
                </div>

                <DialogContent>
                  <CreateBranchForm successCallback={assignBranch} />
                </DialogContent>
              </Dialog>

              <Dialog fullWidth open={creatingNewDriverOpen}>
                <div
                  style={{
                    textAlign: 'right',
                    width: '100%',
                  }}
                >
                  <IconButton onClick={() => setCreatingNewDriverOpen(false)}>
                    <ClearIcon color="primary" fontSize="large" />
                  </IconButton>
                </div>

                <DialogContent>
                  <CreateGuestOrAddDriver />
                </DialogContent>
              </Dialog>
            </>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default EditOrder;
