import { EmployeeType } from './create-employee-form.interfaces';

export const emptyEmployee: EmployeeType = {
  id: '',
  name: '',
  phone_number: '',
  branch: null,
  password: '',
  account: {
    user_permissions: [],
  },
};
