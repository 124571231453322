import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import CreateOrEditGuestDriver from './create-or-edit-guest-driver';
import AddExistingDriver from './add-existing-driver';
import HelpIconPopover from '../../../common/info-icon-popover/info-icon-popover';

const NEW = 'new';
const EXISTING = 'existing';

function CreateGuestOrAddDriver() {
  const [operationType, setOperationType] = React.useState<string>(NEW);

  return (
    <Container>
      <Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            defaultValue={NEW}
            value={operationType}
            onChange={(e) => setOperationType(e.target.value)}
            name="radio-buttons-group"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                value={NEW}
                control={<Radio />}
                label="Create Guest Driver"
              />
              <HelpIconPopover
                message={"Guest drivers don't have access to the system"}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                value={EXISTING}
                control={<Radio />}
                label="Add Registered Account"
              />
              <HelpIconPopover
                message={
                  'Registered drivers can login with their phone numbers'
                }
              />
            </div>
          </RadioGroup>
        </FormControl>
      </Box>
      <hr />

      {operationType === NEW ? (
        <CreateOrEditGuestDriver />
      ) : (
        <AddExistingDriver />
      )}
    </Container>
  );
}

export default CreateGuestOrAddDriver;
