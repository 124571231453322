import { CustomerType } from '../interfaces/common/customer';

export const emptyCustomer: CustomerType = {
  id: '',
  name: '',
  phone_number: '',
  address: '',
  governorate: '',
  district: '',
};
