
import { ensureZeroAfterCountryCode } from '../../../utils/phone_numbers';
import { Filter } from './filter-bar.interface';

export const displayFilter = (filter: Filter) => {
  return `${filter.key}: ${filter.value}`;
};


export const buildQueryParams = (filters: Filter[]): string => {
  let queryParams = "";

  filters.forEach((filter) => {
    queryParams += `&${filter.key}=${encodeURIComponent(ensureZeroAfterCountryCode(filter.value))}`;
  });

  return queryParams
}