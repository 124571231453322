import { Chip } from '@mui/material';
import { DeliveryState, PaymentState } from '../../../enums/orders';

export const getDeliveryStateColor = (state: DeliveryState): string => {
  const opacity = 0.5;
  switch (state) {
    case DeliveryState.PENDING_APPROVAL:
      return `rgba(135, 206, 250, ${opacity})`;
    case DeliveryState.APPROVED:
      return `rgba(0, 230, 0, ${opacity})`;
    case DeliveryState.IN_TRANSIT:
      return `rgba(255, 165, 0, ${opacity})`;
    case DeliveryState.DELIVERED:
      return `rgba(0, 128, 0, ${opacity})`;
    case DeliveryState.CANCELLED:
      return `rgba(255, 0, 0, ${opacity})`;
  }
};

const getPaymentStateColor = (state: PaymentState): string => {
  const opacity = 0.5;
  switch (state) {
    case PaymentState.UNINVOICED:
      return `rgba(119, 136, 153, ${opacity})`;
    case PaymentState.INVOICED_FOR_DRIVER:
      return `rgba(255, 165, 0, ${opacity})`;
    case PaymentState.INVOICED_FOR_MERCHANT:
      return `rgba(0, 128, 0, ${opacity})`;
  }
};

export const showDeliveryState = (state: DeliveryState): JSX.Element => {
  return (
    <Chip
      label={<b>{state.toString()}</b>}
      variant="filled"
      style={{
        margin: 1,
        backgroundColor: getDeliveryStateColor(state),
      }}
    />
  );
};

export const showPaymentState = (state: PaymentState): JSX.Element => {
  return (
    <Chip
      label={<b>{state.toString()}</b>}
      variant="filled"
      style={{
        margin: 1,
        backgroundColor: getPaymentStateColor(state),
      }}
    />
  );
};
