import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CreateEntityDrawer from '../../common/create-entity-drawer/create-entity-drawer';
import CreateOrderForm from './create-order-form/create-order-form';
import OrdersListing from './orders-listing/orders-listing';
import { useContext } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { UserType } from '../../../enums/users';

function Orders() {
  const { user } = useContext(AuthContext)
  const canCreateOrder = user?.type === UserType.ORGANISATION || user?.permissions?.includes("add_order")
  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Orders
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={4}></Grid>
          <Grid xs={4}></Grid>
          <Grid xs={4} sx={{ textAlign: 'right' }}>
            <CreateEntityDrawer disabled={!canCreateOrder}>
              <CreateOrderForm />
            </CreateEntityDrawer>
          </Grid>
          <Grid xs={12}>
            <OrdersListing />
          </Grid>
        </Grid>
      </Box>
    </div >
  );
}

export default Orders;
