import { OrderType } from '../../../interfaces/delivery-company/orders';

export const compute_total_price_usd = (orders: OrderType[]): number => {
  return orders
    .map((item) => Number.parseFloat(item.price_usd.toString()))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};

export const compute_total_price_lbp = (orders: OrderType[]): number => {
  return orders
    .map((item) => Number.parseFloat(item.price_lbp.toString()))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};

export const compute_total_delivery_fee_usd = (orders: OrderType[]): number => {
  return orders
    .map((item) => Number.parseFloat(item.delivery_fee_usd.toString()))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};

export const compute_total_delivery_fee_lbp = (orders: OrderType[]): number => {
  return orders
    .map((item) => Number.parseFloat(item.delivery_fee_lbp.toString()))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};

export const compute_total_commission_usd = (orders: OrderType[]): number => {
  return orders
    .map((item) => Number.parseFloat(item.driver_commission_usd.toString()))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};

export const compute_total_commission_lbp = (orders: OrderType[]): number => {
  return orders
    .map((item) => Number.parseFloat(item.driver_commission_lbp.toString()))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};
