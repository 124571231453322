import {
  Alert,
  Button,
  FormControl,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import axios, { AxiosResponse } from 'axios';
import MuiPhoneNumber from 'mui-phone-number';
import { useContext, useState } from 'react';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import {
  DriverType
} from '../../../../interfaces/delivery-company/drivers';
import DriverIntroCard from '../../driver-order-assigner/driver-intro-card';
import { UserType } from '../../../../enums/users';
import { ensureZeroAfterCountryCode } from '../../../../utils/phone_numbers';

function AddExistingDriver() {
  const [driverData, setDriverData] = useState<DriverType>();
  const [phoneInput, setPhoneInput] = useState<string>('');
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const { user } = useContext(AuthContext)
  const canCreateDriver = user?.type === UserType.ORGANISATION || user?.permissions?.includes("add_basedriver")


  const getAndSetDriver = async () => {
    setPendingRequest(true);
    setResponse(undefined);
    setMessage('');

    const endpoint = `${API_ENDPOINT}/api/drivers/?phone=${phoneInput.replaceAll(' ', '').replaceAll('+', '%2b')}`;

    console.log(endpoint);
    const config = {
      headers: { Authorization: `Bearer ${user?.authToken}` },
    };

    try {
      const response = await axios.get<{ count: number; results: DriverType[] }>(
        endpoint,
        config
      );

      console.log(response);
      if (response.statusText === 'OK') {
        if (response.data.count == 1) {
          setDriverData(response.data.results[0]);
        } else {
          setMessage('No driver account is associated to this phone number');
          setDriverData(undefined);
        }
      }
    } catch (error) {
      setMessage('Failed to fetch driver data');
      console.log(error);
    }

    setPendingRequest(false);
  };

  const handleSaveDriver = async () => {
    if (driverData) {
      setPendingRequest(true);
      setResponse(undefined);
      setLoadingMessage('Saving Driver...');

      const requestData = {
        id: driverData.id,
        name: driverData.name,
        phone_number: ensureZeroAfterCountryCode(driverData.phone_number).replaceAll(' ', ''),
        default_commission_lbp: driverData.default_commission_lbp,
        default_commission_usd: driverData.default_commission_usd,
      };

      console.log(requestData);

      try {
        const endpoint = `${API_ENDPOINT}/api/org/drivers/?isguest=false`;
        console.log(endpoint);

        const config = {
          headers: { Authorization: `Bearer ${user?.authToken}` },
        };

        // edit existing
        const response = await axios.post(endpoint, requestData, config);
        setResponse(response);
        setMessage('Driver saved successfully');

      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response)
          setMessage('Failed to save driver: ' + e.response?.statusText);
        } else {
          setMessage('Failed to save driver');
        }
        console.log(e);
      }
      setPendingRequest(false);
      setLoadingMessage('');
    }
  };
  return (
    <>
      <Typography
        variant="h3"
        component="h3"
        sx={{ textAlign: 'center', mt: 3, mb: 3 }}
      >
        Add Registered Driver
      </Typography>

      <Alert severity={'info'} variant="outlined">
        <ol>
          <li>Ask your driver to sign up to parcel tracer.</li>
          <li>Add their phone number below and click &quotFIND&quot.</li>
        </ol>
      </Alert>

      <>
        {message != '' && (
          <>
            <br />
            <Alert
              severity={
                response && response.status >= 200 && response.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              <>
                {message}

                {response &&
                  response.status >= 300 &&
                  Object.keys(response.data).map((key) => (
                    <>
                      <ul>
                        {
                          typeof response.data[key] === "string" ?
                            <li>{response.data[key]}</li> :
                            response.data[key].map((validationError: string) => (
                              <li key={key}>
                                {key}: {validationError}
                              </li>
                            ))}
                      </ul>
                    </>
                  ))}
              </>
            </Alert>
            <br /> </>
        )}
        <br></br>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            getAndSetDriver();
          }}
        >
          <div style={{ display: 'flex', gap: 3 }}>
            <FormControl fullWidth>
              <MuiPhoneNumber
                label="Driver Phone Number"
                type="tel"
                defaultCountry={'lb'}
                variant="outlined"
                required
                value={phoneInput}
                onChange={(newPhone) => {
                  if (pendingRequest) return;

                  if (typeof newPhone === 'string') {
                    setPhoneInput(newPhone);
                  } else {
                    setPhoneInput(newPhone.target.value);
                  }
                }}
              />
              {pendingRequest && <LinearProgress />}
            </FormControl>

            <Button type="submit" variant="outlined">
              {' '}
              Find{' '}
            </Button>
          </div>
        </form>

        <br />
        {driverData !== undefined && driverData.id !== '' && (
          <>
            <DriverIntroCard driver={driverData} />

            <br />

            {/* Driver Commission */}
            <FormControl fullWidth>
              <Grid container spacing={1}>
                <Grid xs={6}>
                  <TextField
                    label="Driver Commission $"
                    type="number"
                    fullWidth
                    required
                    value={driverData.default_commission_usd}
                    inputProps={{
                      maxLength: 13,
                      step: '0.01',
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        (e) => e.preventDefault(),
                        { passive: false }
                      )
                    }
                    onChange={(e) =>
                      setDriverData({
                        ...driverData,
                        default_commission_usd: parseFloat(e.target.value),
                      })
                    }
                  />
                </Grid>

                <Grid xs={6}>
                  <TextField
                    label="Driver Commission L.L"
                    type="number"
                    fullWidth
                    required
                    value={driverData.default_commission_lbp}
                    onChange={(e) =>
                      setDriverData({
                        ...driverData,
                        default_commission_lbp: parseFloat(e.target.value),
                      })
                    }
                  />
                </Grid>
              </Grid>
            </FormControl>

            <br />
            <br />

            <Button
              onClick={handleSaveDriver}
              variant="contained"
              disabled={pendingRequest || !canCreateDriver}
            >
              Save Driver
            </Button>
          </>
        )}
      </>
    </>
  );
}

export default AddExistingDriver;
