import { Box, LinearProgress, Alert, Tab, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import CreateOrEditGuestDriver from '../drivers/driver-forms/create-or-edit-guest-driver';
import React, { useContext, useState } from 'react';
import { DriverType } from '../../../interfaces/delivery-company/drivers';
import EditExistingDriver from '../drivers/driver-forms/edit-existing-driver';
import axios, { AxiosResponse } from 'axios';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { tabLabel } from '../../common/tab-label/tab-label';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DriverOrders from './driver-orders';

interface DriverProps {
  isGuest: boolean;
}

function Driver({ isGuest }: DriverProps) {
  const { driverId } = useParams();

  const [selectedTab, setSelectedTab] = React.useState<string>('orders');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [driver, setDriver] = useState<DriverType>();
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');
  const { user } = useContext(AuthContext);

  React.useEffect(() => {
    const getAndSetDriver = async () => {
      if (driverId !== undefined) {
        setIsLoading(true);
        try {
          const endpoint = `${API_ENDPOINT}/api/org/driver/${driverId}/?isguest=${isGuest}`;
          const config = {
            headers: { Authorization: `Bearer ${user?.authToken}` },
          };

          console.log(endpoint);
          const response = await axios.get<DriverType>(endpoint, config);
          console.log(response);
          setResponse(response);
          if (response.statusText == 'OK') {
            setDriver(response.data);
          }
        } catch (e) {
          setDriver(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load driver data: ' + e.message);
          } else {
            setMessage('Failed to load driver data');
          }
          console.log(e);
        }
        setIsLoading(false);
      }
    };
    getAndSetDriver();
  }, []);

  return (
    <div>
      <Typography
        variant="h3"
        component="h3"
        sx={{ textAlign: 'center', mt: 3, mb: 3, marginBottom: 0 }}
      >
        Driver
      </Typography>

      {driver && (
        <Typography
          variant="h5"
          component="h5"
          sx={{ textAlign: 'center', mt: 3, mb: 3, marginTop: 0 }}
        >
          {driver.name}
        </Typography>
      )}

      <Box>
        {isLoading && <LinearProgress />}

        {message !== '' && (
          <Alert
            severity={
              response && response.status >= 200 && response.status < 300
                ? 'success'
                : 'error'
            }
          >
            {message}
          </Alert>
        )}

        {!isLoading && driver && (
          <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={(e, v) => setSelectedTab(v)}
                    variant="fullWidth"
                  >
                    <Tab
                      label={tabLabel(
                        <InventoryIcon color="primary" />,
                        'Orders'
                      )}
                      value="orders"
                    />
                    <Tab
                      label={tabLabel(
                        <AccountBoxIcon color="primary" />,
                        'Account'
                      )}
                      value="account"
                    />
                  </TabList>
                </Box>
                <TabPanel value="orders">
                  <DriverOrders driver={driver} />
                </TabPanel>
                <TabPanel value="account">
                  {driver.isGuest ? (
                    <CreateOrEditGuestDriver
                      defaultDriver={driver}
                      headless={true}
                    />
                  ) : (
                    <EditExistingDriver
                      defaultDriver={driver}
                      headless={true}
                    />
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
}

export default Driver;
