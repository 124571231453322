import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { primaryPaletteColor } from '../../../configurations/global.config';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { IFieldConfig, IFooterRow } from './report';
import { ReportTableFooter } from './table-footer';
import { ReportTableHeader } from './table-header';
import { ReportTableRows } from './table-rows';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: primaryPaletteColor,
    width: '100%',
  },
});

interface TableSeparatorProps {
  title: string;
  color: string;
}

const TableSeparator = ({ title, color }: TableSeparatorProps) => {
  return (
    <View
      style={{
        letterSpacing: 1,
        fontSize: 13,
        textAlign: 'center',
        textTransform: 'uppercase',
        backgroundColor: color,
        width: '100%',
      }}
    >
      <Text
        style={{
          textAlign: 'center',
        }}
      >
        {title}
      </Text>
    </View>
  );
};

interface ReportTableProps {
  orders: OrderType[];
  fields_config: IFieldConfig[];
  title: string;
  on_new_page: boolean;
  header_bg: string;
  page_index: number;
  table_footer_rows: IFooterRow[];
}

export const ReportTable = ({
  orders,
  fields_config,
  title,
  on_new_page,
  header_bg,
  page_index,
  table_footer_rows,
}: ReportTableProps) => {
  const max_rows_on_first_page = page_index === 0 ? 12 : 17;
  return (
    <View break={on_new_page} style={styles.tableContainer}>
      <TableSeparator title={title} color={header_bg} />
      <ReportTableHeader fields_config={fields_config} />
      <ReportTableRows
        max_rows_on_first_page={max_rows_on_first_page}
        orders={orders}
        fields_config={fields_config}
      />
      <ReportTableFooter
        orders={orders}
        table_footer_rows={table_footer_rows}
      />
    </View>
  );
};
