import { Box, Typography } from '@mui/material';
import MerchantOrdersListing from './merchant-orders-listing/merchant-orders-listing';
import Grid from '@mui/material/Unstable_Grid2';
import CreateEntityDrawer from '../../common/create-entity-drawer/create-entity-drawer';
import MerchantCreateOrderForm from './merchant-create-order-form/merchant-create-order-form';
import MerchantBulkCreateOrders from '../merchant-bulk-create-orders/merchant-bulk-create-orders';
import { useContext } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';

function MerchantOrders() {
  const { user } = useContext(AuthContext)

  return (
    <div>
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          My Orders
        </Typography>

        <br />

        {
          <Grid container spacing={2}>
            <Grid
              xs={12}
              sx={{
                textAlign: 'right',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 1,
              }}
            >
              <CreateEntityDrawer>
                <MerchantCreateOrderForm />
              </CreateEntityDrawer>

              <MerchantBulkCreateOrders orgId={user?.organisation?.org_id} />
            </Grid>
            <Grid xs={12}>
              <MerchantOrdersListing />
            </Grid>
          </Grid>
        }
      </Box>
    </div>
  );
}

export default MerchantOrders;
