export const passwordValidationError = (
  password: string,
  passwordConfirmation: string
): string | undefined => {
  // Password and confirm password should match
  if (password !== passwordConfirmation) return "Passwords don't match";

  // Password should be non empty
  if (password.length === 0) return "Password can't be empty";

  // Password should not be shorter than 8 characters
  if (password.length < 8)
    return 'Password should contain at least 8 characters';

  return undefined;
};
