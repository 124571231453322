import HelpIcon from '@mui/icons-material/Help';
import { Popover, Typography } from '@mui/material';
import React from 'react';
import GenericPopover from '../generic-popover/generic-popover';

interface HelpIconPopoverProps {
  message: string;
}

function HelpIconPopover({ message }: HelpIconPopoverProps) {

  return (
    <GenericPopover display={<HelpIcon color="primary" fontSize="small" />} >
      <Typography sx={{ p: 2 }}>{message}</Typography>
    </GenericPopover>
  )
}

export default HelpIconPopover;
