import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import DriverAccounting from './driver-accounting/driver-accounting';
import MerchantAccounting from './merchant-accounting/merchant-accounting';

function Accounting() {
  const [entity, setEntity] = React.useState<string>('driver');

  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Accounting - {entity === 'driver' ? 'Driver' : 'Merchant'}
        </Typography>

        <FormControl fullWidth>
          <InputLabel id="entity-select-label">Entity</InputLabel>
          <Select
            labelId="entity-select-label"
            id="entity-select"
            value={entity}
            label="Entity"
            onChange={(e) => setEntity(e.target.value)}
          >
            <MenuItem value={'driver'}>Driver</MenuItem>
            <MenuItem value={'merchant'}>Merchant</MenuItem>
          </Select>
        </FormControl>
        <Box style={{ marginTop: 10 }}>
          {entity === 'driver' ? <DriverAccounting /> : <MerchantAccounting />}
        </Box>
      </Box>
    </div>
  );
}

export default Accounting;
