import ReactPDFChart from "react-pdf-charts";
import { BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, LabelList } from "recharts";
import { OrdersSummary } from "./profit-report";
import HistogramTitle from "./histogram-title";

interface DataSegment {
    name: string;
    price: number;
    "delivery fee": number;
    "driver commission": number;
}


type Currency = 'usd' | 'lbp'
const make_data = (ordersSummary: OrdersSummary, currency: Currency): DataSegment[] => {
    const data_segments: DataSegment[] = []

    data_segments.push({
        name: "Uninvoiced",
        price: currency === 'usd' ? ordersSummary.uninvoiced.price_usd : ordersSummary.uninvoiced.price_lbp,
        "driver commission": currency === 'usd' ? ordersSummary.uninvoiced.commission_usd : ordersSummary.uninvoiced.commission_lbp,
        "delivery fee": currency === 'usd' ? ordersSummary.uninvoiced.delivery_usd : ordersSummary.uninvoiced.delivery_lbp,
    })

    data_segments.push({
        name: "Invoiced For Driver",
        price: currency === 'usd' ? ordersSummary.invoiced_for_driver.price_usd : ordersSummary.invoiced_for_driver.price_lbp,
        "driver commission": currency === 'usd' ? ordersSummary.invoiced_for_driver.commission_usd : ordersSummary.invoiced_for_driver.commission_lbp,
        "delivery fee": currency === 'usd' ? ordersSummary.invoiced_for_driver.delivery_usd : ordersSummary.invoiced_for_driver.delivery_lbp,
    })


    data_segments.push({
        name: "Invoiced For Merchant",
        price: currency === 'usd' ? ordersSummary.invoiced_for_merchant.price_usd : ordersSummary.invoiced_for_merchant.price_lbp,
        "driver commission": currency === 'usd' ? ordersSummary.invoiced_for_merchant.commission_usd : ordersSummary.invoiced_for_merchant.commission_lbp,
        "delivery fee": currency === 'usd' ? ordersSummary.invoiced_for_merchant.delivery_usd : ordersSummary.invoiced_for_merchant.delivery_lbp,
    })


    return data_segments
}

const getMaxElevation = (data_segments: DataSegment[]): number => {
    const max_values = data_segments.map(segment => Math.max(segment.price, Math.max(segment['delivery fee'], segment['driver commission'])))
    return max_values.reduce((prev, current) => Math.max(prev, current))
}


const barValueFormatter = (currency: Currency): any => {
    return (value: string) => `${value}`
}

export const PricesHistogram = (ordersSummary: OrdersSummary, withLegend: boolean, currency: Currency): JSX.Element => {
    const data = make_data(ordersSummary, currency)
    return <>
        <HistogramTitle title={"Order Prices in " + (currency === 'usd' ? "$" : "L.L")} />
        < ReactPDFChart >
            <BarChart width={550} height={220} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={"name"} />
                <YAxis domain={[0, Math.trunc(getMaxElevation(data) * 1.05)]} />
                {withLegend && <Legend formatter={(value, entry, index) => <span style={{ color: "black" }}>{value}</span>} layout='vertical' />}

                <Bar dataKey="price" fill='#6dd3ce' isAnimationActive={false}>
                    <LabelList dataKey="price" position="top" formatter={barValueFormatter(currency)} fill='black' />
                </Bar>

                <Bar dataKey="delivery fee" fill='#c8e9a0' isAnimationActive={false}>
                    <LabelList dataKey="delivery fee" position="top" formatter={barValueFormatter(currency)} fill='black' />
                </Bar>
                <Bar dataKey="driver commission" fill='#f7a278' isAnimationActive={false}>
                    <LabelList dataKey="driver commission" position="top" formatter={barValueFormatter(currency)} fill='black' />
                </Bar>

            </BarChart>

        </ReactPDFChart >
    </>


}

