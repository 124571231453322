import React from 'react';
import { useParams } from 'react-router-dom';
import CreateCustomerForm from '../../common/create-customer-form/create-customer-form';
import { Container, Box } from '@mui/material';
import { API_ENDPOINT } from '../../../configurations/global.config';

function Customer() {
  const { customerId } = useParams();
  return (
    <div>
      <Container>
        <Box>
          <CreateCustomerForm
            defaultCustomerId={customerId}
            baseEndpoint={`${API_ENDPOINT}/api/org`}
          />
        </Box>
      </Container>
    </div>
  );
}

export default Customer;
