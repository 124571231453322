import React from 'react';
import { PageFooter } from '../../../common/pdf-builders-utils/page-footer';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Histogram } from 'perf_hooks';
import { titleStyles } from '../../../common/pdf-builders-utils/styles';


const styles = StyleSheet.create({
    bargraphTitle: {
        fontWeight: "bold",
        marginBottom: 5,
        marginLeft: "auto",
        marginRight: "auto",
    },
});

interface HistogramTitleProps {
    title: string;
}

function HistogramTitle({ title }: HistogramTitleProps) {
    return (
        <View style={{ ...titleStyles.titleContainer, marginTop: 5 }}>
            <Text style={styles.bargraphTitle}>{title}</Text>
        </View>
    );
}

export default HistogramTitle;