import { Document, Image, Page, StyleSheet, Text } from '@react-pdf/renderer';
import { PageFooter } from '../../../common/pdf-builders-utils/page-footer';
import { pageStyles } from '../../../common/pdf-builders-utils/styles';
import { PageTitle } from '../../../common/pdf-builders-utils/page-title';
import { Aggregation, OrdersSummary, ReportSummary } from './profit-report';
import ReactPDFChart from "react-pdf-charts";
import { Bar, BarChart, CartesianGrid, ComposedChart, LabelList, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import GlobalSummary from './global-summary';
import SummaryStatsAndCharts from './summary-stats-and-charts';



interface PdfGeneratorProp {
    organisation_id: string;
    date_from: string;
    date_to: string;
    title: string;
    reportSummary: ReportSummary;
}
function PdfGenerator({ title, organisation_id, date_from, date_to, reportSummary }: PdfGeneratorProp) {

    console.log(reportSummary)
    return (
        <Document>
            <Page size="A4" style={pageStyles.page}>
                <Image style={pageStyles.logo} src={'/main-logo.png'} />
                <PageTitle organisation_id={organisation_id} date_from={date_from} date_to={date_to} title={title} />
                <GlobalSummary reportSummary={reportSummary} />
                <SummaryStatsAndCharts title={"Delivered Orders"} ordersSummary={reportSummary.delivered_orders} />
                <SummaryStatsAndCharts title={"Cancelled Orders"} ordersSummary={reportSummary.cancelled_orders} />
            </Page>
        </Document>
    );
}

export default PdfGenerator;