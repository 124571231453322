export const tabLabel = (icon: JSX.Element, header: string) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div>{header}</div>
      {icon}
    </div>
  );
};
