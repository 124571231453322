import { Box, Card, TablePagination } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import SearchBar from '../../../common/search-bar/search-bar';
import { EmployeeType } from '../create-employee-form/create-employee-form.interfaces';
import EmployeesListingTable from './employees-listing.table';
import { ensureZeroAfterCountryCode } from '../../../../utils/phone_numbers';

function EmployeesListing() {
  const navigate = useNavigate();

  const [employeesList, setEmployeesList] = useState<EmployeeType[]>([]);
  const [employeesCount, setEmployeesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const searchOptions = ['Name', 'Phone'];

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    searchColumn: 'Name',
    searchValue: '',
    searchOperation: 'equals',
  });

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  useEffect(() => {
    const getData = async () => {
      let endpoint = `${API_ENDPOINT}/api/org/employees?page=${controller.page + 1}&page_size=${controller.rowsPerPage}`;

      if (controller.searchColumn !== '' && ensureZeroAfterCountryCode(controller.searchValue) !== '') {
        endpoint += `&${controller.searchColumn.toLowerCase()}__${controller.searchOperation}=${encodeURIComponent(ensureZeroAfterCountryCode(controller.searchValue))}`;
      }
      try {
        const response = await axios.get<{
          count: number;
          results: EmployeeType[];
        }>(endpoint, config);

        console.log(response.data);
        if (response.statusText === 'OK') {
          const data = response.data;
          setEmployeesList(data.results);
          setEmployeesCount(data.count);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
  }, [controller]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const searchQueryAction = (
    column: string,
    value: string,
    operation: string
  ) => {
    setController({
      ...controller,
      searchColumn: column,
      searchValue: value,
      searchOperation: operation,
    });
  };

  return (
    <Box>
      <Card sx={{ padding: 2 }}>
        <SearchBar
          searchOptions={searchOptions}
          searchQueryAction={searchQueryAction}
        />
        <EmployeesListingTable
          employees={employeesList}
          isLoading={isLoading}
        />
        <TablePagination
          component="div"
          onPageChange={handlePageChange}
          page={controller.page}
          count={employeesCount}
          rowsPerPage={controller.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          labelRowsPerPage=""
          style={{ overflow: 'visible' }}
        />
      </Card>
    </Box>
  );
}

export default EmployeesListing;
