import { Card, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/user-context/user-context';
import LoginForm from './login-form';
import ResetPasswordForm from './reset-password-form';
import SignUpForm from './sign-up-form';

type AuthAction = 'signin' | 'signup' | 'reset';

interface AuthenticationProps {
  mode: AuthAction;
}

function Authentication({ mode }: AuthenticationProps) {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const { org } = useParams();

  console.log('org = ', org);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  return (
    <div
      style={{
        height: '80%',
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card sx={{ padding: 5, width: '600px', maxWidth: '95%' }}>
        {mode === 'signin' && (
          <>
            <Typography
              variant="h3"
              component="h3"
              sx={{ textAlign: 'center', mt: 1, mb: 1 }}
            >
              Login
            </Typography>

            <LoginForm />
          </>
        )}

        {mode === 'signup' && (
          <>
            <Typography
              variant="h3"
              component="h3"
              sx={{ textAlign: 'center', mt: 1, mb: 1 }}
            >
              Create Account
            </Typography>
            <SignUpForm />
          </>
        )}

        {mode === 'reset' && (
          <>
            <Typography
              variant="h3"
              component="h3"
              sx={{ textAlign: 'center', mt: 1, mb: 1 }}
            >
              Reset Password
            </Typography>

            <ResetPasswordForm />
          </>
        )}
      </Card>
    </div>
  );
}

export default Authentication;
