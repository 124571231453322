import { View } from '@react-pdf/renderer';
import {
  primaryPaletteColor
} from '../../../configurations/global.config';

export const PageFooter = () => {
  return (
    <View
      fixed
      style={{
        width: '100%',
        height: 10,
        backgroundColor: primaryPaletteColor,
      }}
    ></View>
  );
};
