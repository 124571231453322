import {
  Alert,
  Box,
  FormControl
} from '@mui/material';
import React, { useContext, useState } from 'react';

import AllInboxIcon from '@mui/icons-material/AllInbox';
import ArticleIcon from '@mui/icons-material/Article';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { DeliveryState } from '../../../../enums/orders';
import {
  CompactDriverType,
  DriverType,
} from '../../../../interfaces/delivery-company/drivers';
import AutocompleteWithThrottle from '../../../common/autocomplete-with-throttle/autocomplete-with-throttle';
import { tabLabel } from '../../../common/tab-label/tab-label';
import DriverOrdersListing from './delivered-orders-listing/driver-orders-listing';
import DriverInvoicesListing from './driver-invoices-listing.tsx/driver-invoices-listing';

function DriverAccounting() {
  const [driver, setDriver] = useState<DriverType | null>(null);
  const [selectedTab, setSelectedTab] = React.useState<string>('invoices');
  const [message, setMessage] = useState<string>("");
  const [response, setReponse] = useState<AxiosResponse>()

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleFetchDrivers = async (query: string): Promise<DriverType[]> => {
    const endpoint = `${API_ENDPOINT}/api/org/drivers/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;

    try {
      const response = await axios.get<{
        count: number;
        results: DriverType[];
      }>(endpoint, config);
      if (response.statusText === 'OK') {
        return response.data.results;
      }
      return [];
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setReponse(undefined)
    setMessage("")
    setSelectedTab(newValue);
  };

  const switchToInvoices = () => {
    setSelectedTab('invoices');
  };

  return (
    <Box>
      <FormControl fullWidth>
        <AutocompleteWithThrottle
          label={'Driver'}
          required={false}
          fetch={handleFetchDrivers}
          value={driver}
          getOptionLabel={(option: CompactDriverType) =>
            option.name + ' - ' + option.phone_number
          }
          onChange={(e, value) => {
            setDriver(value);
          }}
        />
      </FormControl>

      <br />
      <br />

      {driver !== null && (
        <>
          <Box sx={{ width: '100%', typography: 'body1' }}>

            {message !== '' && (

              <>
                <Alert
                  severity={
                    response && response.status >= 200 && response.status < 300
                      ? 'success'
                      : 'error'
                  }
                >
                  <>
                    {message}

                    {response &&
                      response.status >= 300 &&
                      Object.keys(response.data).map((key) => (
                        <>
                          <ul>
                            {
                              typeof response.data[key] === "string" ?
                                <li>{response.data[key]}</li> :
                                response.data[key].map((validationError: string) => (
                                  <li key={key}>
                                    {key}: {validationError}
                                  </li>
                                ))}
                          </ul>
                        </>
                      ))}
                  </>
                </Alert>

                <br />

              </>

            )}
            <TabContext value={selectedTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="fullWidth"
                >
                  <Tab
                    label={tabLabel(
                      <EventBusyIcon color="primary" />,
                      'Cancelled Orders'
                    )}
                    value="cancelled_orders"
                  />
                  <Tab
                    label={tabLabel(
                      <AllInboxIcon color="primary" />,
                      'Delivered Orders'
                    )}
                    value="delivered_orders"
                  />
                  <Tab
                    label={tabLabel(
                      <ArticleIcon color="primary" />,
                      'Invoices'
                    )}
                    value="invoices"
                  />
                </TabList>
              </Box>
              <TabPanel value="cancelled_orders">
                <DriverOrdersListing
                  driverId={driver.id}
                  deliveryState={DeliveryState.CANCELLED}
                  switchToInvoices={switchToInvoices}
                  setResponse={setReponse}
                  setMessage={setMessage}
                />
              </TabPanel>
              <TabPanel value="delivered_orders">
                <DriverOrdersListing
                  driverId={driver.id}
                  deliveryState={DeliveryState.DELIVERED}
                  switchToInvoices={switchToInvoices}
                  setResponse={setReponse}
                  setMessage={setMessage}
                />
              </TabPanel>
              <TabPanel value="invoices">
                <DriverInvoicesListing
                  driverId={driver.id}
                  setResponse={setReponse}
                  setMessage={setMessage}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </>
      )}
    </Box>
  );
}

export default DriverAccounting;
