import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/user-context/user-context';
import { UserType } from '../enums/users';
import axios from 'axios';
import { API_ENDPOINT } from '../configurations/global.config';

export function useAttachedOrgs() {
  const [attachedOrgs, setAttachedOrgs] = useState<string[]>();
  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  useEffect(() => {
    const fetchAndSetOrgs = async () => {
      if (user) {
        if (user.type === UserType.DRIVER) {
          // Fetch driver orgs

          // Fetch merchant orgs
          const endpoint = `${API_ENDPOINT}/api/driver/orgs/`;
          try {
            const response = await axios.get<string[]>(endpoint, config);
            if (response.statusText === 'OK') {
              setAttachedOrgs(response.data);
            }
          } catch (e) {
            setAttachedOrgs([]);
            console.log(e);
          }
        }

        if (user.type === UserType.MERCHANT) {
          // Fetch merchant orgs
          const endpoint = `${API_ENDPOINT}/api/merchant/orgs/`;
          try {
            const response = await axios.get<string[]>(endpoint, config);
            if (response.statusText === 'OK') {
              setAttachedOrgs(response.data);
            }
          } catch (e) {
            setAttachedOrgs([]);
            console.log(e);
          }
        }
      }
    };
    fetchAndSetOrgs();
  }, [user]);

  return attachedOrgs;
}
