import ReactPDFChart from "react-pdf-charts";
import { BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, LabelList } from "recharts";
import { OrdersSummary } from "./profit-report";
import HistogramTitle from "./histogram-title";

interface DataSegment {
    name: string;
    count: number;
}

const make_data = (ordersSummary: OrdersSummary): DataSegment[] => {
    const data_segments: DataSegment[] = []

    data_segments.push({
        name: "Uninvoiced",
        count: ordersSummary.uninvoiced.count,
    })

    data_segments.push({
        name: "Invoiced For Driver",
        count: ordersSummary.invoiced_for_driver.count,
    })


    data_segments.push({
        name: "Invoiced For Merchant",
        count: ordersSummary.invoiced_for_merchant.count,
    })


    return data_segments
}

const getMaxElevation = (data_segments: DataSegment[]): number => {
    const max_values = data_segments.map(segment => segment.count)
    return max_values.reduce((prev, current) => Math.max(prev, current))
}


export const CountsHistogram = (ordersSummary: OrdersSummary): JSX.Element => {
    const data = make_data(ordersSummary)
    return <>
        <HistogramTitle title={"Number of Orders"} />
        < ReactPDFChart >
            <BarChart width={550} height={220} data={data} title="Number of Orders">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={"name"} />
                <YAxis domain={[0, Math.trunc(getMaxElevation(data) * 1.5) + 4]} />

                <Bar dataKey="count" fill='#ffba49' isAnimationActive={false} barSize={75}>
                    <LabelList
                        dataKey="count"
                        position="top"
                        fill='black'
                    />
                </Bar>

            </BarChart>
        </ReactPDFChart >

    </>


}

