import { Container, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import CreateEmployeeForm from '../employees/create-employee-form/create-employee-form';

function Employee() {
  const { employeeId } = useParams();
  return (
    <div>
      <Container>
        <Box>
          <CreateEmployeeForm defaultEmployeeId={employeeId} />
        </Box>
      </Container>
    </div>
  );
}

export default Employee;
