import BarChartIcon from '@mui/icons-material/BarChart';
import { Card } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { PieChart, PieValueType, pieArcLabelClasses } from '@mui/x-charts';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import { ResponsiveContainer } from 'recharts';
import { PALETTE } from '../../../../configurations/global.config';
import {
  cardHeaderStyle,
  cardStyle,
} from '../../../common/styles/grid-card.style';
import { WidgetProps } from '../home';

const makePieData = (rawData?: { governorate: string; total: number }[]) => {
  if (rawData === undefined) {
    return {
      data: [],
      total: 0,
    };
  }

  const data: MakeOptional<PieValueType, 'id'>[] = [];

  let id = 0;

  let total = 0;

  rawData.forEach((item) => {
    data.push({
      id: id,
      value: item.total,
      label: item.governorate === "" ? "Not Set" : item.governorate,
      color: PALETTE[id % 5],
    });

    total += item.total;
    id++;
  });

  return {
    data: data,
    total: total,
  };
};

function GovernorateDistributionWidget({
  stats,
  isLoading,
  errorMessage,
}: WidgetProps) {
  const { data, total } = makePieData(stats?.orders_per_governorate);

  return (
    <Grid style={{ textAlign: 'center' }} xs={12} md={6}>
      <Card style={cardStyle}>
        <div style={cardHeaderStyle}>
          <BarChartIcon />
          <div>
            <b>Targetted Regions</b>
          </div>
        </div>

        <ResponsiveContainer height={350}>
          <PieChart
            series={[
              {
                arcLabel: (item) =>
                  `${((item.value * 100) / total).toFixed(0)} %`,
                arcLabelMinAngle: 0,
                data: data,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30 },
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                fontWeight: 'bold',
              },
            }}
            margin={{ bottom: 75 }}
            slotProps={{
              legend: {
                direction: 'row',
                position: {
                  vertical: 'bottom',
                  horizontal: 'middle',
                },
                padding: 0,
              },
            }}
          />
        </ResponsiveContainer>
      </Card>
    </Grid>
  );
}

export default GovernorateDistributionWidget;
