import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { primaryPaletteColor } from '../../../configurations/global.config';
import { IFieldConfig } from './report';

const borderColor = primaryPaletteColor;
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: primaryPaletteColor,
    backgroundColor: primaryPaletteColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
    flexGrow: 1,
  },
});

interface ReportTableHeaderProps {
  fields_config: IFieldConfig[];
}

export const ReportTableHeader = ({
  fields_config,
}: ReportTableHeaderProps) => (
  <View style={styles.container}>
    {fields_config.map((config_item) => (
      <Text
        key={config_item.display_name}
        style={{
          width: config_item.width_percentage,
          borderRightColor: borderColor,
          borderRightWidth: 1,
          color: 'white',
        }}
      >
        {config_item.display_name}
      </Text>
    ))}
  </View>
);
