export interface RegionType {
  governorate: string;
  district: string;
}

export const emptyRegion: RegionType = {
  governorate: '',
  district: '',
};

export const regions: RegionType[] = [
  { governorate: 'Beqaa', district: 'Zahleh' },
  { governorate: 'Nabatieh', district: 'Hasbaya' },
  { governorate: 'Beqaa', district: 'Baalbek' },
  { governorate: 'South', district: 'Jezzine' },
  { governorate: 'Mount Lebanon', district: 'Baabda' },
  { governorate: 'Mount Lebanon', district: 'Jbeil' },
  { governorate: 'North', district: 'Zgharta' },
  { governorate: 'Nabatieh', district: 'Bint Jbeil' },
  { governorate: 'Beqaa', district: 'Hermel' },
  { governorate: 'Nabatieh', district: 'Marjeyoun' },
  { governorate: 'North', district: 'Bsharri' },
  { governorate: 'Mount Lebanon', district: 'Aley' },
  { governorate: 'Beqaa', district: 'Rashaya' },
  { governorate: 'North', district: 'Miniyeh-Danniyeh' },
  { governorate: 'North', district: 'Tripoli' },
  { governorate: 'South', district: 'Tyre' },
  { governorate: 'Nabatieh', district: 'Nabatieh' },
  { governorate: 'Mount Lebanon', district: 'Chouf' },
  { governorate: 'Mount Lebanon', district: 'Maatn' },
  { governorate: 'Beqaa', district: 'Western Beqaa' },
  { governorate: 'North', district: 'Akkar' },
  { governorate: 'South', district: 'Sidon' },
  { governorate: 'North', district: 'Batroun' },
  { governorate: 'Mount Lebanon', district: 'Keserwan' },
  { governorate: 'North', district: 'Koura' },
];

export const getGovernorateForDistrict = (district: string): string => {
  const matched_regions = regions.filter((item) => item.district === district);
  if (matched_regions.length !== 1) {
    return '';
  }
  return matched_regions[0].governorate;
};
