import { DriverType } from '../../../../interfaces/delivery-company/drivers';

export const emptyDriver: DriverType = {
  id: '',
  name: '',
  phone_number: '',
  isGuest: false,
  default_commission_lbp: 0,
  default_commission_usd: 0,
};
