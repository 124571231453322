import { Html5QrcodeCameraScanConfig } from 'html5-qrcode';

export const qrboxFunction = function (
  viewfinderWidth: number,
  viewfinderHeight: number
) {
  const minEdgePercentage = 0.95; // 70%
  const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
  const qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);

  return {
    width: qrboxSize,
    height: qrboxSize,
  };
};

// Create style for for BarcodeScanner
export const getStyle = (enabled: boolean): React.CSSProperties => {
  return enabled
    ? {
      opacity: 1,
      visibility: 'visible',
      transition: 'opacity 0.3s ease',
      position: 'relative',
    }
    : {
      opacity: 0,
      visibility: 'visible',
      transition: 'opacity 0.3s ease',
      position: 'absolute',
    };
};

// Creates the configuration object for BarcodeScanner.
export const createConfig = (): Html5QrcodeCameraScanConfig => {
  return {
    qrbox: qrboxFunction,
    fps: 30,
    disableFlip: false,
    aspectRatio: 1,
  };
};
