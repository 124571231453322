import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

interface OrgSetterProps {
  currentOrg: string | undefined;
  setCurrentOrg: React.Dispatch<React.SetStateAction<string | undefined>>;
  attachedOrgs: string[] | undefined;
}

function OrgSetter({
  currentOrg,
  setCurrentOrg,
  attachedOrgs,
}: OrgSetterProps) {
  if (attachedOrgs === undefined) return null;
  if (attachedOrgs.length === 1)
    return (
      <>
        <b> Delivery Organisation: </b> {attachedOrgs[0]}
      </>
    );

  return (
    <FormControl key={'user-type-input'} fullWidth>
      <InputLabel id="user-type-select-label"> Organisation</InputLabel>
      <Select
        labelId="user-type-select-label"
        id="entity-select"
        value={currentOrg}
        label="Entity"
        required
        onChange={(e) => {
          setCurrentOrg(e.target.value);
        }}
      >
        {attachedOrgs.map((org) => (
          <MenuItem key={org} value={org}>
            {org}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default OrgSetter;
