import React from 'react';
import { OrdersSummary } from './profit-report';
import { PageFooter } from '../../../common/pdf-builders-utils/page-footer';
import { titleStyles } from '../../../common/pdf-builders-utils/styles';
import { View, Text } from '@react-pdf/renderer';
import { PricesHistogram } from './prices-histogram';
import { CountsHistogram } from './counts-histogram';

interface SummaryStatsAndChartsProps {
    title: string;
    ordersSummary: OrdersSummary;
}

function SummaryStatsAndCharts({ ordersSummary, title }: SummaryStatsAndChartsProps) {
    return (
        <View wrap={false}>
            <PageFooter />
            <View style={{ ...titleStyles.titleContainer, marginTop: 10 }}>
                <Text style={titleStyles.sectionTitle}>{title}</Text>
            </View>
            {CountsHistogram(ordersSummary)}
            {PricesHistogram(ordersSummary, false, 'usd')}
            {PricesHistogram(ordersSummary, true, 'lbp')}

        </View>
    );
}

export default SummaryStatsAndCharts;