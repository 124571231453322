import MenuIcon from '@mui/icons-material/Menu';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/user-context/user-context';
import { UserType } from '../../enums/users';
import MainContent from '../router/main';
import QuickScanDialog from './quick-scan-dialog';
import SideNav from './side-nav/side-nav';
import { drawerWidth } from './side-nav/side-nav.config';
import { DrawerHeader } from './side-nav/side-nav.utils';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: 9999999999,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppLayout = () => {
  const [open, setOpen] = React.useState(false);
  const [scanning, setScanning] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { user } = useContext(AuthContext);

  if (user === null) {
    open && setOpen(false);
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {user !== null && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box
              component="img"
              sx={{
                height: 64,
                padding: 1,
              }}
              alt="Your logo."
              src={'/main-logo-for-nav.png'}
            />

            {user &&
              [
                UserType.ORGANISATION.toString(),
                UserType.EMPLOYEE.toString(),
              ].includes(user.type) && (
                <Box
                  component="div"
                  sx={{
                    height: 64,
                    padding: 1,
                    alignContent: 'right',
                  }}
                >
                  <IconButton onClick={() => setScanning(true)}>
                    <QrCodeScannerIcon
                      sx={{ color: 'white' }}
                      fontSize="large"
                    />
                  </IconButton>
                </Box>
              )}
          </div>
        </Toolbar>
      </AppBar>

      {user !== null && (
        <SideNav
          setOpen={setOpen}
          open={open}
          handleDrawerClose={handleDrawerClose}
        />
      )}

      <Box
        id="this box"
        component="main"
        sx={{ width: `calc(100% - ${drawerWidth})`, flexGrow: 1, p: 3 }}
      >
        <DrawerHeader />
        <MainContent />
      </Box>
      <QuickScanDialog
        open={scanning}
        onClose={() => setScanning(false)}
      ></QuickScanDialog>
    </Box>
  );
};

export default AppLayout;
