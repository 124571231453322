import WarningIcon from '@mui/icons-material/Warning';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

interface ConfirmActionProps {
  message: string;
  action: () => void;
  isOpen: boolean;
  handleClose: any;
  isSevereAction?: boolean;
  confirmSevereActionTarget?: string;
  children: React.ReactNode;
}

function ConfirmAction({
  message,
  action,
  isOpen,
  handleClose,
  isSevereAction,
  confirmSevereActionTarget,
  children,
}: ConfirmActionProps) {
  const [confirmationInput, setConfirmationInput] = useState<string>('');

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() => {
        handleClose();
        setConfirmationInput('');
      }}
    >
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <WarningIcon fontSize="large" color="error" />
          <div>
            <b>{message}</b>
          </div>
        </div>
      </DialogTitle>

      <DialogContent>
        {children !== undefined && (
          <>
            {children}
            <br />{' '}
          </>
        )}

        {isSevereAction && (
          <>
            <FormControl fullWidth>
              <TextField
                placeholder={`Type in "${confirmSevereActionTarget}" to allow proceeding with this operation...`}
                value={confirmationInput}
                onChange={(e) => {
                  setConfirmationInput(e.target.value);
                }}
              />
            </FormControl>
            <br /> <br />
          </>
        )}
        <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
          <Button
            variant="outlined"
            disabled={
              isSevereAction && confirmationInput !== confirmSevereActionTarget
            }
            color="error"
            onClick={() => {
              action();
              handleClose();
              setConfirmationInput('');
            }}
          >
            Proceed
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleClose();
              setConfirmationInput('');
            }}
          >
            Cancel
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmAction;
