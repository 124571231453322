import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  LinearProgress,
  TextField,
} from '@mui/material';
import axios, { AxiosResponse, isAxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINT } from '../../configurations/global.config';
import { AuthContext } from '../../contexts/user-context/user-context';

interface VerifyPhoneProps {
  phoneNumber: string;
  password?: string;
  isOpen: boolean;
  closeVerificationDialog: any;
  onSuccess?: () => void;
}

export const VerifyPhoneDialog = ({
  phoneNumber,
  isOpen,
  closeVerificationDialog,
  password,
  onSuccess,
}: VerifyPhoneProps) => {
  const [verificationCodeInput, setVerificationCodeInput] =
    useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resendAllowed, setResendAllowed] = useState<boolean>(false);
  const [resendCountdown, setResendCountdown] = useState<JSX.Element>();
  const [verificationResponse, setVerificationResponse] =
    useState<AxiosResponse>();
  const [verificationMessage, setVerificationMessage] = useState('');
  const [resendCodeResponse, setResendCodeResponse] = useState<AxiosResponse>();
  const [resendCodeMessage, setResendCodeMessage] = useState('');
  const [verified, setVerified] = useState<boolean>(false);

  const { setUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const resetMessagesAndResponses = () => {
    setVerificationMessage('');
    setResendCodeMessage('');
    setResendCodeResponse(undefined);
    setVerificationResponse(undefined);
  };

  const handleResendRequest = async () => {
    resetMessagesAndResponses();
    const requestData = {
      account_id: phoneNumber.replaceAll(' ', ''),
    };

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/auth/resend-code/`,
        requestData
      );
      setResendCodeResponse(response);
      if (response.status >= 200 && response.status < 300) {
        setResendCodeMessage('Verification code resent again.');
        setResendAllowed(false);
        setResendCountdown(
          <Countdown
            date={Date.now() + 2 * 60 * 1000}
            onComplete={() => setResendAllowed(true)}
            renderer={({ hours, minutes, seconds, completed }) => (
              <span>
                {zeroPad(minutes)}:{zeroPad(seconds)}
              </span>
            )}
          />
        );
      } else {
        setResendCodeMessage(
          'Failed to resend verification code resent again. Please contact us if the problem persists.'
        );
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const response = e.response;
        setResendCodeResponse(response);

        if (response?.data.message === 'account not found') {
          setResendCodeMessage(
            'No account is associated with this phone number. Please create account first.'
          );
        } else if (response?.data.message === 'account suspended') {
          setResendCodeMessage(
            'Your account has been suspended because you entered a wrong verification code too many times. Please contact us to unblock your account.'
          );
        } else if (response?.data.message === 'too many requests') {
          setResendCodeMessage(
            `Too many requests. Please wait 2 minutes and try again.`
          );
        } else
          setResendCodeMessage(
            `Failed to resend verification code: ${e.message}`
          );
      } else {
        setResendCodeMessage(
          'Failed to resend verification code. Please contact us to help you with this.'
        );
      }
      console.log(e);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    resetMessagesAndResponses();
    const requestData = {
      account_id: phoneNumber.replaceAll(' ', ''),
      verification_code: verificationCodeInput,
      password: password,
    };
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/auth/verify-account/`,
        requestData
      );
      setVerificationResponse(response);
      setVerificationMessage('Account Verified! You can login now 😄');
      setVerified(true);
      onSuccess && onSuccess()
    } catch (e) {
      if (isAxiosError(e)) {
        const authResponse = e.response;
        if (authResponse?.data.message === 'account suspended') {
          setVerificationMessage(
            'Your account has been suspended because you entered a wrong verification code too many times. Please contact us to unblock your account.'
          );
        } else {
          setVerificationMessage(
            'Verification code is incorrect or has expired'
          );
        }

        setVerificationResponse(e.response);
      } else {
        setVerificationMessage(
          'Failed to verify. Try again later or contact us.'
        );
      }
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setIsLoading(false);
    setVerificationCodeInput('');
    resetMessagesAndResponses();
    closeVerificationDialog();
  };

  useEffect(() => {
    if (isOpen)
      setResendCountdown(
        <Countdown
          date={Date.now() + 2 * 60 * 1000}
          onComplete={() => setResendAllowed(true)}
          renderer={({ hours, minutes, seconds, completed }) => (
            <span>
              {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
          )}
        />
      );
  }, [resendAllowed, isOpen]);

  return (
    <div>
      <Dialog fullWidth open={isOpen} >
        <DialogTitle>
          Please use the verification code sent to {phoneNumber} by whatsapp
        </DialogTitle>
        <DialogContent>
          {verificationMessage && (
            <>
              <Alert
                severity={
                  verificationResponse &&
                    verificationResponse.statusText === 'OK'
                    ? 'success'
                    : 'error'
                }
              >
                {verificationMessage}
              </Alert>
              <br />

              {verificationResponse &&
                verificationResponse.statusText === 'OK' && (
                  <Button
                    fullWidth
                    onClick={() => {
                      setUser(null);
                      closeVerificationDialog();
                      navigate('/login');
                    }}
                    variant="contained"
                  >
                    {' '}
                    Login{' '}
                  </Button>
                )}
            </>
          )}

          {resendCodeMessage && (
            <>
              <Alert
                severity={
                  resendCodeResponse && resendCodeResponse.statusText === 'OK'
                    ? 'success'
                    : 'error'
                }
              >
                {resendCodeMessage}
              </Alert>
            </>
          )}

          <br />

          {!verified && (
            <>
              {isLoading ? (
                <LinearProgress />
              ) : (
                <form onSubmit={handleSubmit}>
                  <>
                    <FormControl key={'verification-code-input'} fullWidth>
                      <TextField
                        id="verification-code-input"
                        label="Verification Code"
                        type="text"
                        value={verificationCodeInput}
                        disabled={isLoading}
                        required
                        onChange={(e) =>
                          setVerificationCodeInput(e.target.value)
                        }
                      />
                    </FormControl>

                    <br />
                    <br />

                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      {' '}
                      Verify{' '}
                    </Button>

                    <hr />
                    {isLoading && <LinearProgress />}

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 12,
                      }}
                    >
                      <Button
                        style={{ display: 'block', width: '49%' }}
                        fullWidth
                        variant="outlined"
                        disabled={isLoading || !resendAllowed}
                        onClick={handleResendRequest}
                      >
                        Resend Code
                        <br />
                        {!resendAllowed && resendCountdown}
                      </Button>

                      <Button
                        style={{ display: 'block', width: '49%' }}
                        fullWidth
                        variant="outlined"
                        disabled={isLoading}
                        onClick={handleClose}
                      >
                        Modify Phone Number
                      </Button>
                    </div>
                  </>
                </form>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
