
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { BranchType } from '../../../../interfaces/delivery-company/branches';

interface BranchesListingTableProps {
  branches: BranchType[];
  isLoading: boolean;
}

function BranchesListingTable({
  branches,
  isLoading,
}: BranchesListingTableProps) {
  return (
    <TableContainer style={{ maxHeight: '80%' }}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Name</b>
              </TableCell>
              <TableCell align="center">
                <b>Address</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {branches.map((branch) => (
              <TableRow key={branch.id}>
                <TableCell align="center" style={{ cursor: 'pointer' }}>
                  <a href={`/branch/${branch.id}`}>
                    <b>{branch.name}</b>
                  </a>
                </TableCell>
                <TableCell align="center">{branch.address}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

export default BranchesListingTable;
