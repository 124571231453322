import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CreateEntityDrawer from '../../common/create-entity-drawer/create-entity-drawer';
import BranchesListing from './branches-listing/branches-listing';
import CreateBranchForm from './create-branch-form/create-branch-form';

function Branches() {
  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Branches
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={4}></Grid>
          <Grid xs={4}></Grid>
          <Grid xs={4} sx={{ textAlign: 'right' }}>
            <CreateEntityDrawer>
              <CreateBranchForm />
            </CreateEntityDrawer>
          </Grid>
          <Grid xs={12}>
            <BranchesListing />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Branches;
