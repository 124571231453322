import { Container, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import CreateBranchForm from '../branches/create-branch-form/create-branch-form';

function Branch() {
  const { branchId } = useParams();
  return (
    <div>
      <Container>
        <Box>
          <CreateBranchForm defaultBranchId={branchId} />
        </Box>
      </Container>
    </div>
  );
}

export default Branch;
