import { Alert } from '@mui/material';

function PageNotFound() {
  return (
    <div>
      <Alert severity="error"> Oops.. Page not found!</Alert>
    </div>
  );
}

export default PageNotFound;
