import {
  Alert,
  Button,
  FormControl,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';

import { emptyDriver } from './driver-form.utils';

import Grid from '@mui/material/Unstable_Grid2';
import axios, { AxiosResponse } from 'axios';
import MuiPhoneNumber from 'mui-phone-number';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import {
  CreateDriverFormProps,
  DriverType,
} from '../../../../interfaces/delivery-company/drivers';
import ConfirmAction from '../../../common/confirm-action/confirm-action';
import { UserType } from '../../../../enums/users';
import { ensureZeroAfterCountryCode } from '../../../../utils/phone_numbers';

function EditExistingDriver({
  defaultDriver,
  headless,
}: CreateDriverFormProps) {
  const [driverData, setDriverData] = useState<DriverType>(
    defaultDriver || emptyDriver
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [confirmDeleteDriver, setConfirmDeleteDriver] =
    useState<boolean>(false);
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');
  const { user } = useContext(AuthContext);

  console.log(user?.permissions)
  const canDeleteDriver = user?.type === UserType.ORGANISATION || user?.permissions?.includes("delete_basedriver")
  const canChangeDriver = user?.type === UserType.ORGANISATION || user?.permissions?.includes("change_basedriver")


  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (driverData) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Saving Driver...');
      const requestData = {
        name: driverData.name,
        phone_number: ensureZeroAfterCountryCode(driverData.phone_number).replaceAll(' ', ''),
        default_commission_lbp: driverData.default_commission_lbp,
        default_commission_usd: driverData.default_commission_usd,
      };

      console.log(requestData);

      try {
        const endpoint = `${API_ENDPOINT}/api/org/driver/${driverData.id}/?isguest=false`;

        // edit existing
        const response = await axios.put(endpoint, requestData, config);

        setResponse(response);
        setMessage('Driver saved successfully');
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response)
          setMessage('Failed to save driver: ' + e.response?.statusText);
        } else {
          setMessage('Failed to save driver');
        }
      }

      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const deleteDriver = async () => {
    if (driverData && driverData.id !== '') {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/org/driver/${driverData.id}/?isguest=false`,
          config
        );
        setResponse(response);

        setDriverData(emptyDriver);
        setMessage('Driver deleted successfully');

      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response)
          setMessage('Failed to delete driver: ' + e.response?.statusText);
        } else {
          setMessage('Failed to delete driver');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      {!headless && (
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Edit Driver
        </Typography>
      )}

      {isLoading ? (
        <>
          {loadingMessage}
          <br />
          <LinearProgress />
        </>
      ) : (
        <>
          {message != '' && (
            <>
              <Alert
                severity={
                  response && response.status >= 200 && response.status < 300
                    ? 'success'
                    : 'error'
                }
              >
                <>
                  {message}

                  {response &&
                    response.status >= 300 &&
                    Object.keys(response.data).map((key) => (
                      <>
                        <ul>
                          {
                            typeof response.data[key] === "string" ?
                              <li>{response.data[key]}</li> :
                              response.data[key].map((validationError: string) => (
                                <li key={key}>
                                  {key}: {validationError}
                                </li>
                              ))}
                        </ul>
                      </>
                    ))}
                </>
              </Alert>
              <br /> </>
          )}


          {driverData && (
            <>
              {' '}
              <br></br>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    label="Driver Name"
                    type="text"
                    variant="outlined"
                    required
                    disabled={true}
                    value={driverData.name}
                  />
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <MuiPhoneNumber
                    label="Driver Phone Number"
                    type="tel"
                    defaultCountry={'lb'}
                    variant="outlined"
                    required
                    value={driverData.phone_number}
                    disabled={true}
                    onChange={(newPhone) => {
                      if (typeof newPhone === 'string') {
                        setDriverData({
                          ...driverData,
                          phone_number: newPhone,
                        });
                      } else
                        setDriverData({
                          ...driverData,
                          phone_number: newPhone.target.value,
                        });
                    }}
                  />
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <Grid container spacing={1}>
                    <Grid xs={6}>
                      <TextField
                        label="Default Commission per Order $"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={driverData.default_commission_usd}
                        inputProps={{
                          maxLength: 13,
                          step: '0.01',
                        }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            'wheel',
                            (e) => e.preventDefault(),
                            { passive: false }
                          )
                        }
                        onChange={(e) =>
                          setDriverData({
                            ...driverData,
                            default_commission_usd: parseFloat(e.target.value),
                          })
                        }
                      />
                    </Grid>

                    <Grid xs={6}>
                      <TextField
                        label="Default Commission per Order L.L"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={driverData.default_commission_lbp}
                        onChange={(e) =>
                          setDriverData({
                            ...driverData,
                            default_commission_lbp: parseFloat(e.target.value),
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <br /> <br />
                <div style={{ display: 'flex', gap: 7 }}>
                  <Button type="submit" variant="contained" disabled={!canChangeDriver}>
                    Save Driver
                  </Button>

                  {driverData.id !== '' && (
                    <Button
                      onClick={() => {
                        setConfirmDeleteDriver(true);
                      }}
                      variant="outlined"
                      color="error"
                      disabled={!canDeleteDriver}
                    >
                      Delete driver
                    </Button>
                  )}
                </div>
              </form>
            </>
          )}
        </>
      )}
      <ConfirmAction
        message={'Are you sure you want to delete this driver?'}
        action={deleteDriver}
        isOpen={confirmDeleteDriver}
        handleClose={() => setConfirmDeleteDriver(false)}
        isSevereAction={true}
        confirmSevereActionTarget="delete driver"
      >
        <div>
          Attention: This action will delete all orders registered for this
          driver
        </div>
      </ConfirmAction>
    </>
  );
}

export default EditExistingDriver;
