import ClearIcon from '@mui/icons-material/Clear';
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useState } from 'react';

import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { emptyCustomer } from '../../../../data/customers';
import { emptyMerchant } from '../../../../data/merchants';
import { RegionType, emptyRegion, regions } from '../../../../data/regions';
import { CustomerType } from '../../../../interfaces/common/customer';
import { BranchType } from '../../../../interfaces/delivery-company/branches';
import {
  CompactMerchantType,
  MerchantType,
} from '../../../../interfaces/delivery-company/merchants';
import {
  CreateOrderFormProps,
  OrderType,
} from '../../../../interfaces/delivery-company/orders';
import AutocompleteWithThrottle from '../../../common/autocomplete-with-throttle/autocomplete-with-throttle';
import {
  assignBranchProvider,
  assignCustomerProvider,
  assignMerchantProvider,
} from '../../../common/create-callbacks-utils/create-callbacks-utils';
import CreateCustomerForm from '../../../common/create-customer-form/create-customer-form';
import TextFieldWithScan from '../../../common/textfield-with-scan/textfield-with-scan';
import CreateBranchForm from '../../branches/create-branch-form/create-branch-form';
import CreateMerchantForm from '../../merchants/merchant-forms/create-or-edit-merchant';
import { emptyOrder } from './create-order-form.utils';

function CreateOrderForm({ defaultOrder }: CreateOrderFormProps) {
  const [orderData, setOrderData] = useState<OrderType | undefined>(
    defaultOrder || emptyOrder
  );

  console.log('default Order = ', defaultOrder);
  console.log('order Data = ', orderData);
  const [creatingNewCustomerOpen, setCreatingNewCustomerOpen] =
    useState<boolean>(false);
  const [creatingNewMerchantOpen, setCreatingNewMerchantOpen] =
    useState<boolean>(false);
  const [creatingNewBranchOpen, setCreatingNewBranchOpen] =
    useState<boolean>(false);
  const [autoAssignOrderId, setAutoAssignOrderId] = useState<boolean>(false)

  const [regionSearchInput, setRegionSearchInput] = useState(
    orderData?.district
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleFetchCustomers = async (
    query: string
  ): Promise<CustomerType[]> => {
    if (orderData) {
      const endpoint = `${API_ENDPOINT}/api/org/customers/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;

      try {
        const response = await axios.get<{
          count: number;
          results: CustomerType[];
        }>(endpoint, config);

        if (response.statusText === 'OK') {
          return response.data.results;
        }

        return [];
      } catch (error) {
        console.log(error);
      }
    }

    return [];
  };

  const handleFetchMerchants = async (
    query: string
  ): Promise<MerchantType[]> => {
    if (orderData) {
      const endpoint = `${API_ENDPOINT}/api/org/merchants/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;

      try {
        const response = await axios.get<{
          count: number;
          results: MerchantType[];
        }>(endpoint, config);

        if (response.statusText === 'OK') {
          return response.data.results;
        }

        return [];
      } catch (error) {
        console.log(error);
      }
    }

    return [];
  };

  const handleFetchBranches = async (query: string) => {
    if (orderData) {
      const endpoint = `${API_ENDPOINT}/api/org/branches/?page_size=10&name=${encodeURIComponent(query)}`;

      try {
        const response = await axios.get<{
          count: number;
          results: BranchType[];
        }>(endpoint, config);

        console.log(response);

        if (response.statusText === 'OK') {
          return response.data.results;
        }

        return [];
      } catch (error) {
        console.log(error);
      }
    }

    return [];
  };

  // Initialise data assigners to be used as success callbacks when creating entities inline
  const assignCustomer = assignCustomerProvider({
    orderData,
    setOrderData,
    setRegionSearchInput,
  });
  const assignMerchant = assignMerchantProvider({ orderData, setOrderData });
  const assignBranch = assignBranchProvider({ orderData, setOrderData });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (orderData) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Saving Order...');
      setMessage('');

      const requestData = orderData;

      try {
        const endpoint = `${API_ENDPOINT}/api/org/orders/${orderData.id}/`;

        let response;

        console.log('Request Data: ');
        console.log(requestData);

        if (orderData.id === '') {
          // create new
          console.log(endpoint);
          response = await axios.post(
            `${API_ENDPOINT}/api/org/orders/`,
            requestData,
            {
              headers: { ...config.headers, 'X-Auto-Assign-ID': autoAssignOrderId }
            }
          );
        } else {
          // edit existing
          response = await axios.put(endpoint, requestData,
            {
              headers: { ...config.headers, 'X-Auto-Assign-ID': autoAssignOrderId }
            }
          );
        }
        setResponse(response);
        setMessage('Order saved successfully');
        setOrderData({ ...emptyOrder, merchant: orderData.merchant });
        setRegionSearchInput('');
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          console.log('Response from catch block: ', response);
          setResponse(e.response);

          if (e.response !== undefined)
            setMessage('Failed to save order: ' + e.response.statusText);
          else setMessage('Failed to save order');
        } else {
          setMessage('Failed to save order');
        }
        console.log(e);
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  console.log('RESPONSE = ', response);

  return (
    <>
      <Typography
        variant="h3"
        component="h3"
        sx={{ textAlign: 'center', mt: 3, mb: 3 }}
      >
        {orderData?.id === '' ? 'Create Order' : 'Edit Order'}
      </Typography>

      {isLoading ? (
        <>
          {loadingMessage}
          <br />
          <LinearProgress />
        </>
      ) : (
        <>
          {message != '' && (
            <Alert
              severity={
                response && response.status >= 200 && response.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              <>
                {message}

                {response &&
                  response.status >= 300 &&
                  Object.keys(response.data).map((key) => (
                    <>
                      <ul>
                        {
                          typeof response.data[key] === "string" ?
                            <li>{response.data[key]}</li> :
                            response.data[key].map((validationError: string) => (
                              <li key={key}>
                                {key}: {validationError}
                              </li>
                            ))}
                      </ul>
                    </>
                  ))}
              </>
            </Alert>
          )}

          {orderData && (
            <>
              <br></br>
              <Switch
                onChange={() => {
                  setAutoAssignOrderId(!autoAssignOrderId)
                }}
                checked={autoAssignOrderId}
              />
              Assign Order Id Automatically
              <br /><br />
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Grid container spacing={2}>
                    {!autoAssignOrderId && <Grid xs={12} md={6}>
                      <TextFieldWithScan
                        label="Order Id"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={orderData.order_id}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        }}
                        act={(value) =>
                          setOrderData({
                            ...orderData,
                            order_id: value,
                          })
                        }
                      />
                    </Grid>
                    }
                    <Grid xs={12} md={autoAssignOrderId ? 12 : 6}>
                      <TextFieldWithScan
                        label="Reference Number"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={orderData.reference_id}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        }}

                        act={(value) =>
                          setOrderData({
                            ...orderData,
                            reference_id: value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ flexGrow: 1 }}
                  >
                    <Grid xs={12} md={6}>
                      <AutocompleteWithThrottle
                        label={'Customer'}
                        onClickAdd={() => setCreatingNewCustomerOpen(true)}
                        fetch={handleFetchCustomers}
                        value={orderData.customer}
                        getOptionLabel={(option: CustomerType) =>
                          option.name + ' ' + option.phone_number
                        }
                        onChange={(e: any, value: CustomerType | null) => {
                          assignCustomer(value ?? emptyCustomer);
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <AutocompleteWithThrottle
                        label={'Merchant'}
                        onClickAdd={() => setCreatingNewMerchantOpen(true)}
                        fetch={handleFetchMerchants}
                        value={
                          orderData.merchant.id !== ''
                            ? orderData.merchant
                            : null
                        }
                        getOptionLabel={(option: CompactMerchantType) =>
                          option.name + ' ' + option.phone_number
                        }
                        onChange={(e, value) => {
                          setOrderData({
                            ...orderData,
                            merchant: value ? value : emptyMerchant,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                      <TextField
                        label="Address"
                        type="string"
                        variant="outlined"
                        fullWidth
                        required
                        value={orderData.address}
                        onChange={(e) =>
                          setOrderData({
                            ...orderData,
                            address: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Autocomplete
                        disablePortal
                        id="district"
                        isOptionEqualToValue={(option, value) =>
                          option.district === value.district
                        }
                        value={
                          orderData.district !== ''
                            ? {
                              district: orderData.district,
                              governorate: orderData.governorate,
                            }
                            : null
                        }
                        onChange={(_, newValue: RegionType | null) => {
                          if (newValue === null) newValue = emptyRegion;

                          setOrderData({
                            ...orderData,
                            district: newValue.district,
                            governorate: newValue.governorate,
                          });
                        }}
                        inputValue={regionSearchInput}
                        onInputChange={(event, newInputValue) => {
                          if (event === null) return;
                          setRegionSearchInput(newInputValue);
                        }}
                        options={regions.sort((a, b) =>
                          b.governorate.localeCompare(a.governorate)
                        )}
                        groupBy={(option) => option.governorate}
                        getOptionLabel={(option) => option.district}
                        renderInput={(params) => (
                          <TextField {...params} label="District" />
                        )}
                      />
                    </Grid>
                  </Grid>
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <AutocompleteWithThrottle
                    label={'Branch'}
                    onClickAdd={() => setCreatingNewBranchOpen(true)}
                    fetch={handleFetchBranches}
                    value={orderData.branch ? orderData.branch : null}
                    getOptionLabel={(option: BranchType) =>
                      option.name + ' - ' + option.address
                    }
                    required={false}
                    onChange={(e, value) => {
                      const newOrderData = {
                        ...orderData,
                        branch: value ? value : null,
                      };

                      setOrderData(newOrderData);
                    }}
                  />
                </FormControl>
                <br />
                <br />

                <FormControl fullWidth>
                  <Grid container spacing={2}>
                    <Grid xs={6}>
                      <TextField
                        label="Order Price $"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={orderData.price_usd}
                        inputProps={{
                          maxLength: 13,
                          step: '0.01',
                        }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            'wheel',
                            (e) => e.preventDefault(),
                            { passive: false }
                          )
                        }
                        onChange={(e) =>
                          setOrderData({
                            ...orderData,
                            price_usd: parseFloat(e.target.value),
                          })
                        }
                      />
                    </Grid>

                    <Grid xs={6}>
                      <TextField
                        label="Order Price L.L"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={orderData.price_lbp}
                        onChange={(e) =>
                          setOrderData({
                            ...orderData,
                            price_lbp: parseInt(e.target.value),
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <br />
                <br />

                <FormControl fullWidth>
                  <Grid container spacing={2}>
                    <Grid xs={6}>
                      <TextField
                        label="Delivery Fee $"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={orderData.delivery_fee_usd}
                        inputProps={{
                          maxLength: 13,
                          step: '0.01',
                        }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            'wheel',
                            (e) => e.preventDefault(),
                            { passive: false }
                          )
                        }
                        onChange={(e) =>
                          setOrderData({
                            ...orderData,
                            delivery_fee_usd: parseFloat(e.target.value),
                          })
                        }
                      />
                    </Grid>

                    <Grid xs={6}>
                      <TextField
                        label="Delivery Fee L.L"
                        type="number"
                        variant="outlined"
                        fullWidth
                        required
                        value={orderData.delivery_fee_lbp}
                        onChange={(e) =>
                          setOrderData({
                            ...orderData,
                            delivery_fee_lbp: parseInt(e.target.value),
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <br />
                <br />

                <FormControl fullWidth>
                  <TextField
                    label="Number of Packages"
                    type="number"
                    variant="outlined"
                    required
                    value={orderData.number_of_packages}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        'wheel',
                        (e) => e.preventDefault(),
                        { passive: false }
                      )
                    }
                    onChange={(e) =>
                      setOrderData({
                        ...orderData,
                        number_of_packages: parseInt(e.target.value),
                      })
                    }
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <TextField
                    label="Description"
                    type="text"
                    variant="outlined"
                    multiline
                    value={orderData.description}
                    rows={2}
                    onChange={(e) =>
                      setOrderData({
                        ...orderData,
                        description: e.target.value,
                      })
                    }
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <TextField
                    label="Public Note"
                    type="text"
                    variant="outlined"
                    multiline
                    value={orderData.public_note}
                    rows={2}
                    onChange={(e) =>
                      setOrderData({
                        ...orderData,
                        public_note: e.target.value,
                      })
                    }
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <TextField
                    label="Private Note"
                    type="text"
                    variant="outlined"
                    multiline
                    value={orderData.private_note}
                    rows={2}
                    onChange={(e) =>
                      setOrderData({
                        ...orderData,
                        private_note: e.target.value,
                      })
                    }
                  />
                </FormControl>

                <br />
                <br />

                <Button type="submit" variant="contained">
                  Save Order
                </Button>
              </form>
            </>
          )}
        </>
      )}

      <div style={{ zIndex: '999999 !important' }}>
        <Dialog
          fullWidth
          open={creatingNewCustomerOpen}
          onClose={() => undefined}
          sx={{ zIndex: '999999 !important' }}
        >
          <div
            style={{
              textAlign: 'right',
              width: '100%',
            }}
          >
            <IconButton onClick={() => setCreatingNewCustomerOpen(false)}>
              <ClearIcon color="primary" fontSize="large" />
            </IconButton>
          </div>

          <DialogContent>
            <CreateCustomerForm
              baseEndpoint={`${API_ENDPOINT}/api/org`}
              successCallback={assignCustomer}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth
          open={creatingNewMerchantOpen}
          onClose={() => undefined}
          sx={{ zIndex: '999999 !important' }}
        >
          <div
            style={{
              textAlign: 'right',
              width: '100%',
            }}
          >
            <IconButton onClick={() => setCreatingNewMerchantOpen(false)}>
              <ClearIcon color="primary" fontSize="large" />
            </IconButton>
          </div>

          <DialogContent>
            <CreateMerchantForm successCallback={assignMerchant} />
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth
          open={creatingNewBranchOpen}
          onClose={() => undefined}
          sx={{ zIndex: '999999 !important' }}
        >
          <div
            style={{
              textAlign: 'right',
              width: '100%',
            }}
          >
            <IconButton onClick={() => setCreatingNewBranchOpen(false)}>
              <ClearIcon color="primary" fontSize="large" />
            </IconButton>
          </div>

          <DialogContent>
            <CreateBranchForm successCallback={assignBranch} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default CreateOrderForm;
