export const formatMetric = (metric: number): string => {
  if (metric < 1000) return metric.toString();
  else {
    const metricParts = (metric / 1000).toString().split('.');
    return metricParts[0] + '.' + metricParts[1][0] + 'k';
  }
};

export const stateHeaderStyle = (color: string): React.CSSProperties => {
  return {
    fontSize: '125%',
    fontWeight: 'bold',
    color: color,
  };
};

export const stateMetricStyle = (color: string): React.CSSProperties => {
  return {
    fontSize: '250%',
    fontWeight: 'bold',
    color: color,
  };
};

export const generateMetricComponent = (
  name: string,
  metric: number,
  color: string
) => {
  return (
    <>
      <div style={stateHeaderStyle(color)}>{name}</div>

      <div style={stateMetricStyle(color)}>{formatMetric(metric)}</div>
    </>
  );
};
