import { useContext, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import { ParsedOrderType, getParsedOrdersErrors, parseFile } from './utils';
import { API_ENDPOINT } from '../../../configurations/global.config';
import axios, { AxiosResponse, isAxiosError } from 'axios';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { getGovernorateForDistrict } from '../../../data/regions';
import { isArray } from 'lodash';

interface MerchantBulkCreateOrdersProps {
  orgId?: string;
}

interface ProcessingResultType {
  isSuccess: boolean;
  message?: string;
  errors?: string[];
}

function MerchantBulkCreateOrders({ orgId }: MerchantBulkCreateOrdersProps) {
  const [bulkCreateOrderOpen, setBulkCreateOrderOpen] =
    useState<boolean>(false);
  const uploadInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File>();
  const [processingFile, setProcessingFile] = useState<boolean>(false);
  const [processingResult, setProcessingResult] =
    useState<ProcessingResultType>();

  const [createOrdersMessage, setCreateOrdersMessage] = useState<string>("")
  const [createOrdersResponse, setCreateOrdersResponse] = useState<AxiosResponse>()

  const [processingStage, setProcessingStage] = useState<string>('');
  const [parsedOrders, setParsedOrders] = useState<ParsedOrderType[]>();

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const resetStates = () => {
    setFile(undefined);
    setProcessingFile(false);
    setProcessingStage('');
    setProcessingResult(undefined);
    setCreateOrdersMessage("");
    setCreateOrdersResponse(undefined);
  };

  const handleClose = () => {
    setBulkCreateOrderOpen(false);
    resetStates();
  };

  useEffect(() => {
    if (processingResult !== undefined) setProcessingFile(false);
  }, [processingResult]);

  useEffect(() => {
    resetStates();
    const parse = async () => {
      if (file === undefined) return;
      setProcessingFile(true);
      setProcessingStage('Reading File');
      parseFile(file, setParsedOrders, setProcessingResult);
    };
    parse();
  }, [file]);

  useEffect(() => {
    console.log("running use effect for orders change")
    console.log(orgId)
    if (orgId === undefined) return;
    console.log(parsedOrders);
    const upload = async () => {
      setProcessingStage('Validating File Data');
      if (parsedOrders) {
        const parseErrors = getParsedOrdersErrors(parsedOrders);
        if (parseErrors.length > 0) {
          resetStates();
          setProcessingResult({
            isSuccess: false,
            message: `Found ${parseErrors.length} Issues in your file`,
            errors: parseErrors,
          });
          return;
        } else {
          setProcessingStage('Creating Orders');

          const endpoint = `${API_ENDPOINT}/api/merchant/orders/?org=${orgId}`;
          let response;

          try {
            console.log(endpoint);
            console.log(parsedOrders);
            const requestData = parsedOrders.map((order) => {
              return {
                ...order,
                customer: {
                  name: order.customer_name,
                  phone_number: order.customer_phone,
                  address: order.address,
                  district: order.district,
                  governorate: getGovernorateForDistrict(order.district),
                },
                governorate: getGovernorateForDistrict(order.district),
              };
            });
            console.log('request data = ', requestData);
            response = await axios.post(endpoint, requestData, config);
            console.log(response);

            setCreateOrdersResponse(response)
            setCreateOrdersMessage("Orders created successfully!")

          } catch (e) {
            if (isAxiosError(e)) {
              setCreateOrdersResponse(e.response)
              setCreateOrdersMessage('Failed to create orders: ' + e.response?.statusText)
            } else {
              setCreateOrdersMessage('Failed to create orders')
            }
            console.log(e);
          }
        }
      } else resetStates();
    };
    upload();
  }, [parsedOrders]);

  return (
    <div>
      <Button variant="outlined" onClick={() => setBulkCreateOrderOpen(true)}>
        {' '}
        Create Using CSV
      </Button>

      <Dialog fullWidth open={bulkCreateOrderOpen} onClose={handleClose}>
        <DialogContent>
          <a href="https://drive.google.com/u/0/uc?id=1IbDlaEfGvTpq4KldYFnQVxMZtMyYOKUD&export=download">
            <Button fullWidth variant="outlined">
              Download Template CSV
            </Button>
          </a>

          <br />
          <br />

          <input
            ref={uploadInputRef}
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
            onChange={(e) => {
              e.target.files !== null && setFile(e.target.files[0]);
            }}
          />

          <div style={{ textAlign: 'center' }}>
            {' '}
            <Button
              onClick={() => {
                uploadInputRef.current && uploadInputRef.current.click();
              }}
              variant="contained"
              disabled={processingFile}
            >
              {file === undefined ? 'Upload File' : 'Change File'}
            </Button>
          </div>

          {processingFile && (
            <>
              <br />
              <b>{processingStage}:</b> {file && file.name}
              <LinearProgress />
            </>
          )}

          {processingResult !== undefined && (
            <>
              <br />{' '}

              {
                createOrdersMessage != '' ?
                  (
                    <>
                      <Alert
                        severity={
                          createOrdersResponse && createOrdersResponse.status >= 200 && createOrdersResponse.status < 300
                            ? 'success'
                            : 'error'
                        }
                      >
                        <>
                          {createOrdersMessage}

                          {createOrdersResponse &&
                            createOrdersResponse.status >= 300 && <>
                              {
                                isArray(createOrdersResponse.data) ?
                                  <>
                                    {createOrdersResponse.data.map(item =>

                                      <>
                                        {
                                          Object.keys(item).map((key) => (
                                            <>
                                              <ul>
                                                {
                                                  typeof item[key] === "string" ?
                                                    <li>{item[key]}</li> :
                                                    item[key].map((validationError: string) => (
                                                      <li key={key}>
                                                        {key}: {validationError}
                                                      </li>
                                                    ))}
                                              </ul>
                                            </>
                                          ))
                                        }
                                      </>
                                    )}
                                  </>
                                  :
                                  <>
                                    {
                                      Object.keys(createOrdersResponse.data).map((key) => (
                                        <>
                                          <ul>
                                            {
                                              typeof createOrdersResponse.data[key] === "string" ?
                                                <li>{createOrdersResponse.data[key]}</li> :
                                                createOrdersResponse.data[key].map((validationError: string) => (
                                                  <li key={key}>
                                                    {key}: {validationError}
                                                  </li>
                                                ))}
                                          </ul>
                                        </>
                                      ))
                                    }
                                  </>

                              }
                            </>
                          }
                        </>
                      </Alert>
                      <br /> </>
                  )

                  :

                  <Alert
                    severity={processingResult.isSuccess ? 'success' : 'error'}
                  >
                    {processingResult.isSuccess ? (
                      processingResult.message
                    ) : (
                      <>
                        <b>{processingResult.message}:</b>
                        {processingResult.errors?.map((errorMessage, index) => (
                          <li key={index}>{errorMessage}</li>
                        ))}
                      </>
                    )}
                  </Alert>
              }

            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MerchantBulkCreateOrders;
