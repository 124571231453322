import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Alert,
  Button,
  Card,
  CircularProgress,
  FormControl,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PDFDownloadLink } from '@react-pdf/renderer';
import axios, { AxiosResponse, isAxiosError } from 'axios';
import { useContext, useState } from 'react';
import {
  API_ENDPOINT
} from '../../../../configurations/global.config';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { DoubleDate } from '../../../common/double-date-picker/double-date-picker.interfaces';

import {
  cardHeaderStyle,
  cardStyle,
} from '../../../common/styles/grid-card.style';
import PdfGenerator from './pdf-generator';


export type Aggregation = {
  price_lbp: number;
  price_usd: number;
  delivery_usd: number;
  delivery_lbp: number;
  commission_usd: number;
  commission_lbp: number;
  count: number;
}

export type OrdersSummary = {
  count: number;
  uninvoiced: Aggregation;
  invoiced_for_driver: Aggregation;
  invoiced_for_merchant: Aggregation;
}

export interface ReportSummary {
  delivered_orders: OrdersSummary;
  cancelled_orders: OrdersSummary;
}


function ProfitReport() {
  const [dateFilter, setDateFilter] = useState<DoubleDate>({
    startDate: null,
    endDate: null,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportSummary, setReportSummary] = useState<ReportSummary>()
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const generateFileName = () => {
    if (dateFilter.startDate === null && dateFilter.endDate === null)
      return 'invalid-date';

    if (dateFilter.startDate === null) {
      return '0.0.0-' + dateFilter.endDate?.toDate().toLocaleDateString();
    }

    if (dateFilter.endDate === null) {
      return dateFilter.startDate?.toDate().toLocaleDateString() + '-0.0.0';
    }

    return (
      dateFilter.startDate.toDate().toLocaleDateString() +
      '-' +
      dateFilter.endDate.toDate().toLocaleDateString()
    );
  };

  const resetReportSummary = () => {
    setReportSummary(undefined)
  };

  const downloadReport = async () => {
    if (dateFilter.startDate === null && dateFilter.endDate === null) return;

    setIsLoading(true);

    const relevant_fields = [
      'order_id',
      'price_usd',
      'price_lbp',
      'delivery_fee_usd',
      'delivery_fee_lbp',
      'driver_commission_lbp',
      'driver_commission_usd',
      'customer',
      'address',
    ];

    let endpoint = `${API_ENDPOINT}/api/org/profit-report/?`;

    if (dateFilter.startDate !== null)
      endpoint += `&date_from=${encodeURIComponent(dateFilter.startDate.format('YYYY-MM-DD'))}`;

    if (dateFilter.endDate !== null)
      endpoint += `&date_to=${encodeURIComponent(dateFilter.endDate.format('YYYY-MM-DD'))}`;


    try {
      const summaryResponse = await axios.get<ReportSummary>(endpoint, config)
      const data = summaryResponse.data
      setReportSummary(data)
      setResponse(response)
    } catch (e) {
      if (isAxiosError(e)) {
        const response = e.response;
        setResponse(response);
        setMessage('Failed to get orders: ' + e.message);
      } else {
        setMessage('Unexpected error. Please try again.');
      }
    }

    setIsLoading(false);
  };


  return (
    <Grid style={{ textAlign: 'center' }} xs={12} md={4}>
      <Card style={cardStyle}>
        <div style={cardHeaderStyle}>
          <AttachMoneyIcon />
          <div>
            <b>Profit Report</b>
          </div>
        </div>
        <br />
        {message !== '' && (
          <>
            <Alert
              severity={
                response && response.status >= 200 && response?.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              {message}
            </Alert>
            <br />
          </>
        )}

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: 8,
          }}
        >
          <FormControl style={{ maxWidth: '40%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
                value={dateFilter.startDate}
                onChange={(newValue) => {
                  setDateFilter({
                    ...dateFilter,
                    startDate: newValue,
                  });

                  resetReportSummary();
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl style={{ maxWidth: '40%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To"
                value={dateFilter.endDate}
                onChange={(newValue) => {
                  setDateFilter({
                    ...dateFilter,
                    endDate: newValue,
                  });

                  resetReportSummary();
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </div>

        <br />

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : reportSummary === undefined ? (
            <Button onClick={downloadReport} variant="contained">
              Generate Report
            </Button>
          ) : (
            <PDFDownloadLink
              document={
                <PdfGenerator
                  title="INTERNAL PROFIT REPORT"
                  date_from={
                    dateFilter.startDate?.toDate().toLocaleDateString() ?? ''
                  }
                  date_to={
                    dateFilter.endDate?.toDate().toLocaleDateString() ?? ''
                  }
                  organisation_id={user?.organisation?.org_id ?? 'UNKNOWN'}
                  reportSummary={reportSummary}
                />
              }
              fileName={generateFileName()}
            >
              {({ blob, url, loading, error }) => {
                return loading ? (
                  <Button color="primary" size="large" disabled>
                    Loading...
                  </Button>
                ) : (
                  <Button color="primary" size="large">
                    <DownloadIcon fontSize="large" />
                  </Button>
                );
              }}
            </PDFDownloadLink>
          )}
        </div>
      </Card>
    </Grid >
  );
}

export default ProfitReport;
