import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { IFooterRow } from './report';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    fontSize: 12,
    fontStyle: 'bold',
  },
  description: {
    width: '50%',
    paddingRight: 8,
  },
  total: {
    width: '50%',
    textAlign: 'right',
    paddingRight: 8,
  },
});

interface ReportTableFooterProps {
  table_footer_rows: IFooterRow[];
  orders: OrderType[];
}

export const ReportTableFooter = ({
  table_footer_rows,
  orders,
}: ReportTableFooterProps) => {
  return (
    <>
      {table_footer_rows.map((row) => (
        <View style={styles.row} key={row.label}>
          <Text style={styles.description}>{row.label.toUpperCase()} </Text>
          <Text style={styles.total}> {row.compute_value(orders)} </Text>
        </View>
      ))}
    </>
  );
};
