import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { BulkActionProps } from './props.interface';
import { FormControl, Button, TextField } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import WaybillPrinter from '../waybill-printer/waybill-printer';
import { primaryPaletteColor } from '../../../configurations/global.config';

function PrintWaybills({
    selectedOrders,
    handleClose,
    orgId,
    extractAsPdf,
}: BulkActionProps) {
    const fileName = "waybills"

    const [waybillsPerPage, setWaybillsPerPage] = useState(1)
    const [startGenerating, setStartGenerating] = useState(false)


    return <>

        {
            !startGenerating && < div style={{
                display: "flex",
                gap: 5,
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <TextField
                    value={waybillsPerPage}
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => { setWaybillsPerPage(parseInt(e.target.value)) }}
                    onFocus={(e) =>
                        e.target.addEventListener(
                            'wheel',
                            (e) => e.preventDefault(),
                            { passive: false }
                        )
                    }
                />

                <Button color="primary" variant="outlined" onClick={() => setStartGenerating(true)}><b>Print Waybills</b></Button>

            </div >
        }

        {
            startGenerating &&
            <FormControl fullWidth>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {
                        <PDFDownloadLink
                            document={
                                <WaybillPrinter orders={Array.from(selectedOrders.values())} waybillsPerPage={waybillsPerPage} />
                            }

                            fileName={fileName}
                        >
                            {({ blob, url, loading, error }) => {
                                return loading ? (
                                    <Button color="primary" size="large" disabled>
                                        Loading...
                                    </Button>
                                ) : (
                                    <Button color="primary" size="large">
                                        <DownloadIcon fontSize="large" />
                                    </Button>
                                );
                            }}
                        </PDFDownloadLink>
                    }
                </div>
            </FormControl >
        }

    </>
}

export default PrintWaybills;