import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  primaryPaletteColor,
  secondaryPaletteColor,
} from './configurations/global.config';
import { enableMapSet } from 'immer';

import AppLayout from './components/app-layout/app-layout';
import { AuthProvider } from './contexts/user-context/user-context';
import { Routes, Route } from 'react-router-dom';
import DeliveryTracker from './components/delivery-tracker/delivery-tracker';
import { Fab } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { PermissionsContextProvider } from './contexts/user-context/permissions-context';
import { generateWhatsAppLink } from './components/driver-components/driver-order-lookup/utils';

function App() {
  enableMapSet();

  const theme = createTheme({
    palette: {
      primary: {
        main: primaryPaletteColor,
      },
      secondary: {
        main: secondaryPaletteColor,
      },
    },
  });

  theme.typography.h3 = {
    fontSize: '1.6rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/tracking">
            <Route path=":hash" element={<DeliveryTracker />} />
          </Route>

          <Route
            path="*"
            element={
              <AuthProvider>
                <PermissionsContextProvider>
                  <AppLayout />
                </PermissionsContextProvider>
              </AuthProvider>
            }
          ></Route>
        </Routes>

        <Fab
          color="primary"
          size="large"
          aria-label="add"
          style={{
            position: 'fixed',
            zIndex: 9999999,
            bottom: 7,
            right: 7,
          }}
          onClick={() => window.open(generateWhatsAppLink('+33753560667'))}
        >
          <ContactSupportIcon />
        </Fab>
      </ThemeProvider>
    </div>
  );
}

export default App;
