import {
  Typography,
  Alert,
  FormControl,
  TextField,
  Button,
  LinearProgress,
  Switch,
} from '@mui/material';
import { useContext, useState } from 'react';
import {
  CreateOrEditGuestMerchantProps,
  MerchantType,
} from '../../../../interfaces/delivery-company/merchants';
import MuiPhoneNumber from 'mui-phone-number';
import ConfirmAction from '../../../common/confirm-action/confirm-action';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { emptyMerchant } from '../../../../data/merchants';
import { UserType } from '../../../../enums/users';
import { ensureZeroAfterCountryCode } from '../../../../utils/phone_numbers';

function CreateMerchantForm({
  defaultMerchant,
  successCallback,
  headless,
}: CreateOrEditGuestMerchantProps) {
  const [merchantData, setMerchantData] = useState<MerchantType | undefined>(
    defaultMerchant || emptyMerchant
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [confirmDeleteMerchant, setConfirmDeleteMerchant] =
    useState<boolean>(false);
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');
  const [enableModifyPassword, setEnableModifyPassword] =
    useState<boolean>(false);
  const { user } = useContext(AuthContext);

  console.log("check: ", user)
  const canChangeMerchant = user?.type === UserType.ORGANISATION || user?.permissions?.includes("change_merchant")
  const canCreateMerchant = user?.type === UserType.ORGANISATION || user?.permissions?.includes("create_merchant")
  const canDeleteMerchant = user?.type === UserType.ORGANISATION || user?.permissions?.includes("delete_merchant")


  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  console.log(merchantData);

  console.log('checkpoiny');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (merchantData) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Saving Merchant...');
      const requestData = {
        name: merchantData.name,
        phone_number: ensureZeroAfterCountryCode(merchantData.phone_number).replaceAll(' ', ''),
        address: merchantData.address,
        organisation: user?.organisation,
        password: merchantData.password,
      };

      console.log();

      try {
        let response;
        const endpoint = `${API_ENDPOINT}/api/org/merchant/${merchantData.id}/`;

        if (merchantData.id === '') {
          console.log('request data:', requestData);
          // create new
          response = await axios.post(
            `${API_ENDPOINT}/api/org/merchants/`,
            requestData,
            config
          );
        } else {
          // edit existing
          response = await axios.put(endpoint, requestData, config);
        }
        setResponse(response);
        console.log(response);

        if (response.status >= 200 && response.status < 300) {
          setMessage('Merchant saved successfully');
          successCallback && successCallback(response.data);
        }
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response)
          setMessage('Failed to save merchant: ' + e.message);
        } else {
          setMessage('Failed to save merchant');
        }
        console.log(e);
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const deleteMerchant = async () => {
    if (merchantData && merchantData.id !== '') {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/org/merchant/${merchantData.id}/?isguest=true`,
          config
        );
        setResponse(response);
        if (response.status === 204) {
          setMerchantData(undefined);
          setMessage('Merchant deleted successfully');
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response)
          setMessage('Failed to delete merchant: ' + e.message);
        } else {
          setMessage('Failed to delete merchant');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      {!headless && (
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          {defaultMerchant === undefined ? 'Create Merchant' : 'Edit Merchant'}
        </Typography>
      )}

      {isLoading ? (
        <>
          {loadingMessage}
          <br />
          <LinearProgress />
        </>
      ) : (
        <>
          {message !== '' && (
            <Alert
              severity={
                response && response.status >= 200 && response.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              {message}

              {response &&
                response.status >= 300 &&
                Object.keys(response.data).map((key) => (
                  <>
                    <ul>
                      {
                        typeof response.data[key] === "string" ?
                          <li>{response.data[key]}</li> :
                          response.data[key].map((validationError: string) => (
                            <li key={key}>
                              {key}: {validationError}
                            </li>
                          ))}
                    </ul>
                  </>
                ))}
            </Alert>
          )}

          {merchantData && (
            <>
              <br></br>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    label="Merchant Name"
                    type="text"
                    variant="outlined"
                    required
                    value={merchantData.name}
                    onChange={(e) =>
                      setMerchantData({ ...merchantData, name: e.target.value })
                    }
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <MuiPhoneNumber
                    label="Merchant Phone Number"
                    type="tel"
                    defaultCountry={'lb'}
                    variant="outlined"
                    required
                    value={merchantData.phone_number}
                    onChange={(newPhone) => {
                      if (typeof newPhone === 'string') {
                        setMerchantData({
                          ...merchantData,
                          phone_number: newPhone,
                        });
                      } else
                        setMerchantData({
                          ...merchantData,
                          phone_number: newPhone.target.value,
                        });
                    }}
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <TextField
                    label="Merchant Address"
                    type="text"
                    variant="outlined"
                    required
                    value={merchantData.address}
                    onChange={(e) =>
                      setMerchantData({
                        ...merchantData,
                        address: e.target.value,
                      })
                    }
                  />
                </FormControl>

                <br />
                <br />

                <div
                  style={{
                    display: 'flex',
                    gap: 3,
                    alignItems: 'center',
                  }}
                >
                  {
                    <>
                      <Switch
                        onChange={() =>
                          setEnableModifyPassword(!enableModifyPassword)
                        }
                        checked={enableModifyPassword}
                      />
                      {!enableModifyPassword && <>Set merchant password</>}
                    </>
                  }

                  {enableModifyPassword && (
                    <FormControl fullWidth>
                      <TextField
                        label="Merchant Password"
                        type="password"
                        variant="outlined"
                        required
                        value={merchantData.password}
                        onChange={(e) =>
                          setMerchantData({
                            ...merchantData,
                            password: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  )}
                </div>

                <br />

                <div style={{ display: 'flex', gap: 7 }}>
                  <Button type="submit" variant="contained" disabled={
                    (merchantData.id !== '' && !canChangeMerchant)
                    ||
                    (merchantData.id === '' && !canCreateMerchant)
                  }>
                    Save Merchant
                  </Button>

                  {merchantData.id !== '' && (
                    <Button
                      onClick={() => {
                        setConfirmDeleteMerchant(true);
                      }}
                      disabled={!canDeleteMerchant}
                      variant="outlined"
                      color="error"
                    >
                      Delete Merchant
                    </Button>
                  )}
                </div>
              </form>
            </>
          )}
        </>
      )}

      <ConfirmAction
        message={'Are you sure you want to delete this order?'}
        action={deleteMerchant}
        isOpen={confirmDeleteMerchant}
        handleClose={() => setConfirmDeleteMerchant(false)}
        isSevereAction={true}
        confirmSevereActionTarget="delete merchant"
      >
        <div>
          Attention: This action will delete all orders registered for this
          merchant!
        </div>
      </ConfirmAction>
    </>
  );
}

export default CreateMerchantForm;
