import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { API_ENDPOINT } from '../../../configurations/global.config';
import CreateCustomerForm from '../../common/create-customer-form/create-customer-form';
import CreateEntityDrawer from '../../common/create-entity-drawer/create-entity-drawer';
import CustomersListing from './customers-listing/customers-listing';

function Customers() {
  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Customers
        </Typography>
        <Grid container spacing={2}>
          <Grid xs={4}></Grid>
          <Grid xs={4}></Grid>
          <Grid xs={4} sx={{ textAlign: 'right' }}>
            <CreateEntityDrawer>
              <CreateCustomerForm baseEndpoint={`${API_ENDPOINT}/api/org`} />
            </CreateEntityDrawer>
          </Grid>
          <Grid xs={12}>
            <CustomersListing />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Customers;
