import { Permission } from '../../../contexts/user-context/permissions-context';

// Returns permissions_left - permissions_right
export const get_permissions_diff = (
  permissions_left: Permission[],
  permissions_right: Permission[]
) => {
  const permissions_right_set = new Set<string>();
  permissions_right.forEach((perm) => permissions_right_set.add(perm.id));
  return permissions_left.filter((perm) => !permissions_right_set.has(perm.id));
};
