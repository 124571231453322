import { primaryPaletteColor } from '../../../configurations/global.config';

export const cardStyle: React.CSSProperties = {
  padding: 10,
  textAlign: 'left',
  height: '100%',
  width: '100%',
};

export const cardHeaderStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  gap: 3,
  color: primaryPaletteColor,
  marginBottom: 10,
};

export const cardTableStyle: React.CSSProperties = {
  width: '100%',
  padding: 10,
};
