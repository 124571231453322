export function generateWhatsAppLink(phoneNumber: string): string {
  // Remove non-numeric characters from the phone number
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  const formattedNumber = cleanedNumber.startsWith('+')
    ? cleanedNumber
    : `+${cleanedNumber}`;

  // Construct the WhatsApp chat link
  const whatsappLink = `https://wa.me/${formattedNumber}`;

  return whatsappLink;
}
