import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Html5QrcodeResult } from 'html5-qrcode';
import { useEffect, useState } from 'react';
import BarcodeScanner from '../scanner/scanner-input';

type TextFieldWithScanProps = TextFieldProps & {
  act: (value: string) => void;
}

function TextFieldWithScan({ act, onChange, disabled, ...props }: TextFieldWithScanProps) {
  const [isScanning, setIsScanning] = useState(false);
  const [value, setValue] = useState<any>(props.value);

  const beepSound = new Howl({
    src: ['/media/Barcode-scanner-beep-sound.mp3'],
    volume: 0.0075,
  });

  const onNewCameraScanResult = (
    newDecodedText: string,
    newDecodedResult: Html5QrcodeResult
  ) => {
    beepSound.play();
    setValue(newDecodedText);
    setIsScanning(false);
  };

  useEffect(() => {
    act(value)
  }, [value])

  return (
    <>
      <TextField
        {...props}
        disabled={disabled}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsScanning(true)}
                edge="end"
                disabled={disabled}
              >
                <QrCodeScannerIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Dialog
        open={isScanning}
        fullWidth={true}
        sx={{ zIndex: 99999999999999 }}
      >
        <DialogContent>
          <div
            style={{
              width: '100%',
            }}
          >
            {isScanning && <BarcodeScanner
              onSuccessfullScan={onNewCameraScanResult}
            />}
          </div>
        </DialogContent>

        <div style={{ width: '100%', textAlign: 'center' }}>
          <Button
            sx={{ width: '70%', marginBottom: 3 }}
            color="primary"
            variant="contained"
            onClick={() => {
              setIsScanning(false);
            }}
          >
            {' '}
            Close{' '}
          </Button>
        </div>
      </Dialog>
    </>
  );
}

export default TextFieldWithScan;
