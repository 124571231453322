import AccountBoxIcon from '@mui/icons-material/AccountBoxSharp';
import { Button } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { secondaryPaletteColor } from '../../../configurations/global.config';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { UserType } from '../../../enums/users';

function UserProfile() {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  let userType: string = user !== null ? user.type : 'INVALID';

  if (userType === UserType.ORGANISATION.toString()) {
    userType = 'ADMIN';
  }

  const requireOrgId = [
    UserType.ORGANISATION.toString(),
    UserType.EMPLOYEE.toString(),
    UserType.MERCHANT.toString(),
  ].includes(user?.type ?? '');

  console.log(user);
  return (
    <div style={{ background: secondaryPaletteColor }}>
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
        }}
      >
        <AccountBoxIcon fontSize="large" color="primary" />
        <div>
          <b>Account Info</b>
        </div>
      </div>

      <br />

      <table style={{ width: '100%', margin: 3, paddingLeft: 10 }}>
        <tbody>
          <tr>
            <th>Profile</th>
            <td>{userType}</td>
          </tr>

          {requireOrgId && (
            <tr>
              <th>Org. Id</th>
              <td>{user?.organisation?.org_id}</td>
            </tr>
          )}

          <tr>
            <th>Name</th>
            <td>{user?.name}</td>
          </tr>

          <tr>
            <th>Phone</th>
            <td>{user?.phone}</td>
          </tr>
        </tbody>
      </table>

      <br />

      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{ width: '65%' }}
          onClick={() => {
            setUser(null);
            navigate('login');
          }}
          color="primary"
          variant="contained"
        >
          {' '}
          Logout
        </Button>
      </div>

      <br />
    </div>
  );
}

export default UserProfile;
