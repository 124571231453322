import {
  Alert,
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  LinearProgress,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DriverType } from '../../../interfaces/delivery-company/drivers';

import Grid from '@mui/material/Unstable_Grid2';
import axios, { AxiosResponse } from 'axios';
import { Html5QrcodeResult } from 'html5-qrcode';
import { isBrowser, isMobile } from 'react-device-detect';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { DeliveryState, PaymentState } from '../../../enums/orders';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import BarcodeScanner from '../../common/scanner/scanner-input';
import SnackBar from '../../common/snack-bar/snack-bar';
import DriverIntroCard from './driver-intro-card';
import ScannedOrdersView from './scanned-orders-view';
import { UserType } from '../../../enums/users';
import { ensureZeroAfterCountryCode } from '../../../utils/phone_numbers';

interface TimestampedMessage {
  content: string;
  success: boolean;
  timestamp: number;
}

interface DriverOrderAssignerProps {
  isGuest: boolean;
}

function DriverOrderAssigner({ isGuest }: DriverOrderAssignerProps) {
  const { driverId } = useParams();
  const [driver, setDriver] = React.useState<DriverType>();
  const [useCameraChecked, setUseCameraChecked] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [commissionLBP, setCommissionLBP] = React.useState<number>(0);
  const [commissionUSD, setCommissionUSD] = React.useState<number>(0);

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const canAssignOrders = user?.type === UserType.ORGANISATION || user?.permissions?.includes("change_order")

  console.log(user?.permissions)

  // Fetching Driver
  const [fetchDriverResponse, setFetchDriverResponse] =
    React.useState<AxiosResponse>();
  const [fetchDriverMessage, setfetchDriverMessage] =
    React.useState<string>('');

  // Fetching Order
  const [fetchOrderResponse, setFetchOrderResponse] =
    React.useState<AxiosResponse>();
  const [fetchOrderMessage, setfetchOrderMessage] =
    React.useState<TimestampedMessage>({
      content: '',
      success: true,
      timestamp: Date.now(),
    });

  // Fetching Order
  const [assignOrderResponse, setAssignOrderResponse] =
    React.useState<AxiosResponse>();
  const [assignOrderMessage, setAssignOrderMessage] =
    React.useState<string>('');

  const [orders, setOrders] = React.useState<OrderType[]>([]);
  const [orderIdInput, setOrderIdInput] = React.useState<string>('');
  const [orderScannedId, setOrderScannedId] = React.useState<string>('');

  console.log(assignOrderMessage);
  const beepSound = new Howl({
    src: ['/media/Barcode-scanner-beep-sound.mp3'],
    volume: 0.0075,
  });

  React.useEffect(() => {
    const getAndSetDriver = async () => {
      if (driverId !== undefined) {
        setIsLoading(true);
        try {
          const endpoint = `${API_ENDPOINT}/api/org/driver/${driverId}/?isguest=${isGuest}`;
          const config = {
            headers: { Authorization: `Bearer ${user?.authToken}` },
          };

          console.log(endpoint);
          const response = await axios.get<DriverType>(endpoint, config);
          console.log(response);
          setFetchDriverResponse(response);
          if (response.statusText == 'OK') {
            setDriver(response.data);
            setCommissionLBP(response.data.default_commission_lbp);
            setCommissionUSD(response.data.default_commission_usd);
          }
        } catch (e) {
          setDriver(undefined);
          if (axios.isAxiosError(e)) {
            setfetchDriverMessage('Failed to load driver data: ' + e.message);
          } else {
            setfetchDriverMessage('Failed to load driver data');
          }
          console.log(e);
        }
        setIsLoading(false);
      }
    };
    getAndSetDriver();
  }, []);

  const onNewCameraScanResult = (
    newDecodedText: string,
    newDecodedResult: Html5QrcodeResult
  ) => {
    if (isLoading) return;
    setOrderScannedId(newDecodedText);
  };

  React.useEffect(() => {
    if (orderScannedId !== '') {
      beepSound.play();
      fetchAndAppendOrder(orderScannedId);
      setTimeout(() => setOrderScannedId(''), 1500);
    }
  }, [orderScannedId]);

  const handleUseCameraChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUseCameraChecked(event.target.checked);
  };

  const fetchAndAppendOrder = async (queryOrderId: string) => {
    if (isLoading) return;
    setIsLoading(true);
    // setResponse(undefined)
    if (orders.some((item) => item.order_id === queryOrderId)) {
      setfetchOrderMessage({
        content: 'Order is already added',
        timestamp: Date.now(),
        success: true,
      });
    } else {
      setIsLoading(true);
      try {
        const endpoint = `${API_ENDPOINT}/api/org/orders/?order_id__equals=${encodeURIComponent(queryOrderId)}`;
        const response = await axios.get<{
          count: number;
          results: OrderType[];
        }>(endpoint, config);
        setFetchOrderResponse(response);
        if (response.statusText == 'OK' && response.data.count === 1) {
          const newOrder = response.data.results[0];

          if (newOrder.delivery_state === DeliveryState.PENDING_APPROVAL) {
            setfetchOrderMessage({
              content: `Order is not approved.`,
              timestamp: Date.now(),
              success: false,
            });
          } else if (newOrder.payment_state !== PaymentState.UNINVOICED) {
            setfetchOrderMessage({
              content: `Order is already invoiced.`,
              timestamp: Date.now(),
              success: false,
            });
          } else {
            setOrders([response.data.results[0], ...orders]);
            setfetchOrderMessage({
              content: 'Order added',
              timestamp: Date.now(),
              success: true,
            })
          }
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setfetchOrderMessage({
            content: 'Failed to load order data: ' + e.message,
            timestamp: Date.now(),
            success: false,
          });
        } else {
          setfetchOrderMessage({
            content: 'Failed to load order data: ',
            timestamp: Date.now(),
            success: false,
          });
        }
        console.log(e);
      }
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const removeOrder = (orderId: string) => {
    if (isLoading) return;
    setOrders(orders.filter((order) => order.order_id != orderId));
  };

  const assignOrders = async () => {
    if (driver === undefined) return;
    const endpoint = `${API_ENDPOINT}/api/org/orders/bulk-update/`;
    setIsLoading(true);
    const requestData: OrderType[] = orders.map((order) => {
      const newOrder: OrderType = {
        ...order,
        driver: {
          id: driver.id,
          name: driver.name,
          phone_number: ensureZeroAfterCountryCode(driver.phone_number),
          isGuest: driver.isGuest,
        },
        delivery_state: DeliveryState.IN_TRANSIT,
        driver_commission_lbp: commissionLBP,
        driver_commission_usd: commissionUSD,
      };
      return newOrder;
    });

    console.log(requestData);
    try {
      const response = await axios.patch<OrderType[]>(
        endpoint,
        requestData,
        config
      );
      setAssignOrderResponse(response);
      if (response.statusText == 'OK') {
        setAssignOrderMessage('Orders assigned successfully');
        setOrders(requestData);
      } else {
        setAssignOrderMessage('Failed to assign orders');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.log('error: ', e);
        setAssignOrderResponse(e.response);
        setAssignOrderMessage('Failed to assign orders: ' + e.response?.statusText);
      } else {
        setAssignOrderMessage('Failed to assign orders');
      }
      console.log(e);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Container>
        <Box>
          <Typography
            variant="h3"
            component="h3"
            sx={{ textAlign: 'center', mt: 3, mb: 3 }}
          >
            Assign Orders
          </Typography>

          {isLoading && driver !== undefined && <LinearProgress />}

          {fetchOrderMessage.content !== '' && (
            <SnackBar
              message={fetchOrderMessage.content}
              isSuccess={fetchOrderMessage.success}
              key={fetchOrderMessage.timestamp}
            />
          )}
          {driver === undefined ? (
            <LinearProgress />
          ) : (
            <>
              {/* Introduce Driver */}
              <DriverIntroCard driver={driver} />

              <br />
              {/* Driver Commission */}
              <FormControl fullWidth>
                <Grid container spacing={1}>
                  <Grid xs={6}>
                    <TextField
                      label="Driver Commission $"
                      type="number"
                      variant="outlined"
                      fullWidth
                      required
                      value={commissionUSD}
                      inputProps={{
                        maxLength: 13,
                        step: '0.01',
                      }}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          (e) => e.preventDefault(),
                          { passive: false }
                        )
                      }
                      onChange={(e) =>
                        setCommissionUSD(parseFloat(e.target.value))
                      }
                    />
                  </Grid>

                  <Grid xs={6}>
                    <TextField
                      label="Driver Commission L.L"
                      type="number"
                      variant="outlined"
                      fullWidth
                      required
                      value={commissionLBP}
                      onChange={(e) =>
                        setCommissionLBP(parseFloat(e.target.value))
                      }
                    />
                  </Grid>
                </Grid>
              </FormControl>

              <br />
              {/* Response */}
              {assignOrderMessage && assignOrderResponse && (
                <>
                  <br />
                  <Alert
                    severity={
                      assignOrderResponse && assignOrderResponse.status >= 200 && assignOrderResponse.status < 300
                        ? 'success'
                        : 'error'
                    }
                  >
                    <>
                      {assignOrderMessage}

                      {assignOrderResponse &&
                        assignOrderResponse.status >= 300 &&
                        Object.keys(assignOrderResponse.data).map((key) => (
                          <>
                            <ul>
                              {
                                typeof assignOrderResponse.data[key] === "string" ?
                                  <li>{assignOrderResponse.data[key]}</li> :
                                  assignOrderResponse.data[key].map((validationError: string) => (
                                    <li key={key}>
                                      {key}: {validationError}
                                    </li>
                                  ))}
                            </ul>
                          </>
                        ))}
                    </>
                  </Alert>
                </>
              )}

              <br />

              {/* Use Device Camera to scan barcodes */}
              <div style={{ display: 'flex', alignContent: 'center' }}>
                <Switch
                  disabled={isBrowser}
                  onChange={handleUseCameraChange}
                  checked={useCameraChecked}
                />
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  Use device camera to scan orders
                </div>
              </div>
              {!isMobile && (
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Alert severity="warning">
                    Using device camera is only available on mobile devices
                  </Alert>
                </div>
              )}

              {!useCameraChecked && (
                <>
                  <br />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      fetchAndAppendOrder(orderIdInput);
                    }}
                  >
                    <div style={{ display: 'flex', gap: 3 }}>
                      <FormControl fullWidth>
                        <TextField
                          label="Type order id or scan order barcode"
                          type="text"
                          disabled={isLoading}
                          variant="outlined"
                          required
                          value={orderIdInput}
                          onChange={(e) =>
                            !isLoading && setOrderIdInput(e.target.value)
                          }
                        />

                        {isLoading && <LinearProgress />}
                      </FormControl>
                      <Button
                        variant="contained"
                        type={'submit'}
                        disabled={isLoading || !canAssignOrders}
                      >
                        Add
                      </Button>
                    </div>
                  </form>
                </>
              )}

              <br />

              <Chip
                color="primary"
                label={`# Orders: ${orders.length}`}
                variant="outlined"
              />

              <br />

              {
                /* Open device camera if required */
                isMobile && (
                  <>
                    {useCameraChecked && <br />}
                    {useCameraChecked && <BarcodeScanner
                      onSuccessfullScan={onNewCameraScanResult}
                    />}
                  </>
                )
              }

              <br />

              <ScannedOrdersView orders={orders} removeOrder={removeOrder} />

              <br />

              {orders.length > 0 && (
                <>
                  <Button
                    variant="contained"
                    onClick={assignOrders}
                    disabled={isLoading || !canAssignOrders}
                  >
                    Assign to {driver.name}
                  </Button>
                  <br />
                </>
              )}
            </>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default DriverOrderAssigner;
