import {
  Typography,
  Alert,
  FormControl,
  TextField,
  Button,
  Autocomplete,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Container,
  Box,
  Switch,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import _debounce from 'lodash/debounce';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { RegionType, regions } from '../../../data/regions';
import { CustomerType } from '../../../interfaces/common/customer';
import AutocompleteWithThrottle from '../../common/autocomplete-with-throttle/autocomplete-with-throttle';
import CreateCustomerForm from '../../common/create-customer-form/create-customer-form';
import { useParams } from 'react-router-dom';
import React from 'react';
import { DeliveryState } from '../../../enums/orders';
import { AuthContext } from '../../../contexts/user-context/user-context';
import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../../../configurations/global.config';
import TextFieldWithScan from '../../common/textfield-with-scan/textfield-with-scan';
import { assignCustomerProvider } from '../../common/create-callbacks-utils/create-callbacks-utils';
import { emptyCustomer } from '../../../data/customers';

function MerchantEditOrder() {
  const { orderId } = useParams();
  const { orgId } = useParams();

  const [orderData, setOrderData] = React.useState<OrderType>();
  const [creatingNewCustomerOpen, setCreatingNewCustomerOpen] =
    useState<boolean>(false);

  const [regionSearchInput, setRegionSearchInput] = useState(
    orderData?.district
  );
  const [autoAssignOrderId, setAutoAssignOrderId] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  console.log(orderData);

  React.useEffect(() => {
    console.log('use effect');
    const getAndSetOrder = async () => {
      if (orderId !== undefined && orgId !== undefined) {
        setIsLoading(true);
        try {
          const endpoint = `${API_ENDPOINT}/api/merchant/order/${orderId}/?org=${encodeURIComponent(orgId)}`;
          console.log(endpoint);
          const response = await axios.get<OrderType>(endpoint, config);
          console.log(response);
          setResponse(response);
          if (response.statusText == 'OK') {
            setOrderData(response.data);
          }
        } catch (e) {
          setOrderData(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load order data: ' + e.message);
          } else {
            setMessage('Failed to load order data');
          }
          console.log(e);
        }
        setIsLoading(false);
      }
    };
    getAndSetOrder();
  }, []);

  const handleFetchCustomers = async (
    query: string
  ): Promise<CustomerType[]> => {
    if (orderData) {
      const endpoint = `${API_ENDPOINT}/api/merchant/customers/?page_size=10&name_or_phone=${encodeURIComponent(query)}&org=${orgId}`;

      try {
        const response = await axios.get<{
          count: number;
          results: CustomerType[];
        }>(endpoint, config);

        if (response.statusText === 'OK') {
          return response.data.results;
        }

        return [];
      } catch (error) {
        console.log(error);
      }
    }

    return [];
  };

  // Initialise data assigners to be used as success callbacks when creating entities inline
  const assignCustomer = assignCustomerProvider({
    orderData,
    setOrderData,
    setRegionSearchInput,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (orderData && orderId != '' && orgId) {
      setIsLoading(true);
      setResponse(undefined);
      setMessage('');
      setLoadingMessage('Saving Order...');

      const requestData = orderData;

      try {
        const endpoint = `${API_ENDPOINT}/api/merchant/order/${orderData.id}/?org=${orgId}`;

        console.log('Request Data: ');
        console.log(requestData);

        // update order
        const response = await axios.put(endpoint, requestData, {
          headers: { ...config.headers, 'X-Auto-Assign-ID': autoAssignOrderId }
        });
        setResponse(response);
        console.log(response);
        setMessage('Order saved successfully');
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response)
          setMessage('Failed to save order: ' + e.response?.statusText);
        } else {
          setMessage('Failed to save order');
        }
        console.log(e);
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  return (
    <Container>
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Edit Order
        </Typography>

        {orderData === undefined ? (
          <LinearProgress />
        ) : (
          <>
            {message != '' && (
              <Alert
                severity={
                  response && response.status >= 200 && response.status < 300
                    ? 'success'
                    : 'error'
                }
              >
                <>
                  {message}

                  {response &&
                    response.status >= 300 &&
                    Object.keys(response.data).map((key) => (
                      <>
                        <ul>
                          {
                            typeof response.data[key] === "string" ?
                              <li>{response.data[key]}</li> :
                              response.data[key].map((validationError: string) => (
                                <li key={key}>
                                  {key}: {validationError}
                                </li>
                              ))}
                        </ul>
                      </>
                    ))}
                </>
              </Alert>
            )}

            {isLoading ? (
              <>
                {' '}
                {loadingMessage} <br /> <LinearProgress />{' '}
              </>
            ) : (
              <>
                {orderData && (
                  <>
                    <br></br>

                    <Switch
                      onChange={() => {
                        setAutoAssignOrderId(!autoAssignOrderId)
                      }}
                      checked={autoAssignOrderId}
                    />
                    Assign Order Id Automatically
                    <br /><br />


                    {orderData.delivery_state !==
                      DeliveryState.PENDING_APPROVAL ? (
                      <Alert severity="error">
                        You can&apost edit an order once it is approved by the
                        delivery company. <br />
                        Please contact the delivery company in order to edit
                        this order.
                      </Alert>
                    ) : (
                      <form onSubmit={handleSubmit}>
                        <FormControl fullWidth>
                          <Grid container spacing={2}>
                            {!autoAssignOrderId && <Grid xs={12} md={6}>
                              <TextFieldWithScan
                                label="Order Id"
                                type="text"
                                variant="outlined"
                                fullWidth
                                value={orderData.order_id}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }
                                }}
                                act={(value) =>
                                  setOrderData({
                                    ...orderData,
                                    order_id: value,
                                  })
                                }
                              />
                            </Grid>}
                            <Grid xs={12} md={autoAssignOrderId ? 12 : 6}>
                              <TextFieldWithScan
                                label="Reference Number"
                                type="text"
                                variant="outlined"
                                fullWidth
                                value={orderData.reference_id}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }
                                }}
                                act={(value) =>
                                  setOrderData({
                                    ...orderData,
                                    reference_id: value,
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <AutocompleteWithThrottle
                            label={'Customer'}
                            value={orderData.customer}
                            onClickAdd={() => setCreatingNewCustomerOpen(true)}
                            fetch={handleFetchCustomers}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option: CustomerType) =>
                              option.name + ' ' + option.phone_number
                            }
                            onChange={(e, value) =>
                              assignCustomer(value ?? emptyCustomer)
                            }
                          />
                        </FormControl>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Grid container spacing={2}>
                            <Grid xs={12} md={6}>
                              <TextField
                                label="Address"
                                type="string"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.address}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    address: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <Autocomplete
                                disablePortal
                                id="district"
                                value={
                                  orderData.district !== ''
                                    ? {
                                      district: orderData.district,
                                      governorate: orderData.governorate,
                                    }
                                    : null
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.district === value.district
                                }
                                onChange={(_, newValue: RegionType | null) => {
                                  if (newValue === null)
                                    newValue = {
                                      district: '',
                                      governorate: '',
                                    };

                                  setOrderData({
                                    ...orderData,
                                    district: newValue.district,
                                    governorate: newValue.governorate,
                                  });
                                }}
                                inputValue={regionSearchInput}
                                onInputChange={(event, newInputValue) => {
                                  if (event === null) return;
                                  setRegionSearchInput(newInputValue);
                                }}
                                options={regions.sort((a, b) =>
                                  b.governorate.localeCompare(a.governorate)
                                )}
                                groupBy={(option) => option.governorate}
                                getOptionLabel={(option) => option.district}
                                renderInput={(params) => (
                                  <TextField {...params} label="District" />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Grid container spacing={2}>
                            <Grid xs={6}>
                              <TextField
                                label="Order Price $"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.price_usd}
                                inputProps={{
                                  maxLength: 13,
                                  step: '0.01',
                                }}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    'wheel',
                                    (e) => e.preventDefault(),
                                    { passive: false }
                                  )
                                }
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    price_usd: parseFloat(e.target.value),
                                  })
                                }
                              />
                            </Grid>

                            <Grid xs={6}>
                              <TextField
                                label="Order Price L.L"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.price_lbp}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    price_lbp: parseInt(e.target.value),
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <Grid container spacing={2}>
                            <Grid xs={6}>
                              <TextField
                                label="Delivery Fee $"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.delivery_fee_usd}
                                inputProps={{
                                  maxLength: 13,
                                  step: '0.01',
                                }}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    'wheel',
                                    (e) => e.preventDefault(),
                                    { passive: false }
                                  )
                                }
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    delivery_fee_usd: parseFloat(
                                      e.target.value
                                    ),
                                  })
                                }
                              />
                            </Grid>

                            <Grid xs={6}>
                              <TextField
                                label="Delivery Fee L.L"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                                value={orderData.delivery_fee_lbp}
                                onChange={(e) =>
                                  setOrderData({
                                    ...orderData,
                                    delivery_fee_lbp: parseInt(e.target.value),
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <br /> <br />
                        <FormControl fullWidth>
                          <TextField
                            label="Number of Packages"
                            type="number"
                            variant="outlined"
                            required
                            value={orderData.number_of_packages}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                'wheel',
                                (e) => e.preventDefault(),
                                { passive: false }
                              )
                            }
                            onChange={(e) =>
                              setOrderData({
                                ...orderData,
                                number_of_packages: parseInt(e.target.value),
                              })
                            }
                          />
                        </FormControl>
                        <br />
                        <br />
                        <FormControl fullWidth>
                          <TextField
                            label="Description"
                            type="text"
                            variant="outlined"
                            multiline
                            value={orderData.description}
                            rows={4}
                            onChange={(e) =>
                              setOrderData({
                                ...orderData,
                                description: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        <br />
                        <br />
                        <Button type="submit" variant="contained">
                          Save Order
                        </Button>
                      </form>
                    )}
                  </>
                )}
              </>
            )}

            <Dialog fullWidth open={creatingNewCustomerOpen} onClose={() => undefined}>
              <div
                style={{
                  textAlign: 'right',
                  width: '100%',
                }}
              >
                <IconButton onClick={() => setCreatingNewCustomerOpen(false)}>
                  <ClearIcon color="primary" fontSize="large" />
                </IconButton>
              </div>

              <DialogContent>
                <CreateCustomerForm
                  baseEndpoint={`${API_ENDPOINT}/api/merchant`}
                  orgId={orgId}
                  successCallback={assignCustomer}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      </Box>
    </Container>
  );
}

export default MerchantEditOrder;
