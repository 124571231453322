import { useContext, useEffect, useState } from 'react';
import { Card, TablePagination } from '@mui/material';
import SearchBar from '../../../common/search-bar/search-bar';

import MerchantsListingTable from './merchants-listing.table';
import { MerchantType } from '../../../../interfaces/delivery-company/merchants';
import axios from 'axios';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { ensureZeroAfterCountryCode } from '../../../../utils/phone_numbers';

function MerchantsListing() {
  const [merchantsList, setMerchantsList] = useState<MerchantType[]>([]);
  const [merchantsCount, setMerchantsCount] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchOptions = ['Name', 'Phone', 'Address'];

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    searchColumn: 'Name',
    searchValue: '',
    searchOperation: 'equals',
  });

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  useEffect(() => {
    const getData = async () => {
      let endpoint = `${API_ENDPOINT}/api/org/merchants/?page=${controller.page + 1}&page_size=${controller.rowsPerPage}`;

      if (controller.searchColumn !== '' && ensureZeroAfterCountryCode(controller.searchValue) !== '') {
        endpoint += `&${controller.searchColumn.toLowerCase()}__${controller.searchOperation}=${encodeURIComponent(ensureZeroAfterCountryCode(controller.searchValue))}`;
      }

      console.log(endpoint);
      try {
        const response = await axios.get<{
          count: number;
          results: MerchantType[];
        }>(endpoint, config);
        console.log(response);

        if (response.statusText === 'OK') {
          setMerchantsList(response.data.results);
          setMerchantsCount(response.data.count);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
  }, [controller]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const searchQueryAction = (
    column: string,
    value: string,
    operation: string
  ) => {
    setController({
      ...controller,
      searchColumn: column,
      searchValue: value,
      searchOperation: operation,
    });
  };

  return (
    <Card sx={{ padding: 2, overflow: 'auto' }}>
      <SearchBar
        searchOptions={searchOptions}
        searchQueryAction={searchQueryAction}
      />
      <MerchantsListingTable merchants={merchantsList} isLoading={isLoading} />
      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={merchantsCount}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        labelRowsPerPage=""
        style={{ overflow: 'visible' }}
      />
    </Card>
  );
}

export default MerchantsListing;
